import { Pipe, PipeTransform } from '@angular/core';

const PADDING = '000000';

@Pipe({ name: 'decimalFormat' })
export class DecimalFormatPipe implements PipeTransform {
  private DECIMAL_SEPARATOR: string;
  private DECIMAL_DECORATOR: string;
  private THOUSANDS_DECORATOR: string;

  constructor() {
    // TODO comes from configuration settings
    this.DECIMAL_SEPARATOR = '.';

    this.DECIMAL_DECORATOR = ',';
    this.THOUSANDS_DECORATOR = '.';
  }

  // OnBlur --> transform
  transform(value: number | string, fractionSize = 2): string {
    if (String(value).includes(',')) {
      value = this.setDecimalFormatter(value);
    }
    if (value !== 0) {
      let [integer, fraction = ''] = (value || '').toString().split(this.DECIMAL_SEPARATOR);

      if (fraction === '') {
        [integer, fraction = ''] = (value || '').toString().split(this.DECIMAL_DECORATOR);
      }

      fraction = fractionSize > 0 ? this.DECIMAL_DECORATOR + (fraction + PADDING).substring(0, fractionSize) : '';

      integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_DECORATOR);
      if (integer === '') {
        integer = '0';
      }
      return integer + fraction;
    } else {
      return String(value);
    }
  }

  // OnFocus --> Parse
  parse(value: string, fractionSize = 2): string {
    let integer, fraction, result;
    if (String(value).includes(this.DECIMAL_DECORATOR)) {
      [integer, fraction = ''] = (value || '').split(this.DECIMAL_DECORATOR);
    } else if (String(value).includes(this.DECIMAL_SEPARATOR)) {
      [integer, fraction = ''] = (value || '').split(this.DECIMAL_SEPARATOR);
    } else if (+value !== 0) {
      integer = +value;
    } else {
      integer = '0';
    }

    if (String(integer).includes(this.THOUSANDS_DECORATOR)) {
      integer = integer.replace(this.THOUSANDS_DECORATOR, '');
    }

    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0 ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize) : '';

    result = integer + fraction;

    return result;
  }

  setDecimalFormatter(value: any) {
    let [integer, fraction = ''] = (value || '').split(',');
    integer = integer.replace('.', '');
    fraction = parseInt(fraction, 10) > 0 ? '.' + (fraction + '000000').substring(0, 2) : '';
    let result: number = +(integer + fraction);
    return result;
  }
}
