import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';

import { AccessToken } from '@auth/model/accessToken';
import { RefreshToken } from '@auth/model/refreshToken';

import { APIRoutesService } from '@app/services/apiRoutes.service';
import { Usuario } from '@usuarios/model/usuario.model';
import { Channel } from '@comun/model/socket.model';

import { EncryptionService } from '@comun/services/encryption.service';
import { APIConfig } from '@app/models/global';
import { map, share, delay } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private apiURL: string;
  private apiURLUsers: string;
  private sessionKey: string;
  private cookieKey: string;
  private channelKey: string;
  private favoritosKey: string;
  private isSession: boolean;
  private isCookie: boolean;
  private isRefresh: boolean;
  private authToken: any;

  constructor(private _http: HttpClient, private _APIRoutesService: APIRoutesService, private _router: Router, private _encryptionService: EncryptionService) {
    this.sessionKey = APIConfig.SESSION_KEY;
    this.cookieKey = APIConfig.COOKIE_KEY;
    this.channelKey = APIConfig.CHANNEL_KEY;
    this.favoritosKey = APIConfig.FAVORITOS_KEY;
    this.apiURL = this._APIRoutesService.getApiURIauthorize();
    this.apiURLUsers = this._APIRoutesService.getApiURIusuarios();
    this.isRefresh = false;
  }

  login(accessToken: AccessToken) {
    let body = JSON.stringify(accessToken);
    return this._http.post(this.apiURL, body);
  }

  getMiPerfil(accessToken: AccessToken) {
    return this._http.get(this.apiURLUsers + '/miPerfil');
  }

  getSuscripciones(accessToken: AccessToken) {
    return this._http.get(this.apiURLUsers + '/misSuscripciones');
  }

  getFavoritos(accessToken: AccessToken) {
    return this._http.get(this.apiURLUsers + '/misFavoritos');
  }

  getRefreshToken(refreshtoken: RefreshToken) {
    let now: number = new Date().valueOf() / 1000 + 21600; // Token válido para 6h
    let body = JSON.stringify(refreshtoken);
    return this._http.post(this.apiURL, body).pipe(
      share(),
      map((token) => {
        let cipherObject;
        cipherObject = this._encryptionService.encrypt(token, this.sessionKey);
        sessionStorage.setItem('session', cipherObject);
        sessionStorage.setItem('expire', now.toString());
        this.authToken = token['access_token'];
        this.isRefresh = false;
        return this.authToken;
      })
    );
  }

  getToken() {
    let now: number = new Date().valueOf() / 1000;

    this.isSession = !!sessionStorage.getItem('session');
    if (this.isSession) {
      let sessionInfo = sessionStorage.getItem('session');
      let decryptObject = this._encryptionService.decrypt(sessionInfo, this.sessionKey);
      this.authToken = decryptObject.access_token;
      let actualrefreshtoken = decryptObject.refresh_token;
      let refreshtoken = new RefreshToken('refresh_token', APIConfig.API_CLIENT, APIConfig.APP_KEY, actualrefreshtoken);
      let expireToken = +sessionStorage.getItem('expire');

      if (this.authToken == null || expireToken == null) {
        this.logout();
      }

      // La función refreshToken no he sido capaz de hacerla funcionar, el problema es que se lanza a la vez la solicitud de refreshToken y la query con el token antiguo
      // No he encontrado la forma de "parar" la query para que espere a recibir el token nuevo. Por lo demas, funciona perfectamente.
      /*
      if (now > expireToken) {
        if (!this.isRefresh) {
          this.isRefresh = true;
          this.getRefreshToken(refreshtoken).toPromise().then(
            value => { this.authToken = value; this.isRefresh = false; }
          );
        }
      }
      */
    }

    return this.authToken;
  }

  getMiUsuario() {
    let miUsuario: Usuario;
    let cookieInfo = sessionStorage.getItem('cookie');
    let decryptObject = this._encryptionService.decrypt(cookieInfo, this.cookieKey);
    miUsuario = decryptObject;

    return miUsuario;
  }

  getRefreshSuscripciones(uuid: string) {
    return this._http.get(this.apiURLUsers + '/getSuscripciones/' + uuid);
  }

  getMisSuscripciones() {
    let misSuscripciones: Channel[];
    let channelsInfo = sessionStorage.getItem('channels');
    let decryptObject = this._encryptionService.decrypt(channelsInfo, this.channelKey);
    misSuscripciones = decryptObject;

    return misSuscripciones;
  }

  setMisSuscripciones(channelsInfo: Channel[]) {
    let _cipherObject = this._encryptionService.encrypt(channelsInfo, this.channelKey);
    sessionStorage.setItem('channels', _cipherObject);
  }

  getMisFavoritos() {
    let misFavoritos: any[];
    let favoritosInfo = sessionStorage.getItem('bookmarks');
    let decryptObject = this._encryptionService.decrypt(favoritosInfo, this.favoritosKey);
    misFavoritos = decryptObject;

    return misFavoritos;
  }

  setMisFavoritos(favoritos: any[]) {
    let _cipherObject = this._encryptionService.encrypt(favoritos, this.favoritosKey);
    sessionStorage.setItem('bookmarks', _cipherObject);
  }

  updateMiUsuario(usuario: Usuario) {
    let miUsuario: Usuario;
    let cookieInfo = sessionStorage.getItem('cookie');
    let decryptObject = this._encryptionService.decrypt(cookieInfo, this.cookieKey);
    miUsuario = decryptObject;
    miUsuario = usuario;
    let _cipherObject = this._encryptionService.encrypt(miUsuario, this.cookieKey);
    sessionStorage.setItem('cookie', _cipherObject);
    return miUsuario;
  }

  updateMiUsuarioImagen(imagen: string) {
    let miUsuario: Usuario;
    let cookieInfo = sessionStorage.getItem('cookie');
    let decryptObject = this._encryptionService.decrypt(cookieInfo, this.cookieKey);
    miUsuario = decryptObject;
    miUsuario.imagen = imagen;
    let _cipherObject = this._encryptionService.encrypt(miUsuario, this.cookieKey);
    sessionStorage.setItem('cookie', _cipherObject);
    return miUsuario;
  }

  logout() {
    sessionStorage.clear();
    this._APIRoutesService.setBackEnd();
    this._router.navigate(['login']);
  }
}
