import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APIConfig } from '@app/models/global';

@Injectable()
export class APIRoutesService {
  public _apiURI: string;

  constructor(private _http: HttpClient) {
    this._apiURI = sessionStorage.getItem('BackEnd');
  }

  getIPClient() {
    return this._http.get('https://api.ipify.org/?format=json');
  }

  getBackEnd() {
    return sessionStorage.getItem('BackEnd');
  }

  // Set BackEnd

  setBackEnd() {
    if (window.location.href.includes(APIConfig.FRONTEND_LOC)) {
      sessionStorage.setItem('BackEnd', APIConfig.BACKEND_LOC);
      sessionStorage.setItem('NodeServer', APIConfig.NODE_WEB);
    } else if (window.location.href.includes(APIConfig.FRONTEND_WEB)) {
      sessionStorage.setItem('BackEnd', APIConfig.BACKEND_WEB);
      sessionStorage.setItem('NodeServer', APIConfig.NODE_WEB);
    }
  }

  // Rutas de la API sin requerir Autenticación

  getApiURIauthorize() {
    this._apiURI = sessionStorage.getItem('BackEnd');
    return this._apiURI + '/api/auth/authorize-client';
  }
  getApiURInuevoUsuario() {
    this._apiURI = sessionStorage.getItem('BackEnd');
    return this._apiURI + '/api/auth/nuevoUsuario';
  }
  getApiURIresetClave() {
    this._apiURI = sessionStorage.getItem('BackEnd');
    return this._apiURI + '/api/auth/resetClave';
  }

  // Rutas de Usuarios
  // Funciones principales
  getApiURIusuarios() {
    return this._apiURI + '/api/usuarios';
  }
  // Complementos de usuarios
  getApiUploadImagenAvatarURI() {
    return this._apiURI + '/api/uploadImagenAvatar';
  }

  // Rutas para bloqueo de registros
  getApiURIAlive() {
    return this._apiURI + '/api/alive';
  }

  // Debug Log
  getApiURIdebugLog() {
    return this._apiURI + '/api/debugLog';
  }

  // Rutas Ficheros
  getApiURIDocs() {
    return this._apiURI + '/api/docs';
  }
  getApiUploadURI() {
    return this._apiURI + '/api/upload';
  }
  getApiUploadImagenURI() {
    return this._apiURI + '/api/uploadImagen';
  }
  getApiTMPUploadURI() {
    return this._apiURI + '/upload/';
  }
  getApiImportCONTENEDORESURI() {
    return this._apiURI + '/api/importContenedores';
  }

   // Rutas de Taller
   getApiURITaller() {
    return this._apiURI + '/api/taller';
  }

  getApiURITallerInformes() {
    return this._apiURI + '/api/taller/informes';
  }

    // Rutas de Maestros
    getApiURImaestros() {
      return this._apiURI + '/api/maestros';
    }
    getApiURIentidades() {
      return this._apiURI + '/api/maestros/entidades';
    }
    getApiURIcontenedores() {
      return this._apiURI + '/api/maestros/contenedores';
    }

  // Facturacion -> SAP
  getApiURITallerSAP() {
    return this._apiURI + '/api/taller/partesTaller/facturacion';
  }

  // getApiUploadURI() {
  //   return this._apiURI + '/api/upload';
  // }
  // getApiImportURI() {
  //   return this._apiURI + '/api/import';
  // }
  // getApiImportMercanciaTXTURI() {
  //   return this._apiURI + '/api/importMercanciaTXT';
  // }
  // getApiImportMercanciaXLSURI() {
  //   return this._apiURI + '/api/importMercanciaXLS';
  // }
  // getApiImportAduanaTXTURI() {
  //   return this._apiURI + '/api/importAduanaTXT';
  // }
  // getApiImportURIN43() {
  //   return this._apiURI + '/api/importN43';
  // }
  // getApiImportSIIURI() {
  //   return this._apiURI + '/api/importSIIUpdate';
  // }
  // getApiImportCECOURI() {
  //   return this._apiURI + '/api/importCECO';
  // }
  // getApiUploadImagenURI() {
  //   return this._apiURI + '/api/uploadImagen';
  // }
  // getApiTMPUploadURI() {
  //   return this._apiURI + '/upload/';
  // }
  // getApiImportCODECOURI() {
  //   return this._apiURI + '/api/importCODECO';
  // }
  // getApiImportCOARRIURI() {
  //   return this._apiURI + '/api/importCOARRI';
  // }
  // getApiImportSTOCKURI() {
  //   return this._apiURI + '/api/importSTOCK';
  // }
  // getApiImportCONTENEDORESURI() {
  //   return this._apiURI + '/api/importContenedores';
  // }
  // getApiImportGrupajeURI() {
  //   return this._apiURI + '/api/importGrupaje';
  // }
  // getApiImportPartidaAlmacenURI() {
  //   return this._apiURI + '/api/importPartidaAlmacen';
  // }

  // // Rutas de Extras
  // getApiURIextrasSeguros() {
  //   return this._apiURI + '/api/extras/seguros';
  // }
  // getApiURIextrasCaja() {
  //   return this._apiURI + '/api/extras/caja';
  // }
  // getApiURIextrasIncidencia() {
  //   return this._apiURI + '/api/extras/incidencias';
  // }
  // getApiURIextrasSII() {
  //   return this._apiURI + '/api/extras/SII';
  // }
  // getApiURIextrasDocumentos() {
  //   return this._apiURI + '/api/extras/documentos';
  // }
  // getApiURIextrasNeoDOC() {
  //   return this._apiURI + '/api/extras/neodoc';
  // }

  // // Rutas de Comercial
  // getApiURIComercial() {
  //   return this._apiURI + '/api/comercial';
  // }
  // getApiURIOfertasInformes() {
  //   return this._apiURI + '/api/comercial/informes';
  // }

  // // Rutas de Maritimo
  // // Partidas
  // getApiURIMaritimo() {
  //   return this._apiURI + '/api/maritimo';
  // }
  // getApiURIMaritimoInformes() {
  //   return this._apiURI + '/api/maritimo/informes';
  // }
  // // Tarifas
  // getApiURItarifaTasas() {
  //   return this._apiURI + '/api/maritimo/tarifaTasas';
  // }
  // // Control de Equipo
  // getApiURIcontrolEquipo() {
  //   return this._apiURI + '/api/maritimo/controlEquipo';
  // }
  // // Facturacion -> SAP
  // getApiURIMaritimoCompletosSAP() {
  //   return this._apiURI + '/api/maritimo/completos/facturacion';
  // }
  // getApiURIMaritimoGrupajesSAP() {
  //   return this._apiURI + '/api/maritimo/grupajes/facturacion';
  // }
  // // Informes
  // getApiURICompletosInformes() {
  //   return this._apiURI + '/api/maritimo/completos/informes';
  // }
  // getApiURIGrupajesInformes() {
  //   return this._apiURI + '/api/maritimo/grupajes/informes';
  // }

  // // Rutas de Taller
  // getApiURITaller() {
  //   return this._apiURI + '/api/taller';
  // }

  // getApiURITallerInformes() {
  //   return this._apiURI + '/api/taller/informes';
  // }

  // // Facturacion -> SAP
  // getApiURITallerSAP() {
  //   return this._apiURI + '/api/taller/partesTaller/facturacion';
  // }

  // // Rutas de Terrestre
  // getApiURITerrestre() {
  //   return this._apiURI + '/api/terrestre';
  // }
  // getApiURITerrestreInformes() {
  //   return this._apiURI + '/api/terrestre/informes';
  // }

  // // Rutas de Operaciones
  // getApiURIOperaciones() {
  //   return this._apiURI + '/api/operaciones';
  // }
  // getApiURIOperacionesInformes() {
  //   return this._apiURI + '/api/operaciones/informes';
  // }

  // // Rutas de Aduanas
  // getApiURIAduanas() {
  //   return this._apiURI + '/api/aduanas';
  // }
  // getApiURIAduanasExportInformes() {
  //   return this._apiURI + '/api/aduanas/ordenesExport/informes';
  // }
  // getApiURIAduanasImportInformes() {
  //   return this._apiURI + '/api/aduanas/ordenesImport/informes';
  // }
  // // Facturacion -> SAP
  // getApiURIAduanasDosierExportSAP() {
  //   return this._apiURI + '/api/aduanas/ordenesExport/facturacion';
  // }
  // getApiURIAduanasDosierImportSAP() {
  //   return this._apiURI + '/api/aduanas/ordenesImport/facturacion';
  // }

  // // Rutas de Almacen
  // getApiURIAlmacen() {
  //   return this._apiURI + '/api/almacen';
  // }
  // getApiURIAlmacenInformes() {
  //   return this._apiURI + '/api/almacen/informes';
  // }

  // // Rutas de la API SAP
  // getSAPURI() {
  //   return this._apiURI + '/api/SAP';
  // }

  // // Rutas de la API SAP MAESTROS
  // getApiURImaestrosSAP() {
  //   return this._apiURI + '/api/maestros/SAP';
  // }

  // // Rutas de la API de Web JSV
  // getApiURIWebJSV() {
  //   return this._apiURI + '/api/WebJSV';
  // }

  //Ruta Soporte
  getApiURISoporte() {
    return this._apiURI + '/api/soporte';
  }

  //Ruta Control Versión
  getApiURIControlVersion() {
    return this._apiURI + '/api/version';
  }
}
