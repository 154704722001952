import { ContactoEntidad } from '@maestros/model/entidad.model';
import { StateGrid } from '@usuarios/model/columnsGrid';

export class Mercancia {
  constructor(public codEstadistico: string, public mercancia: string, public nombreCEE: string, public codigoCEE: string, public grupoT3: number, public tipoUdSuplementaria: any, public activo: boolean) {}
}
export class SearchMercancia {
  constructor(public mercancia: string, public codigoCEE: string, public grupoT3: number, public page: number, public filter: any, public numFilterRows: number, public gridState: StateGrid) {}
}

export class Lugar {
  constructor(
    public uuid: string,
    public placeId: string,
    public soloNombre: string,
    public alias: string,
    public nombre: string,
    public direccion: string,
    public localidad: string,
    public zona: any,
    public codPostal: string,
    public provincia: string,
    public pais: string,
    public cargaDescarga: boolean,
    public sace: boolean,
    public codSACE: string,
    public terminalMaritima: boolean,
    public terminalMaritimaAsoc: any,
    public terminalTren: boolean,
    public aduana: boolean,
    public codAduana: string,
    public almacen: boolean,
    public almacenAsoc: any,
    public depot: boolean,
    public taller: boolean,
    public tallerAsoc: any,
    public telefono: string,
    public movil: string,
    public email: string,
    public website: string,
    public horario: string,
    public url: string,
    public lat: string,
    public lng: string,
    public observaciones: string,
    public contactos: ContactoEntidad[]
  ) {}
}

export class SearchLugar {
  constructor(public nombre: any, public localidad: any, public provincia: any, public pais: any, public resPerPage: any, public page: number, public filter: any, public numFilterRows: number) {}
}

export class ConceptoFacturacionOferta {
  constructor(
    public uuid: string,
    public parentUUID: string,
    public childUUID: string,
    public concepto: any,
    public tieneIngreso: boolean,
    public pagador: any,
    public cantidadIngreso: number,
    public importe: any,
    public totalImporte: any,
    public esTarifaImporte: boolean,
    public tarifaImporte: string,
    public tarifaImporteUUID: string,
    public tienePrevision: boolean,
    public proveedor: any,
    public cantidadPrevision: number,
    public prevision: any,
    public totalPrevision: any,
    public esTarifaPrevision: boolean,
    public tarifaPrevision: string,
    public tarifaPrevisionUUID: string,
    public esMaritimo: boolean,
    public manual: boolean,
    public observaciones: string,
    public resultado: any
  ) {}
}

export class ConceptoFacturacion {
  constructor(
    public uuid: string,
    public parentUUID: string,
    public childUUID: string,
    public comercialConceptoFacturacionUUID: string,
    public concepto: any,
    public SAPSDid: string,
    public orgVentas: any,
    public tieneIngreso: boolean,
    public estadoSD: number,
    public pagador: any,
    public cantidadIngreso: number,
    public importe: any,
    public totalImporte: any,
    public facturadoSAPSD: any,
    public exportadoSAPSD: number,
    public posicionSAPSD: string,
    public numFacturaSAPSD: string,
    public esTarifaImporte: boolean,
    public tarifaImporte: string,
    public tarifaImporteUUID: string,
    public SAPMMid: string,
    public orgCompras: any,
    public tienePrevision: boolean,
    public estadoMM: number,
    public proveedor: any,
    public cantidadPrevision: number,
    public prevision: any,
    public totalPrevision: any,
    public facturadoSAPMM: any,
    public exportadoSAPMM: number,
    public posicionSAPMM: string,
    public numFacturaSAPMM: string,
    public esTarifaPrevision: boolean,
    public tarifaPrevision: string,
    public tarifaPrevisionUUID: string,
    public resultado: any,
    public manual: boolean,
    public observaciones: string
  ) {}
}

export class Etapa {
  constructor(public uuid: string, public parentUUID: string, public estado: number, public position: number, public code: any, public message: string, public info: string, public visit: boolean, public current: boolean, public status: string) {}
}

export class SerieFactura {
  constructor(public uuid: string, public idSerie: any, public empresa: any, public serie: any, public ambito: string, public contador: number, public fecha: any, public descripcion: string, public fechaInicio: any, public fechaFinal: any, public activo: boolean, public bloqueo: boolean, public rectificativa: boolean, public principal: boolean, public serieAsoc: any) {}
}

export class SearchSerieFactura {
  constructor(public empresa: any, public serie: any, public ambito: any, public activo: boolean, public principal: boolean, public bloqueo: boolean, public rectificativa: boolean, public resPerPage: any, public page: number, public filter: any, public numFilterRows: number) {}
}

export class Concepto {
  constructor(public id: string, public idConcepto: any, public conceptoAsociado: any, public nombre: string, public impresion: string, public modulo: any, public tipoServicio: any, public SAPid: string, public grupo: any, public clase: any, public GLIngreso: any, public GLGasto: any) {}
}

export class SearchConceptos {
  constructor(public idConcepto: any, public SAPid: any, public GLIngreso: any, public GLGasto: any, public nombre: any, public impresion: any, public tipoServicio: any, public grupo: any, public clase: any, public modulo: any, public resPerPage: any, public page: number, public filter: any, public numFilterRows: number) {}
}
