import { Component, OnInit, HostListener } from '@angular/core';

// Configuraciones de Componentes UI
import { UIComponentsService } from '@comun/services/uiComponents.service';
import { appsMenu, extrasMenu, usuariosMenu } from '@coreUI/model/apps.model';
import { CommonService } from '@comun/services/common.service';

@Component({
  templateUrl: './homeDashboard.html',
  providers: [UIComponentsService],
})
export class HomeDashboardComponent implements OnInit {
  public dataChart: any;
  public dataChart2: any;
  public dataChart3: any;
  public options: any;

  constructor(private _uiComponentService: UIComponentsService) {}

  ngOnInit() {
    this.dataChart = {
      labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul'],
      datasets: [
        {
          label: 'Alicante',
          data: [65, 59, 80, 81, 56, 55, 40],
          borderColor: '#377b89',
          backgroundColor: 'rgba(188,233,224,0.3)',
        },
        {
          label: 'Barcelona',
          data: [28, 48, 40, 19, 86, 27, 90],
          borderColor: '#52c7ae',
          backgroundColor: 'rgba(179,205,210,0.3)',
        },
      ],
    };

    this.dataChart2 = {
      labels: ['Alimentación', 'Refrigerado', 'Congelado', 'Hogar', 'Bebidas', 'Consumo', 'Textil'],
      datasets: [
        {
          label: 'Tenerife',
          backgroundColor: 'rgba(179,181,198,0.2)',
          borderColor: 'rgba(179,181,198,1)',
          pointBackgroundColor: 'rgba(179,181,198,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          data: [65, 59, 90, 81, 56, 55, 40],
        },
        {
          label: 'Las Palmas',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          pointBackgroundColor: 'rgba(255,99,132,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(255,99,132,1)',
          data: [28, 48, 40, 19, 96, 27, 100],
        },
      ],
    };

    this.dataChart3 = {
      labels: ['20 PW', '40 PW', '45 PW'],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        },
      ],
    };

    this.options = {
      legend: {
        display: false,
        position: 'top',
        labels: {
          boxWidth: 80,
          fontColor: 'black',
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
              color: 'black',
            },
            scaleLabel: {
              display: false,
              labelString: 'Time in Seconds',
              fontColor: 'red',
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
              color: 'black',
              borderDash: [2, 5],
            },
            scaleLabel: {
              display: false,
              labelString: 'Speed in Miles per Hour',
              fontColor: 'green',
            },
          },
        ],
      },
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setZoomIcon();
  }

  setZoomIcon() {
    return this._uiComponentService.setZoomIcon();
  }

  getMyStyles() {
    return this._uiComponentService.getMyStyles();
  }

  goLink(tipo, id) {
    let newTab;
    if (tipo === 'APPS') {
      newTab = this._uiComponentService.filterByProperty(appsMenu.APPS_MENU[0].P_CHILDMENUS, 'TABID', id);
    } else if (tipo === 'EXTRAS') {
      newTab = this._uiComponentService.filterByProperty(extrasMenu.EXTRAS_MENU[0].P_CHILDMENUS, 'TABID', id);
    } else if (tipo === 'USERS') {
      newTab = this._uiComponentService.filterByProperty(usuariosMenu.USER_MENU[0].P_CHILDMENUS, 'TABID', id);
    }
    CommonService.event('selectedTab', newTab[0]);
  }
}
