import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NuevoUsuarioService } from '@auth/services/nuevoUsuario.service';

import { NotificationBarService, NotificationType } from '@comun/UI/angular2-notification-bar';
import { ConfirmationService } from 'primeng/api';

// Servicio de configuracion de usuario
import { NuevoUsuario } from '@usuarios/model/usuario.model';

@Component({
  selector: 'NuevoUsuario',
  templateUrl: './nuevoUsuario.html',
  providers: [NuevoUsuarioService],
})
export class NuevoUsuarioComponent implements OnInit {
  public usuario: NuevoUsuario;
  public delegaciones: any[];
  public empresas: any[];
  public password: string;
  public passwordConfirm: string;
  public backEnd: boolean;
  // Variables del formulario
  public formNuevoUsuario: FormGroup;

  constructor(private _usuariosService: NuevoUsuarioService, private _formBuilder: FormBuilder, private confirmationService: ConfirmationService, private _router: Router, private _notificationBarService: NotificationBarService) {}

  ngOnInit() {
    this.usuario = new NuevoUsuario('', '', '', '', 0, 0, 'user_dummy.png');
    this.backEnd = !!sessionStorage.getItem('BackEnd');
    this.getDelegaciones();
    this.getEmpresas();
    this.createForm();
  }

  private createForm() {
    this.formNuevoUsuario = this._formBuilder.group({
      nombre: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required],
      delegacion: ['', Validators.required],
      empresa: ['', Validators.required],
    });
  }

  // Funciones auxiliares

  showNotification(tipoMsg: string, bodyMsg: string, autoHide: boolean, allowClose: boolean) {
    this._notificationBarService.create({
      message: bodyMsg,
      type: NotificationType[tipoMsg],
      autoHide: autoHide,
      allowClose: allowClose,
    });
  }

  confirm() {
    this.confirmationService.confirm({
      message: 'Se enviará la solicitud de registro al administrador. Si tus datos son correctos, en breve recibirás un correo electrónico con la validación de acceso',
      accept: () => {
        this.onSubmit();
      },
    });
  }

  // Submit

  onSubmit() {
    if (this.backEnd) {
      this._usuariosService.addNuevoUsuario(this.usuario).subscribe((result) => {
        console.log('Nuevo usuario creado!');
        this.showNotification('Success', 'Usuario registrado con exito', true, true);
        setTimeout(() => {
          this._router.navigate(['/login']);
        }, 1250);
      });
    } else {
      this.showNotification('Error', 'No tienes conexión a Internet', false, true);
    }
  }

  // Funciones de datos para formularios

  getDelegaciones() {
    this._usuariosService.getDelegaciones().subscribe((result) => {
      this.delegaciones = result['data'];
    });
  }

  getEmpresas() {
    this._usuariosService.getEmpresas().subscribe((result) => {
      this.empresas = result['data'];
      console.log(this.empresas);
    });
  }
}
