<nz-layout class="layout">

  <nz-header>
    <div class="logo fc-header-left">
      <img src="./assets/images/logo.png" [ngStyle]="{'display': menuWidth===40 ?'none':'inline'}" alt="logo">
      <img src="./assets/images/small-logo.png" [ngStyle]="{'display': menuWidth===40 ?'inline':'none'}" alt="logo">
    </div>
    <div class="container-fluid">

      <ul class="nav navbar-nav hidden-md-down">
        <li class="nav-item">
          <a class="nav-link navbar-toggler menubar-toggle" (click)="toggleLayout()"
            style="margin-top: 8px; height: 40px;">
            &nbsp;<i class="fa fa-bars"></i>&nbsp;
          </a>
        </li>
        <li class="nav-item p-x-1">
          <a class="nav-link" href="#" (click)="goToDashboard()" style="display: flex; align-items: center;">
            <i class="icon-home"></i>&nbsp;&nbsp; {{ 'main.home' | translate }}</a>
        </li>
        <!--
              <li class="nav-item p-x-1">
                  <a class="nav-link" href="#" routerLinkActive="active" [routerLink]="['/SAP/infoSAP/inicio']" openNewTab><i class="icon-heart"></i>&nbsp;&nbsp; Favoritos</a>
              </li>
              -->
        <li class="nav-item p-x-1 dropdown" dropdown (onToggle)="toggled($event)">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="false" dropdownToggle routerLinkActive="active" style="display: flex; align-items: center;">
            <i class="icon-heart"></i>&nbsp;&nbsp; {{ 'main.favoritos' | translate }}
          </a>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
            <div class="dropdown-header text-xs-center">
              <strong>Mis Favoritos</strong>
            </div>
            <a class="dropdown-item" style="color:dimgray;" href="#" (click)="goFavorito(fav.tipo, fav.codigo)" *ngFor="let fav of misFavoritos; let i=index">
              <i class="{{ fav.icon }}" style="color: #1890ff;"></i> {{fav.nombre}} </a>
          </div>
        </li>
      </ul>
      <ul class="nav navbar-nav pull-right">
        <li class="nav-item dropdown" dropdown (onToggle)="toggled($event)">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="false" dropdownToggle style="display: flex; align-items: center;">
            <i class="icon-bell"></i>
            <span *ngIf="numMessages >= 0" [ngClass]="bubbleClass" class="bubble-mensajes">{{numMessages+1}}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">

            <div class="message" style="padding: 8px; width: 280px;  line-height: 20px;"
              *ngFor="let msg of revConversation; let i=index">
              <div class="text-truncate font-weight-bold" style="padding-bottom: 4px;">
                <button type="button" class="close" (click)="deleteMessage(msg)" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="avatar avatar-md">
                  <img src="{{apiImagenAvatar + '/getImagenAvatar/' + msg.userImagen }}" class="img-avatar">
                </div>
                <small class="text-truncate  font-weight-bold" style="padding: 10px">{{msg.username}}</small>
                <small class="text-muted pull-right m-t-q" style="padding: 1px;">{{msg.date | timeAgo }}</small>
              </div>
              <small class="text-muted-chat">{{msg.message}}</small>
              <hr>
            </div>

          </div>
        </li>
        <!-- <li class="nav-item hidden-md-down">
          <a class="nav-link mano" (click)="procesoHorario(true, 'init')">
            <i class="icon-clock"></i>
          </a>
        </li> -->

        <li class="nav-item dropdown" dropdown (onToggle)="toggled($event)">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="false" dropdownToggle>
            <img id="image-logged" src="{{apiImagenAvatar + '/getImagenAvatar/' + miUsuario.imagen }}"
              class="img-avatar" alt="assets/images/avatars/user_dummy.jpg">
            <span id="usuario-nombre" class="hidden-md-down">{{ miUsuario.nombre }}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">

            <div class="dropdown-header text-xs-center">
              <strong>General</strong>
            </div>

            <a class="dropdown-item" (click)="goFavorito('USERS', 'U1HO')">
              <i class="fa fa-bell-o"></i> Autorizaciones
              <span class="tag tag-info">{{numAutorizaciones}}</span>
            </a>
            <a class="dropdown-item" href="#" [routerLink]="['/usuarios/suscripciones/buscar']">
              <i class="fa fa-volume-up"></i> Suscripciones
              <span class="tag tag-success">{{ numSuscripciones }}</span>
            </a>
            <!-- <a class="dropdown-item" href="#">
              <i class="fa fa-tasks"></i> Emails
              <span class="tag tag-danger">42</span>
            </a>
            <a class="dropdown-item" href="#">
              <i class="fa fa-comments"></i> Comentarios
              <span class="tag tag-warning">42</span>
            </a> -->

            <div class="dropdown-header text-xs-center">
              <strong>Configuraci&oacute;n</strong>
            </div>

            <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/usuarios/miUsuario']">
              <i class="fa fa-user"></i> Mi usuario</a>
            <!-- <a class="dropdown-item" href="#">
              <i class="fa fa-wrench"></i> Ajustes</a> -->
            <a *ngIf="miUsuario.sadmin" class="dropdown-item" (click)="goFavorito('USERS', 'U0HO')">
              <i class="fa fa-file"></i> Usuarios </a>
            <!-- <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/usuarios/controlHorario/buscar']">
              <i class="icon-clock"></i> Registro Horario </a> -->
            <div class="divider"></div>
            <a class="dropdown-item mano" (click)="openNovedadesVersion()">
              <i class="fa icon-social-dropbox"></i> Novedades</a>
            <a class="dropdown-item mano" (click)="onLogout()">
              <i class="fa fa-lock"></i> Cerrar sesi&oacute;n</a>
          </div>
        </li>

        <li class="nav-item dropdown" dropdown (onToggle)="toggled($event)">
          <a class="nav-link dropdown-toggle" style="display: list-item;" data-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="false" dropdownToggle style="display: flex; align-items: center;">
            <img src="https://cdn.wpml.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/{{ _translateService.currentLang }}.png"
                  alt="Spanish">
          </a>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">

            <div class="dropdown-header text-xs-center">
              <strong>{{ 'main.idioma' | translate }}</strong>
            </div>

              <a class="dropdown-item" href="#" (click)="setLanguage('es')">
                <img src="https://cdn.wpml.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/es.png"
                  alt="Spanish" style="padding-right: 12px;"> {{ 'main.espanyol' | translate }}
              </a>
              <a class="dropdown-item" href="#" (click)="setLanguage('en')">
                <img src="https://cdn.wpml.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png"
                  alt="English"  style="padding-right: 12px;"> {{ 'main.ingles' | translate }}
              </a>
              <a class="dropdown-item" href="#" (click)="setLanguage('it')">
                <img src="https://cdn.wpml.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/it.png"
                  alt="Italian"  style="padding-right: 12px;"> {{ 'main.italiano' | translate }}
              </a>
              <a class="dropdown-item" href="#" (click)="setLanguage('de')">
                <img src="https://cdn.wpml.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/de.png"
                  alt="German" style="padding-right: 12px;"> {{ 'main.aleman' | translate }}
              </a>
              <a class="dropdown-item" href="#" (click)="setLanguage('fr')">
                <img src="https://cdn.wpml.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/fr.png"
                  alt="French"  style="padding-right: 12px;"> {{ 'main.frances' | translate }}
              </a>
              <a class="dropdown-item" href="#" (click)="setLanguage('jp')">
                <img src="https://cdn.wpml.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/ja.png"
                  alt="Japanese"  style="padding-right: 12px;"> {{ 'main.japones' | translate }}
              </a>
              <a class="dropdown-item" href="#" (click)="setLanguage('ru')">
                <img src="https://cdn.wpml.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/ru.png"
                  alt="Russian"  style="padding-right: 12px;"> {{ 'main.ruso' | translate }}
              </a>
              <a class="dropdown-item" href="#" (click)="setLanguage('cn')" >
                <img src="https://cdn.wpml.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/zh.png"
                  alt="Chinese" style="padding-right: 12px;"> {{ 'main.chino' | translate }}
              </a>
              <a class="dropdown-item" href="#" (click)="setLanguage('ar')">
                <img src="https://cdn.wpml.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/ar.png"
                  alt="Arabic"  style="padding-right: 12px;"> {{ 'main.arabe' | translate }}
              </a>
            </div>
        </li>

        <li class="nav-item">
          <a class="nav-link navbar-toggler aside-toggle" href="#" style="margin-top: 8px; height: 40px;">
            &nbsp;<i class="fa fa-bars"></i>&nbsp;
          </a>
        </li>
        <li class="nav-item hidden-md-down">
          <button class="nav-link navbar-toggler toggleFullscreen" style="margin-top: 8px; height: 40px;" (click)="toggleFullscreen()">
            <i class="fa fa-arrows-alt"></i>
          </button>
        </li>
      </ul>

    </div>
  </nz-header>

  <nz-layout style="width: 100%;">

    <nz-sider [(nzWidth)]="menuWidth" class="fc-sidebar-menu">

      <ul nz-menu [nzMode]="'inline'" [nzTheme]="'dark'"
        style="height: calc(100% - 40px); overflow-y: auto; overflow-x: hidden;" [nzInlineCollapsed]="menuIsCollapsed">

        <li nz-menu-item (click)="goToDashboard()">
          <span title>
            <i class="side-bar-icons icon-speedometer"></i>
            <span class="first-level-title title-section-side-menu">Dashboard</span>
          </span>
        </li>

        <nz-divider style="background: #414d5d;"></nz-divider>

        <li nz-menu-item [nzDisabled]="true" class="seccion-title" style="cursor: default; height: 24px;">
          <span title>
            <span class="first-level-title title-section-side-menu">APLICACIONES</span>
          </span>
        </li>

        <ng-container *ngFor="let menu of _apps">

          <li nz-menu-item *ngIf="menu.HASCHILD==='N'" (click)="selectedMenu(menu)">
            <span title>
              <i class="side-bar-icons" [ngClass]="menu.TABICON"></i>
              <span class="first-level-title" style="padding-left: 10px;">{{menu.TABNAME}}</span>
            </span>
          </li>

          <li nz-submenu [nzOpen]="false"
            *ngIf="menu.HASCHILD==='Y' && menu.P_CHILDMENUS && menu.P_CHILDMENUS.length!==0">
            <span title>
              <i class="side-bar-icons" [ngClass]="menu.TABICON"></i>
              <span class="first-level-title" style="padding-left: 10px;">{{menu.TABNAME}}</span>
            </span>
            <ul>
              <ng-container *ngFor="let childMenu of menu.P_CHILDMENUS">

                <li nz-menu-item *ngIf="childMenu.HASCHILD==='N'" (click)="selectedMenu(childMenu);">
                  <span title>
                    <i class="side-bar-icons" [ngClass]="childMenu.TABICON"></i>
                    <span style="padding-left: 10px;">{{childMenu.TABNAME}}</span>
                  </span>
                </li>

                <li nz-submenu
                  *ngIf="childMenu.HASCHILD==='Y' && childMenu.P_CHILDMENUS && childMenu.P_CHILDMENUS.length!==0">
                  <span title>
                    <i class="side-bar-icons" [ngClass]="childMenu.TABICON"></i>
                    <span style="padding-left: 10px;">{{childMenu.TABNAME}}</span>
                  </span>
                  <ul>
                    <ng-container *ngFor="let grandSonMenu of childMenu.P_CHILDMENUS">

                      <li nz-menu-item *ngIf="grandSonMenu.HASCHILD==='N'" (click)="selectedMenu(grandSonMenu);">
                        <i class="side-bar-icons" [ngClass]="grandSonMenu.TABICON"></i>
                        <span title style="padding-left: 10px;">{{grandSonMenu.TABNAME}}</span>
                      </li>

                      <li nz-submenu
                        *ngIf="grandSonMenu.HASCHILD==='Y' && grandSonMenu.P_CHILDMENUS && grandSonMenu.P_CHILDMENUS.length!==0">
                        <i class="side-bar-icons" [ngClass]="grandSonMenu.TABICON"></i>
                        <span title style="padding-left: 10px;">{{ grandSonMenu.TABNAME}}</span>
                        <ul>
                          <ng-container *ngFor="let lastChidMenu of grandSonMenu.P_CHILDMENUS">
                            <li nz-menu-item (click)="selectedMenu(lastChidMenu)">
                              <i class="side-bar-icons" [ngClass]="lastChidMenu.TABICON"></i>
                              <span title style="padding-left: 10px;">{{ lastChidMenu.TABNAME}}</span>
                            </li>
                          </ng-container>
                        </ul>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>

        <nz-divider style="background: #414d5d;"></nz-divider>

        <li nz-menu-item [nzDisabled]="true" class="seccion-title" style="cursor: default; height: 24px;">
            <span title>
              <span class="first-level-title title-section-side-menu">EXTRAS</span>
            </span>
          </li>

        <ng-container *ngFor="let menu of _extras">

          <li nz-menu-item *ngIf="menu.HASCHILD==='N'" (click)="selectedMenu(menu)">
            <span title>
              <i class="side-bar-icons" [ngClass]="menu.TABICON"></i>
              <span class="first-level-title" style="padding-left: 10px;">{{menu.TABNAME}}</span>
            </span>
          </li>

          <li nz-submenu [nzOpen]="false"
            *ngIf="menu.HASCHILD==='Y' && menu.P_CHILDMENUS && menu.P_CHILDMENUS.length!==0">
            <span title>
              <i class="side-bar-icons" [ngClass]="menu.TABICON"></i>
              <span class="first-level-title" style="padding-left: 10px;">{{menu.TABNAME}}</span>
            </span>
            <ul>
              <ng-container *ngFor="let childMenu of menu.P_CHILDMENUS">

                <li nz-menu-item *ngIf="childMenu.HASCHILD==='N'" (click)="selectedMenu(childMenu);">
                  <span title>
                    <i class="side-bar-icons" [ngClass]="childMenu.TABICON"></i>
                    <span style="padding-left: 10px;">{{childMenu.TABNAME}}</span>
                  </span>
                </li>

                <li nz-submenu
                  *ngIf="childMenu.HASCHILD==='Y' && childMenu.P_CHILDMENUS && childMenu.P_CHILDMENUS.length!==0">
                  <span title>
                    <i class="side-bar-icons" [ngClass]="childMenu.TABICON"></i>
                    <span style="padding-left: 10px;">{{childMenu.TABNAME}}</span>
                  </span>
                  <ul>
                    <ng-container *ngFor="let grandSonMenu of childMenu.P_CHILDMENUS">

                      <li nz-menu-item *ngIf="grandSonMenu.HASCHILD==='N'" (click)="selectedMenu(grandSonMenu);">
                        <i class="side-bar-icons" [ngClass]="grandSonMenu.TABICON"></i>
                        <span title style="padding-left: 10px;">{{grandSonMenu.TABNAME}}</span>
                      </li>

                      <li nz-submenu
                        *ngIf="grandSonMenu.HASCHILD==='Y' && grandSonMenu.P_CHILDMENUS && grandSonMenu.P_CHILDMENUS.length!==0">
                        <i class="side-bar-icons" [ngClass]="grandSonMenu.TABICON"></i>
                        <span title style="padding-left: 10px;">{{ grandSonMenu.TABNAME}}</span>
                        <ul>
                          <ng-container *ngFor="let lastChidMenu of grandSonMenu.P_CHILDMENUS">
                            <li nz-menu-item (click)="selectedMenu(lastChidMenu)">
                              <i class="side-bar-icons" [ngClass]="lastChidMenu.TABICON"></i>
                              <span title style="padding-left: 10px;">{{ lastChidMenu.TABNAME}}</span>
                            </li>
                          </ng-container>
                        </ul>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>

        <nz-divider style="background: #414d5d;"></nz-divider>

        <li nz-menu-item [nzDisabled]="true" class="seccion-title" style="cursor: default; height: 24px;">
            <span title>
              <span class="first-level-title title-section-side-menu">SISTEMA</span>
            </span>
          </li>

          <ng-container *ngFor="let menu of _sistema">

            <li nz-menu-item *ngIf="menu.HASCHILD==='N'" (click)="selectedMenu(menu)">
              <span title>
                <i class="side-bar-icons" [ngClass]="menu.TABICON"></i>
                <span class="first-level-title" style="padding-left: 10px;">{{menu.TABNAME}}</span>
              </span>
            </li>

            <li nz-submenu [nzOpen]="false"
              *ngIf="menu.HASCHILD==='Y' && menu.P_CHILDMENUS && menu.P_CHILDMENUS.length!==0">
              <span title>
                <i class="side-bar-icons" [ngClass]="menu.TABICON"></i>
                <span class="first-level-title" style="padding-left: 10px;">{{menu.TABNAME}}</span>
              </span>
              <ul>
                <ng-container *ngFor="let childMenu of menu.P_CHILDMENUS">

                  <li nz-menu-item *ngIf="childMenu.HASCHILD==='N'" (click)="selectedMenu(childMenu);">
                    <span title>
                      <i class="side-bar-icons" [ngClass]="childMenu.TABICON"></i>
                      <span style="padding-left: 10px;">{{childMenu.TABNAME}}</span>
                    </span>
                  </li>

                  <li nz-submenu
                    *ngIf="childMenu.HASCHILD==='Y' && childMenu.P_CHILDMENUS && childMenu.P_CHILDMENUS.length!==0">
                    <span title>
                      <i class="side-bar-icons" [ngClass]="childMenu.TABICON"></i>
                      <span style="padding-left: 10px;">{{childMenu.TABNAME}}</span>
                    </span>
                    <ul>
                      <ng-container *ngFor="let grandSonMenu of childMenu.P_CHILDMENUS">

                        <li nz-menu-item *ngIf="grandSonMenu.HASCHILD==='N'" (click)="selectedMenu(grandSonMenu);">
                          <i class="side-bar-icons" [ngClass]="grandSonMenu.TABICON"></i>
                          <span title style="padding-left: 10px;">{{grandSonMenu.TABNAME}}</span>
                        </li>

                        <li nz-submenu
                          *ngIf="grandSonMenu.HASCHILD==='Y' && grandSonMenu.P_CHILDMENUS && grandSonMenu.P_CHILDMENUS.length!==0">
                          <i class="side-bar-icons" [ngClass]="grandSonMenu.TABICON"></i>
                          <span title style="padding-left: 10px;">{{ grandSonMenu.TABNAME}}</span>
                          <ul>
                            <ng-container *ngFor="let lastChidMenu of grandSonMenu.P_CHILDMENUS">
                              <li nz-menu-item (click)="selectedMenu(lastChidMenu)">
                                <i class="side-bar-icons" [ngClass]="lastChidMenu.TABICON"></i>
                                <span title style="padding-left: 10px;">{{ lastChidMenu.TABNAME}}</span>
                              </li>
                            </ng-container>
                          </ul>
                        </li>
                      </ng-container>
                    </ul>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>

          <li nz-menu-item (click)="onLogout()">
            <span title>
              <i class="side-bar-icons fa fa-lock"></i>
              <span class="first-level-title" style="padding-left: 10px;">Bloquear</span>
            </span>
          </li>

          <li nz-menu-item (click)="onLogout()">
            <span title>
              <i class="side-bar-icons icon-logout" style="margin-left: -5px;"></i>
              <span class="first-level-title" style="padding-left: 10px;">Salir</span>
            </span>
          </li>

      </ul>

      <div class="layout-toggle">
        <i class="icon iconfont fc-icon-arrowleft" [ngClass]="{'arrowright':menuWidth===40}" (click)="toggleLayout()">
        </i>
      </div>
    </nz-sider>

    <nz-layout>

      <nz-tabset class="fc-tabnav" [nzType]="'card'" [(nzSelectedIndex)]="tabSelectedIndex">
        <nz-tab *ngFor="let tab of stackTabs;index as i" (nzClick)="selectedTabMenu(tab)" [nzTitle]="titleTemplate">
          <ng-template #titleTemplate>
            <div [ngClass]="{'tab-home':i===0}">
              <i *ngIf="tab.icon!==''" [ngClass]="tab.icon"></i>
              &nbsp;{{ tab.name }}<i *ngIf="i>0" nz-icon [nzType]="'close'" class="ant-tabs-close-x"
                (click)="closeTabNav(tab,true)"></i>
            </div>
          </ng-template>
        </nz-tab>
      </nz-tabset>

      <nz-content class="fc-main-wrap">
        <div class="fc-main">
          <router-outlet [ngStyle]="{'height':'auto'}"></router-outlet>
        </div>
      </nz-content>

      <div class="float_corner_right" *ngIf="cumpleanyos">
        <img src="./assets/images/icons/tarta.png">
      </div>

      <nz-footer><span *ngIf="serverMessage" class="pull-left">
          <img src="./assets/images/icons/alarm.png"
            style="height: 26px; vertical-align: top; top: 3px; position: relative;">&nbsp;&nbsp;
          <span style="font-weight: bold; color: #bf0101;">{{ server }}</span>
        </span>
        <span *ngIf="cumpleanyos" class="pull-left">
          <span style="font-weight: bold; color: #bf0101;">¡¡¡ Feliz Cumpleaños !!!</span>
        </span>
        <span class="pull-right">
          <a href="http://www.jsvlogistic.com">Grupo JSV</a> &nbsp; &copy; 2016 - versión: {{ version }}
        </span>
      </nz-footer>

    </nz-layout>

  </nz-layout>

</nz-layout>

<aside class="aside-menu">
  <tabset>
    <tab>
      <ng-template tabHeading>
        <i class="icon-list"></i>
      </ng-template>
      <div class="callout m-a-0 p-y-h text-muted text-xs-center bg-faded text-uppercase">
        <small>
          <b>Today</b>
        </small>
      </div>
      <hr class="transparent m-x-1 m-y-0">
      <div class="callout callout-warning m-a-0 p-y-1">
        <div class="avatar pull-xs-right">
          <img src="./assets/images/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
        </div>
        <div>Meeting with
          <strong>Lucas</strong>
        </div>
        <small class="text-muted m-r-1">
          <i class="icon-calendar"></i>&nbsp; 1 - 3pm</small>
        <small class="text-muted">
          <i class="icon-location-pin"></i>&nbsp; Palo Alto, CA</small>
      </div>
      <hr class="m-x-1 m-y-0">
      <div class="callout callout-info m-a-0 p-y-1">
        <div class="avatar pull-xs-right">
          <img src="./assets/images/avatars/4.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
        </div>
        <div>Skype with
          <strong>Megan</strong>
        </div>
        <small class="text-muted m-r-1">
          <i class="icon-calendar"></i>&nbsp; 4 - 5pm</small>
        <small class="text-muted">
          <i class="icon-social-skype"></i>&nbsp; On-line</small>
      </div>
      <hr class="transparent m-x-1 m-y-0">
      <div class="callout m-a-0 p-y-h text-muted text-xs-center bg-faded text-uppercase">
        <small>
          <b>Tomorrow</b>
        </small>
      </div>
      <hr class="transparent m-x-1 m-y-0">
      <div class="callout callout-danger m-a-0 p-y-1">
        <div>New UI Project -
          <strong>deadline</strong>
        </div>
        <small class="text-muted m-r-1">
          <i class="icon-calendar"></i>&nbsp; 10 - 11pm</small>
        <small class="text-muted">
          <i class="icon-home"></i>&nbsp; creativeLabs HQ</small>
        <div class="avatars-stack m-t-h">
          <div class="avatar avatar-xs">
            <img src="./assets/images/avatars/2.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="./assets/images/avatars/3.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="./assets/images/avatars/4.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="./assets/images/avatars/5.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
        </div>
      </div>
      <hr class="m-x-1 m-y-0">
      <div class="callout callout-success m-a-0 p-y-1">
        <div>
          <strong>#10 Startups.Garden</strong>Meetup</div>
        <small class="text-muted m-r-1">
          <i class="icon-calendar"></i>&nbsp; 1 - 3pm</small>
        <small class="text-muted">
          <i class="icon-location-pin"></i>&nbsp; Palo Alto, CA</small>
      </div>
      <hr class="m-x-1 m-y-0">
      <div class="callout callout-primary m-a-0 p-y-1">
        <div>
          <strong>Team meeting</strong>
        </div>
        <small class="text-muted m-r-1">
          <i class="icon-calendar"></i>&nbsp; 4 - 6pm</small>
        <small class="text-muted">
          <i class="icon-home"></i>&nbsp; creativeLabs HQ</small>
        <div class="avatars-stack m-t-h">
          <div class="avatar avatar-xs">
            <img src="./assets/images/avatars/2.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="./assets/images/avatars/3.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="./assets/images/avatars/4.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="./assets/images/avatars/5.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="./assets/images/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="./assets/images/avatars/8.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
        </div>
      </div>
      <hr class="m-x-1 m-y-0">
    </tab>
    <tab>
      <ng-template tabHeading>
        <i class="icon-speech"></i>
      </ng-template>
      <div class="conversation-container">
        <div style="display: block; float: right; cursor: pointer; margin-top: 4px; margin-bottom: 4px;">
          <img style="padding-left: 2px;" src="./assets/images/icons/limpiar2.png" (click)="deleteAllMessage()">
          <img src="./assets/images/icons/enviar2.png" (click)="click2send()">
        </div>

        <div class="conversation-compose">
          <textarea pInputTextarea class="input-msg" [rows]="1" [cols]="16" autoResize="autoResize"
            placeholder="Escribir mensaje..." [(ngModel)]="message" (keypress)="keypressHandler($event)"></textarea>

        </div>
        <div class="message" *ngFor="let msg of revConversation; let i=index">
          <div class="text-truncate font-weight-bold">
            <button type="button" class="close" (click)="deleteMessage(msg)" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="avatar avatar-md">
              <img src="{{apiImagenAvatar + '/getImagenAvatar/' + msg.userImagen }}" class="img-avatar">
              <span class="avatar-status tag-success"></span>
            </div>
            <small class="text-truncate  font-weight-bold" style="padding: 10px">{{msg.username}}</small>
            <small class="text-muted pull-right m-t-q" style="padding: 1px;">{{msg.date | timeAgo }}</small>
          </div>
          <small class="text-muted-chat">{{msg.message}}</small>
          <hr>
        </div>
      </div>
    </tab>
    <tab>
      <ng-template tabHeading>
        <i class="icon-settings"></i>
      </ng-template>
      <div class="p-a-1">
        <h6>Ajustes</h6>

        <div class="aside-options">
          <div class="clearfix m-t-2">
            <small>
              <b>Option 1</b>
            </small>
            <label class="switch switch-text switch-pill switch-success switch-sm pull-right">
              <input type="checkbox" class="switch-input" checked>
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore
              magna aliqua.</small>
          </div>
        </div>

        <div class="aside-options">
          <div class="clearfix m-t-1">
            <small>
              <b>Option 2</b>
            </small>
            <label class="switch switch-text switch-pill switch-success switch-sm pull-right">
              <input type="checkbox" class="switch-input">
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore
              magna aliqua.</small>
          </div>
        </div>

        <div class="aside-options">
          <div class="clearfix m-t-1">
            <small>
              <b>Option 3</b>
            </small>
            <label class="switch switch-text switch-pill switch-success switch-sm pull-right">
              <input type="checkbox" class="switch-input">
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>

        <div class="aside-options">
          <div class="clearfix m-t-1">
            <small>
              <b>Option 4</b>
            </small>
            <label class="switch switch-text switch-pill switch-success switch-sm pull-right">
              <input type="checkbox" class="switch-input" checked>
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>

        <hr>
        <h6>System Utilization</h6>

        <div class="text-uppercase m-b-q m-t-2">
          <small>
            <b>CPU Usage</b>
          </small>
        </div>
        <progress class="progress progress-xs progress-info m-a-0" value="25" max="100">25%</progress>
        <small class="text-muted">348 Processes. 1/4 Cores.</small>

        <div class="text-uppercase m-b-q m-t-h">
          <small>
            <b>Memory Usage</b>
          </small>
        </div>
        <progress class="progress progress-xs progress-warning m-a-0" value="70" max="100">70%</progress>
        <small class="text-muted">11444GB/16384MB</small>

        <div class="text-uppercase m-b-q m-t-h">
          <small>
            <b>SSD 1 Usage</b>
          </small>
        </div>
        <progress class="progress progress-xs progress-danger m-a-0" value="95" max="100">95%</progress>
        <small class="text-muted">243GB/256GB</small>

        <div class="text-uppercase m-b-q m-t-h">
          <small>
            <b>SSD 2 Usage</b>
          </small>
        </div>
        <progress class="progress progress-xs progress-success m-a-0" value="10" max="100">10%</progress>
        <small class="text-muted">25GB/256GB</small>
      </div>
    </tab>
  </tabset>
</aside>

<p-confirmDialog header="Confirmación" icon="fa fa-question-circle" acceptLabel="Si" rejectLabel="No"
  [style]="{width: '450px'}"></p-confirmDialog>
<!-- <novedadesVersion-modal (onClose)="onNovedadesVersionClose($event);"></novedadesVersion-modal> -->

<!-- <p-dialog header="Registro Horario" [(visible)]="showDialogHorario" modal="modal" [responsive]="true"
  [contentStyle]="{'overflow':'visible', 'max-height':'640px','width':'550'}">
  <div class="row clearfix">
    <div class="col-lg-8">
      <div class="form-group">
        <label class="control-label">Último registro</label>
        <b>{{ horarioUtlMov + horarioUtlFecha }}</b>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-group">
        <div class="clearfix m-t-2" style="width: 120px;">
          <label class="control-label" style="display: inline-block;">Manual?</label>
          <label class="switch switch-text switch-pill switch-success switch-md pull-right">
            <input [(ngModel)]="horarioManual" type="checkbox" class="switch-input">
            <span class="switch-label" data-on="Si" data-off="No"></span>
            <span class="switch-handle"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row clearfix" *ngIf="horarioManual">
    <div class="col-lg-6">
      <div class="form-group">
        <label class="control-label">Fecha - Hora</label>
        <p-calendar [(ngModel)]="horarioFecha" [locale]="es" dateFormat="dd/mm/yy" [showIcon]="true" [showTime]="true"
          placeholder="Fecha - Hora..." styleClass="DisplayBlock">
        </p-calendar>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="form-group">
        <label class="control-label">Observaciones</label>
        <textarea class="textarea-center" pInputTextarea rows="3" cols="40" autoResize="autoResize"
          [(ngModel)]="horarioObs">
            </textarea>
      </div>
    </div>
  </div>
  <div class="row clearfix">
    <div class="col-lg-6" style="text-align: center">
      <button type="button" class="ui-button-success" style="zoom: 4" pButton icon="fa fa-sign-in fa fa-stack-2x"
        (click)="procesoHorario(false, 'entrar')"></button>
    </div>
    <div class="col-lg-6" style="text-align: center">
      <button type="button" class="ui-button-danger" style="zoom: 4" pButton icon="fa fa-sign-out fa fa-stack-2x"
        (click)="procesoHorario(false, 'salir')"></button>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="fa fa-close" (click)="procesoHorario(false, 'cancel')"
      label="Cancelar"></button>
  </p-footer>
</p-dialog> -->
