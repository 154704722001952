import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APIRoutesService } from '@app/services/apiRoutes.service';
import { Usuario, Autorizacion, UsuarioVarianteGrid } from '@usuarios/model/usuario.model';
import { InfoState } from '@comun/model/comun.model';
import { ConceptoFacturacion, Lugar } from 'modulos/Maestros/model/maestros.model';
import { Contenedor, ClaseContenedor, TipoContenedor } from '@maestros/model/contenedor.model';
import { IncidenciaTaller, MaterialTrabajo, ParteTaller, Presupuesto, TrabajoTaller } from '@taller/model/taller.model';
import { MovSACE } from '@comun/model/SACE.model';
import { ContactoEntidad, Entidad, InfoFacturacionEntidad } from '@maestros/model/entidad.model';

@Injectable()
export class UIComponentsService {
  public es: any;
  public innerHeight: any;
  public innerWidth: any;
  public options: any;
  public apiURLAlive: string;

  constructor(private _http: HttpClient, private _APIRoutesService: APIRoutesService) {
    this.apiURLAlive = _APIRoutesService.getApiURIAlive();
  }

  setCalendar() {
    this.es = {
      firstDayOfWeek: 1,
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mie', 'jue', 'vie', 'sab'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    };

    return this.es;
  }
  // Input: Date -> Output: MySQL Date (YYYY-mm-dd)
  toMySQLdate(fecha: Date) {
    let year = fecha.getFullYear();
    let numMonth = fecha.getMonth() + 1;
    let month = numMonth.toString();
    let day = fecha.getDate().toString();

    day = this.addCero(day);
    month = this.addCero(month);

    return year + '-' + month + '-' + day;
  }

  // Input: Date -> Output: MySQL Date (YYYY-mm-dd hh:ii:ss)
  toMySQLdatetime(fecha: Date) {
    let year = fecha.getFullYear();
    let numMonth = fecha.getMonth() + 1;
    let month = numMonth.toString();
    let day = fecha.getDate().toString();
    let hours = fecha.getHours().toString();
    let minutes = fecha.getMinutes().toString();

    day = this.addCero(day);
    month = this.addCero(month);
    hours = this.addCero(hours);
    minutes = this.addCero(minutes);

    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00';
  }

  // Input: Date -> Output: MySQL Date (hh:ii:ss)
  toMySQLtime(fecha: Date) {
    let hours = fecha.getHours().toString();
    let minutes = fecha.getMinutes().toString();

    hours = this.addCero(hours);
    minutes = this.addCero(minutes);

    return hours + ':' + minutes + ':00';
  }

  // Input: Date -> Output: MySQL Date (dd/mm/YYYY hh:ii)
  toISOESdatetime(fecha: Date) {
    let year = fecha.getFullYear();
    let numMonth = fecha.getMonth() + 1;
    let month = numMonth.toString();
    let day = fecha.getDate().toString();
    let hours = fecha.getHours().toString();
    let minutes = fecha.getMinutes().toString();
    day = this.addCero(day);
    month = this.addCero(month);
    hours = this.addCero(hours);
    minutes = this.addCero(minutes);

    return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
  }

  // Input: String (dd/mm/YYYY) -> Output: MySQL Date (YYYY-mm-dd)
  toMySQLdateFromInputES(fecha: string) {
    let splitFecha = fecha.split('/');

    let resDay = splitFecha[0];
    let resMonth = splitFecha[1];
    let year = splitFecha[2];

    return year + '-' + resMonth + '-' + resDay;
  }

  // Input: String (dd/mm/YYYY hh:mm:ss) -> Output: MySQL Date (YYYY-mm-dd hh:ii:ss)
  toMySQLdatetimeFromInputES(fecha: string) {
    let splitFecha = fecha.split('/');

    let resDay = splitFecha[0];
    let resMonth = splitFecha[1];
    let year = splitFecha[2];
    let time = year.split(' ');

    let result = time[0] + '-' + resMonth + '-' + resDay + ' ' + time[1];

    return result;
  }

  // Input: String (YYYY-mm-dd) -> Output:Date
  toMySQLdate2Date(fecha: string) {
    let splitFecha = fecha.split('-');

    let resDay = +splitFecha[2];
    let resMonth = +splitFecha[1] - 1;
    let year = +splitFecha[0];

    let result = new Date(year, resMonth, resDay);
    return result;
  }

  // Input: String (YYYY-mm-dd) -> String: dd/mm/yyyy
  toMySQLdate2ISO(fecha: string) {
    let year = fecha.substring(0, 4);
    let resMonth = fecha.substring(5, 7);
    let resDay = fecha.substring(8, 10);

    return resDay + '/' + resMonth + '/' + year;
  }

  // Input: String (YYYY-mm-dd) -> String: dd-mm-yyyy
  toMySQLdate2ISOv2(fecha: string) {
    let year = fecha.substring(0, 4);
    let resMonth = fecha.substring(5, 7);
    let resDay = fecha.substring(8, 10);

    return resDay + '-' + resMonth + '-' + year;
  }

  // Input: String (YYYY-mm-dd hh:ii:ss) -> Output:Date
  toMySQLdatetime2Date(fecha: string) {
    let splitFechaMaster = fecha.split(' ');
    let splitFecha = splitFechaMaster[0].split('-');
    let resDay = +splitFecha[2];
    let resMonth = +splitFecha[1] - 1;
    let year = +splitFecha[0];

    let secTime = splitFechaMaster[1].split(':');

    let hours = +secTime[0];
    let minutes = +secTime[1];

    let result = new Date(year, resMonth, resDay, hours, minutes);
    return result;
  }

  // Input: String (hh:ii:ss) -> Output:Date
  toMySQLtime2Date(fecha: string) {
    let secTime = fecha.split(':');

    let hours = +secTime[0];
    let minutes = +secTime[1];
    let result = new Date();
    result.setHours(hours, minutes);
    return result;
  }

  // Input: String (YYYY-mm-dd hh:ii:ss) -> Output: String (dd-mm-YYYY hh:ii:ss)
  toMySQLdatetime2String(fecha: string) {
    let splitFechaMaster = fecha.split(' ');
    let splitFecha = splitFechaMaster[0].split('-');
    let resDay = +splitFecha[2];
    let resMonth = +splitFecha[1] - 1;
    let year = +splitFecha[0];

    let secTime = splitFechaMaster[1].split(':');

    let hours = +secTime[0];
    let minutes = +secTime[1];
    let seconds = +secTime[2];

    let result = resDay + '-' + resMonth + '-' + year + ' ' + secTime;
    return result;
  }

  // Input: String (YYYY-mm-dd hh:ii:ss) -> Output: String (dd/mm/YYYY hh:ii)
  toMySQLdatetime2StringV2(fecha: string) {
    let splitFechaMaster = fecha.split(' ');
    let splitFecha = splitFechaMaster[0].split('-');
    let resDay = splitFecha[2];
    let resMonth = (+splitFecha[1]).toString();
    let year = splitFecha[0];

    let secTime = splitFechaMaster[1].split(':');

    let hours = secTime[0];
    let minutes = secTime[1];

    resDay = this.addCero(resDay);
    resMonth = this.addCero(resMonth);
    hours = this.addCero(hours);
    minutes = this.addCero(minutes);

    let result = resDay + '/' + resMonth + '/' + year + ' ' + hours + ':' + minutes;
    return result;
  }

  // Input: String (dd-mm-YYY hh:ii:ss) -> Output:  MySQL Date (YYYY-mm-dd hh:ii:ss)
  toMySQLdatetimeES2MySQL(fecha: string) {
    let splitFechaMaster = fecha.split(' ');
    let splitFecha = splitFechaMaster[0].split('-');
    let resDay = splitFecha[0];
    let resMonth = splitFecha[1];
    let year = splitFecha[2];

    let secTime = splitFechaMaster[1].split(':');

    let hours = secTime[0];
    let minutes = secTime[1];

    let result = year + '-' + resMonth + '-' + resDay + ' ' + hours + ':' + minutes;
    return result;
  }

  // Input: String (YYYY-mm-ddThh:ii:ss) -> Output:  MySQL Date (YYYY-mm-dd hh:ii:ss)
  toISOdatetime2ISO(fecha: string) {
    let splitFechaMaster = fecha.substring(0, 10);
    let secTime = fecha.substring(11, 16);

    let result = splitFechaMaster + ' ' + secTime;
    return result;
  }

  // Input: String (YYYY-mm-ddThh:ii:ss) -> Output:  Date
  toISOdate2ISODate(fecha: string) {
    let year = +fecha.substring(0, 4);
    let resMonth = +fecha.substring(5, 7) - 1;
    let resDay = +fecha.substring(8, 10);

    let result = new Date(year, resMonth, resDay);
    return result;
  }

  addCero(string) {
    let add = '0';
    if (string.length === 1) {
      string = add.concat(string);
    } else {
      string = string;
    }
    return string;
  }

  setZoomIcon() {
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
    let newClass;

    if (this.innerWidth > 1200) {
      newClass = 'fa-stack fa-5x';
    } else if (this.innerWidth <= 1200 && this.innerWidth > 900) {
      newClass = 'fa-stack fa-4x';
    } else if (this.innerWidth <= 900 && this.innerWidth > 768) {
      newClass = 'fa-stack fa-3x';
    } else if (this.innerWidth <= 768 && this.innerWidth > 640) {
      newClass = 'fa-stack fa-2x';
    } else if (this.innerWidth <= 640 && this.innerWidth > 544) {
      newClass = 'fa-stack fa-5x';
    } else if (this.innerWidth <= 544) {
      newClass = 'fa-stack fa-3x';
    }

    return newClass;
  }

  getMyStyles() {
    let myStyles = {
      width: '70%',
      float: 'left',
      'text-align': 'left',
      height: '200px',
    };
    return myStyles;
  }

  getAgGridHeight(number: number) {
    let height;
    let windowHeight = window.innerHeight;

    height = number * 27 * 1.15 + 62;

    if (height <= 165) {
      height = 165;
    } else if (height > 165 && height <= windowHeight - 200) {
      height = height;
    } else {
      height = windowHeight - 200;
    }

    return height;
  }

  startCheckAlive(data: any) {
    let interval = 1000 * 60;
    let alive;
    this.blockAlive(data).subscribe();
    console.log('Inicio el contador: ' + data.uuid);
    alive = setInterval(() => {
      this.checkAlive(data.uuid).subscribe();
      console.log('Check Alive: ' + data.uuid); // just testing if it is working
    }, interval);

    return alive;
  }

  stopCheckAlive(alive: any, uuid: any) {
    this.freeAlive(uuid).subscribe(() => {
      clearInterval(alive);
    });
    clearInterval(alive);
    console.log('Stop el contador: ' + uuid);
  }

  blockAlive(data: any) {
    let body = JSON.stringify(data);
    return this._http.post(this.apiURLAlive + '/block', body);
  }

  checkAlive(uuid: any) {
    return this._http.get(this.apiURLAlive + '/check/' + uuid);
  }

  freeAlive(uuid: any) {
    return this._http.get(this.apiURLAlive + '/free/' + uuid);
  }

  setDecimalFormatter(value: any) {
    let [integer, fraction = ''] = (value || '').split(',');
    integer = integer.replace('.', '');
    fraction = parseInt(fraction, 10) > 0 ? '.' + (fraction + '000000').substring(0, 2) : '';
    let result: number = +(integer + fraction);
    return result;
  }

  setIntegerFormatter(value: any) {
    let [integer, fraction = ''] = (value || '').split(',');
    integer = integer.replace('.', '');
    fraction = parseInt(fraction, 10) > 0 ? '.' + (fraction + '000000').substring(0, 2) : '';
    let result: number = +integer;
    return result;
  }

  formatDecimalNumber(value: number) {
    let [integer, fraction = ''] = (value || '').toString().split('.');
    fraction = ',' + (fraction + '000000').substring(0, 2);
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let result: string = integer + fraction;
    return result;
  }

  formatIntegerNumber(value: number) {
    let [integer, fraction = ''] = (value || '').toString().split('.');
    fraction = ',' + (fraction + '000000').substring(0, 2);
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let result: string = integer;
    return result;
  }

  uuid(): string {
    if (typeof window !== 'undefined' && typeof window.crypto !== 'undefined' && typeof window.crypto.getRandomValues !== 'undefined') {
      let buf: Uint16Array = new Uint16Array(8);
      window.crypto.getRandomValues(buf);
      return this.pad4(buf[0]) + this.pad4(buf[1]) + '-' + this.pad4(buf[2]) + '-' + this.pad4(buf[3]) + '-' + this.pad4(buf[4]) + '-' + this.pad4(buf[5]) + this.pad4(buf[6]) + this.pad4(buf[7]);
    } else {
      return this.random4() + this.random4() + '-' + this.random4() + '-' + this.random4() + '-' + this.random4() + '-' + this.random4() + this.random4() + this.random4();
    }
  }

  pad4(num: number): string {
    let ret: string = num.toString(16);
    while (ret.length < 4) {
      ret = '0' + ret;
    }
    return ret;
  }

  randomString(length) {
    let result = '';
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  random4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  public toDegreesMinutesAndSeconds(coordinate) {
    let absolute = Math.abs(coordinate);
    let degrees = Math.floor(absolute);
    let minutesNotTruncated = (absolute - degrees) * 60;
    let minutes = Math.floor(minutesNotTruncated);
    let seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    // tslint:disable-next-line: quotemark
    return degrees + '°' + minutes + "'" + seconds + '"';
  }

  public convertDMSLat(lat) {
    let latitude = this.toDegreesMinutesAndSeconds(lat);
    let latitudeCardinal = Math.sign(lat) >= 0 ? 'N' : 'S';

    return latitude + latitudeCardinal;
  }

  public convertDMSLng(lng) {
    let longitude = this.toDegreesMinutesAndSeconds(lng);
    let longitudeCardinal = Math.sign(lng) >= 0 ? 'E' : 'W';

    return longitude + longitudeCardinal;
  }

  findArrayByField(array: any, field: any, value: any) {
    // IN->  array: Matriz inicial, field: campo por el cual vamos a filtrar, value: valor del campo filtro => OUT: Matriz resultado
    return array.filter((x: any) => x[field] === value);
  }

  findArrayBySociedad(array: any, id: number) {
    return array.find((x: any) => x.value.sociedad === id);
  }

  findArrayByChildUUID(array: any, uuid: string) {
    return array.find((x: any) => x.value.childUUID === uuid);
  }

  findArrayByOrgVentas(array: any, orgVentas: any) {
    return array.find((x: any) => x.value.orgVentas === +orgVentas);
  }

  findArrayByOrgCompras(array: any, orgCompras: any) {
    return array.find((x: any) => x.value.sociedad === +orgCompras);
  }

  findArrayByClaseTCID(array: any, id: number) {
    return array.find((x: any) => x.claseContenedor.id === id);
  }

  findArrayByConceptoID(array: any, id: number) {
    return array.find((x: any) => x.concepto.id === id);
  }

  findArrayByID(array: any, id: number) {
    return array.find((x: any) => x.value.id === id);
  }

  findArrayByUUID(array: any, uuid: any) {
    return array.find((x: any) => x.value.id === uuid);
  }

  findIndexByField(array: any, field: any, value: any) {
    let item = array.filter((x: any) => x[field] === value);
    let index = array.findIndex((elt) => elt === item[0]);
    return index;
  }

  findIndexByClaseTCID(array: any, id: number) {
    let object = this.findArrayByClaseTCID(array, id);
    let index = array.findIndex((elt) => elt === object);
    return index;
  }

  findIndexByConceptoID(array: any, id: number) {
    let object = this.findArrayByConceptoID(array, id);
    let index = array.findIndex((elt) => elt === object);
    return index;
  }

  arrayUnique(value, index, self) {
    //var a = ['a', 1, 'a', 2, '1']; var unique = a.filter( arrayUnique ); // returns ['a', 1, 2, '1']
    return self.indexOf(value) === index;
  }

  groupByArrayOrderSD(array: any[], field: string, order: string, entidad: string, org: string): any[] {
    const groupedObj = array.reduce((prev, cur) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {});
    let resultSD;
    resultSD = Object.keys(groupedObj).map((key) => ({
      label: key,
      value: {
        [order]: key,
        posicionSAPSD:
          Math.max.apply(
            Math,
            groupedObj[key].map(function (o: any) {
              return o.posicionSAPSD;
            })
          ) + 10,
        [entidad]: groupedObj[key][0][entidad],
        [org]: groupedObj[key][0][org],
      },
    }));
    if (resultSD[0].value.posicionSAPSD < 200) {
      resultSD = Object.keys(groupedObj).map((key) => ({
        label: key,
        value: {
          [order]: key,
          posicionSAPSD:
            Math.max.apply(
              Math,
              groupedObj[key].map(function (o: any) {
                return o.posicionSAPSD;
              })
            ) + 200,
          [entidad]: groupedObj[key][0][entidad],
          [org]: groupedObj[key][0][org],
        },
      }));
    }
    return resultSD;
  }

  groupByArrayOrderMM(array: any[], field: string, order: string, entidad: string, org: string): any[] {
    const groupedObj = array.reduce((prev, cur) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {});
    let resultMM;
    resultMM = Object.keys(groupedObj).map((key) => ({
      label: key,
      value: {
        [order]: key,
        posicionSAPMM:
          Math.max.apply(
            Math,
            groupedObj[key].map(function (o: any) {
              return o.posicionSAPMM;
            })
          ) + 10,
        [entidad]: groupedObj[key][0][entidad],
        [org]: groupedObj[key][0][org],
      },
    }));
    if (resultMM[0].value.posicionSAPMM < 200) {
      resultMM = Object.keys(groupedObj).map((key) => ({
        label: key,
        value: {
          [order]: key,
          posicionSAPMM:
            Math.max.apply(
              Math,
              groupedObj[key].map(function (o: any) {
                return o.posicionSAPMM;
              })
            ) + 200,
          [entidad]: groupedObj[key][0][entidad],
          [org]: groupedObj[key][0][org],
        },
      }));
    }
    return resultMM;
  }

  groupByArray(array: any[], field: string): any[] {
    const groupedObj = array.reduce((prev, cur) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {});
    return Object.keys(groupedObj).map((key) => ({ label: key, value: { value: groupedObj[key][0][field] } }));
  }

  rendondeoArriba(n, digits) {
    let negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    let multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.ceil(n) / multiplicator).toFixed(2);
    if (negative) {
      n = (n * -1).toFixed(2);
    }
    return n;
  }

  setCurrentEtapa(etapas: any) {
    let arrayEtapaEnd = this.findArrayByField(etapas, 'current', true);
    let code = arrayEtapaEnd[arrayEtapaEnd.length - 1]['code'];
    let indexEtapaEnd = this.findIndexByField(etapas, 'code', code);
    for (let index = 0; index < indexEtapaEnd; index++) {
      etapas[index].visit = true;
      etapas[index].status = 'visited';
    }
    return etapas;
  }

  initAutorizacion() {
    let result: Autorizacion;
    result = new Autorizacion(undefined, undefined, undefined, undefined, { id: 0, nombre: 'Sin definir' }, undefined, undefined, { id: 0, nombre: '' }, undefined, undefined, 0, undefined, undefined, undefined);
    return result;
  }

  initUsuario() {
    let result: Usuario;
    result = new Usuario(this.uuid(), undefined, undefined, undefined, false, false, false, undefined, undefined, undefined, false, false, undefined, 'smtp', undefined, undefined, undefined, undefined, undefined, '/usr/sbin/sendmail -bs', false, false, false, undefined, this.initUsuarioVarianteGrid());
    return result;
  }

  initUsuarioVarianteGrid() {
    let result: UsuarioVarianteGrid;
    result = new UsuarioVarianteGrid(
      this.uuid(),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    return result;
  }

  // initEtapa() {
  //   let result: Etapa;
  //   result = new Etapa(this.uuid(), undefined, undefined, undefined, undefined, undefined, undefined, false, false, undefined);
  //   return result;
  // }

  initInfoState() {
    let result: InfoState;
    result = new InfoState('', 0, '', 0, '', 0, true);
    return result;
  }

  setInfoStateOferta(idTab, idTabOrigen, idTabDestino, pinBlock, oferta: any) {
    let infoState: InfoState;
    infoState = this.initInfoState();
    infoState.idTab = idTab;
    infoState.idTabOrigen = idTabOrigen;
    infoState.idTabDestino = idTabDestino;
    infoState.pinBlock = pinBlock;
    switch (idTab) {
      case 'general':
        infoState.tabIndex = 0;
        break;
      case 'serviciosMaritimos':
        infoState.tabIndex = 1;
        break;
      case 'serviciosOrigen':
        infoState.tabIndex = 2;
        break;
      case 'serviciosDestino':
        if (oferta.servicioOrigen) {
          infoState.tabIndex = 3;
        } else {
          infoState.tabIndex = 2;
        }
        break;
      case 'facturacion':
        if (oferta.servicioOrigen && oferta.servicioDestino) {
          infoState.tabIndex = 4;
        } else if (oferta.servicioOrigen || oferta.servicioDestino) {
          infoState.tabIndex = 3;
        } else {
          infoState.tabIndex = 2;
        }
        break;
    }

    if (oferta.servicioOrigenTerrestre) {
      switch (idTabOrigen) {
        case 'terrestre':
          infoState.tabOrigenIndex = 0;
          break;
        case 'almacen':
          infoState.tabOrigenIndex = 1;
          break;
        case 'aduanas':
          if (oferta.servicioOrigenAlmacen) {
            infoState.tabOrigenIndex = 2;
          } else {
            infoState.tabOrigenIndex = 1;
          }
          break;
      }
    } else if (oferta.servicioOrigenAlmacen) {
      switch (idTabOrigen) {
        case 'almacen':
          infoState.tabOrigenIndex = 0;
          break;
        case 'aduanas':
          infoState.tabOrigenIndex = 1;
          break;
      }
    } else if (oferta.servicioOrigenAduana) {
      infoState.tabOrigenIndex = 0;
    }

    if (oferta.servicioDestinoTerrestre) {
      switch (idTabDestino) {
        case 'terrestre':
          infoState.tabDestinoIndex = 0;
          break;
        case 'almacen':
          infoState.tabDestinoIndex = 1;
          break;
        case 'aduanas':
          if (oferta.servicioDestinoAlmacen) {
            infoState.tabDestinoIndex = 2;
          } else {
            infoState.tabDestinoIndex = 1;
          }
          break;
      }
    } else if (oferta.servicioDestinoAlmacen) {
      switch (idTabDestino) {
        case 'almacen':
          infoState.tabDestinoIndex = 0;
          break;
        case 'aduanas':
          infoState.tabDestinoIndex = 1;
          break;
      }
    } else if (oferta.servicioDestinoAduana) {
      infoState.tabDestinoIndex = 0;
    }

    return infoState;
  }

  setInfoStateMaritimo(idTab, idTabOrigen, idTabDestino, pinBlock, partida: any) {
    let infoState: InfoState;
    infoState = this.initInfoState();
    infoState.idTab = idTab;
    infoState.idTabOrigen = idTabOrigen;
    infoState.idTabDestino = idTabDestino;
    infoState.pinBlock = pinBlock;
    switch (idTab) {
      case 'general':
        infoState.tabIndex = 0;
        break;
      case 'serviciosMaritimos':
        infoState.tabIndex = 1;
        break;
      case 'serviciosOrigen':
        infoState.tabIndex = 2;
        break;
      case 'serviciosDestino':
        if (partida.servicioOrigen) {
          infoState.tabIndex = 3;
        } else {
          infoState.tabIndex = 2;
        }
        break;
      case 'historico':
        if (partida.servicioOrigen && partida.servicioDestino) {
          infoState.tabIndex = 4;
        } else if (partida.servicioOrigen || partida.servicioDestino) {
          infoState.tabIndex = 3;
        } else {
          infoState.tabIndex = 2;
        }
        break;
      case 'incidencias':
        if (partida.servicioOrigen && partida.servicioDestino) {
          infoState.tabIndex = 5;
        } else if (partida.servicioOrigen || partida.servicioDestino) {
          infoState.tabIndex = 4;
        } else {
          infoState.tabIndex = 3;
        }
        break;
      case 'facturacion':
        if (partida.servicioOrigen && partida.servicioDestino) {
          infoState.tabIndex = 6;
        } else if (partida.servicioOrigen || partida.servicioDestino) {
          infoState.tabIndex = 5;
        } else {
          infoState.tabIndex = 4;
        }
        break;
    }

    if (partida.servicioOrigenTerrestre) {
      switch (idTabOrigen) {
        case 'terrestre':
          infoState.tabOrigenIndex = 0;
          break;
        case 'almacen':
          infoState.tabOrigenIndex = 1;
          break;
        case 'aduanas':
          if (partida.servicioOrigenAlmacen) {
            infoState.tabOrigenIndex = 2;
          } else {
            infoState.tabOrigenIndex = 1;
          }
          break;
      }
    } else if (partida.servicioOrigenAlmacen) {
      switch (idTabOrigen) {
        case 'almacen':
          infoState.tabOrigenIndex = 0;
          break;
        case 'aduanas':
          infoState.tabOrigenIndex = 1;
          break;
      }
    } else if (partida.servicioOrigenAduana) {
      infoState.tabOrigenIndex = 0;
    }

    if (partida.servicioDestinoTerrestre) {
      switch (idTabDestino) {
        case 'terrestre':
          infoState.tabDestinoIndex = 0;
          break;
        case 'almacen':
          infoState.tabDestinoIndex = 1;
          break;
        case 'aduanas':
          if (partida.servicioDestinoAlmacen) {
            infoState.tabDestinoIndex = 2;
          } else {
            infoState.tabDestinoIndex = 1;
          }
          break;
      }
    } else if (partida.servicioDestinoAlmacen) {
      switch (idTabDestino) {
        case 'almacen':
          infoState.tabDestinoIndex = 0;
          break;
        case 'aduanas':
          infoState.tabDestinoIndex = 1;
          break;
      }
    } else if (partida.servicioDestinoAduana) {
      infoState.tabDestinoIndex = 0;
    }

    return infoState;
  }

  setInfoStateAduana(idTab, pinBlock, dosier: any) {
    let infoState: InfoState;
    infoState = this.initInfoState();
    infoState.idTab = idTab;
    infoState.pinBlock = pinBlock;
    switch (idTab) {
      case 'general':
        infoState.tabIndex = 0;
        break;
      case 'historico':
        infoState.tabIndex = 1;
        break;
      case 'incidencias':
        infoState.tabIndex = 2;
        break;
      case 'facturacion':
        infoState.tabIndex = 3;
        break;
    }
    return infoState;
  }

  setInfoStateAlmacen(idTab, idTabOrigen, idTabDestino, pinBlock, partida: any) {
    let infoState: InfoState;
    infoState = this.initInfoState();
    infoState.idTab = idTab;
    infoState.idTabOrigen = idTabOrigen;
    infoState.idTabDestino = idTabDestino;
    infoState.pinBlock = pinBlock;
    switch (idTab) {
      case 'general':
        infoState.tabIndex = 0;
        break;
      case 'serviciosOrigen':
        infoState.tabIndex = 1;
        break;
      case 'serviciosDestino':
        if (partida.servicioOrigen) {
          infoState.tabIndex = 2;
        } else {
          infoState.tabIndex = 1;
        }
        break;
      case 'historico':
        if (partida.servicioOrigen && partida.servicioDestino) {
          infoState.tabIndex = 3;
        } else if (partida.servicioOrigen || partida.servicioDestino) {
          infoState.tabIndex = 2;
        } else {
          infoState.tabIndex = 1;
        }
        break;
      case 'incidencias':
        if (partida.servicioOrigen && partida.servicioDestino) {
          infoState.tabIndex = 4;
        } else if (partida.servicioOrigen || partida.servicioDestino) {
          infoState.tabIndex = 3;
        } else {
          infoState.tabIndex = 2;
        }
        break;
      case 'facturacion':
        if (partida.servicioOrigen && partida.servicioDestino) {
          infoState.tabIndex = 5;
        } else if (partida.servicioOrigen || partida.servicioDestino) {
          infoState.tabIndex = 4;
        } else {
          infoState.tabIndex = 3;
        }
        break;
    }

    if (partida.servicioOrigenTerrestre) {
      switch (idTabOrigen) {
        case 'terrestre':
          infoState.tabOrigenIndex = 0;
          break;
        case 'aduanas':
          infoState.tabOrigenIndex = 1;
          break;
      }
    } else {
      switch (idTabOrigen) {
        case 'aduanas':
          infoState.tabOrigenIndex = 0;
      }
    }

    if (partida.servicioDestinoTerrestre) {
      switch (idTabDestino) {
        case 'terrestre':
          infoState.tabOrigenIndex = 0;
          break;
        case 'aduanas':
          infoState.tabOrigenIndex = 1;
          break;
      }
    } else {
      switch (idTabDestino) {
        case 'aduanas':
          infoState.tabOrigenIndex = 0;
      }
    }
    return infoState;
  }

  setInfoStateTerrestre(idTab, pinBlock, orden: any) {
    let infoState: InfoState;
    infoState = this.initInfoState();
    infoState.idTab = idTab;
    infoState.pinBlock = pinBlock;
    switch (idTab) {
      case 'trafico':
        infoState.tabIndex = 0;
        break;
      case 'transporte':
        infoState.tabIndex = 1;
        break;
      case 'incidencias':
        infoState.tabIndex = 2;
        break;
      case 'historico':
        infoState.tabIndex = 3;
        break;
    }
    return infoState;
  }

  getAhora() {
    let fecha = new Date();
    let year = fecha.getFullYear();
    let numMonth = fecha.getMonth() + 1;
    let month = numMonth.toString();
    let day = fecha.getDate().toString();
    let hours = fecha.getHours().toString();
    let minutes = fecha.getMinutes().toString();

    day = this.addCero(day);
    month = this.addCero(month);
    hours = this.addCero(hours);
    minutes = this.addCero(minutes);

    return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
  }

  getMonday(d) {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() - d.getDay() + 1);
  }

  getSunday(d) {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() - d.getDay() + 7);
  }

  getWeekNumMysqlDate(d: string) {
    let date: any;
    date = this.toMySQLdatetime2Date(d);
    let weekNo = this.getWeekNumber(date);
    return weekNo;
  }

  getWeekNumber(d: any) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    let weekNo = Math.ceil(((d - +yearStart) / 86400000 + 1) / 7);
    return weekNo;
  }

  checkExportarMM(empresa, proveedor) {
    let result = true;

    if ((empresa === 1100 || empresa === 1101) && proveedor === 1160) {
      result = false;
    }
    if ((empresa === 1500 || empresa === 1501) && proveedor === 16620) {
      result = false;
    }
    if ((empresa === 1600 || empresa === 1601) && proveedor === 14529) {
      result = false;
    }
    if (empresa === 1700 && proveedor === 22178) {
      result = false;
    }

    return result;
  }

  sacaDiaSemana(dia) {
    let result: number;
    switch (dia) {
      case 'Lunes':
        result = 1;
        break;
      case 'Martes':
        result = 2;
        break;
      case 'Miercoles':
        result = 3;
        break;
      case 'Jueves':
        result = 4;
        break;
      case 'Viernes':
        result = 5;
        break;
      case 'Sabado':
        result = 6;
        break;
      case 'Domingo':
        result = 7;
        break;
    }
    return result;
  }

  filterByProperty(array, prop, value) {
    let filtered = [];
    for (let i = 0; i < array.length; i++) {
      let obj = array[i];
      if (obj['HASCHILD'] === 'Y') {
        let objP = obj['P_CHILDMENUS'];
        for (let keyP in objP) {
          if (objP[keyP]['HASCHILD'] === 'Y') {
            let obj3 = objP[keyP]['P_CHILDMENUS'];
            for (let key3 in obj3) {
              if (typeof (obj3[key3] === 'object')) {
                let item = obj3[key3];
                if (item[prop] === value) {
                  filtered.push(item);
                }
              }
            }
          } else if (typeof (objP[keyP] === 'object')) {
            let item = objP[keyP];
            if (item[prop] === value) {
              filtered.push(item);
            }
          }
        }
      } else {
        for (let key in obj) {
          if (obj[key]['HASCHILD'] === 'Y') {
            let obj2 = obj[key]['P_CHILDMENUS'];
            for (let key2 in obj2) {
              if (typeof (obj2[key2] === 'object')) {
                let item = obj2[key2];
                if (item[prop] === value) {
                  filtered.push(item);
                }
              }
            }
          } else if (typeof (obj[key] === 'object')) {
            let item = obj[key];
            if (item[prop] === value) {
              filtered.push(item);
            }
          }
        }
      }
    }
    return filtered;
  }


  /* Proyecto Taller PROGECO*/

  initConceptoFacturacion() {
    let result: ConceptoFacturacion;
    result = new ConceptoFacturacion(this.uuid(), undefined, undefined, undefined, undefined, undefined, undefined, false, 0, { id: 0, nombre: '' }, 1, 0, 0, false, 0, undefined, undefined, false, undefined, undefined, undefined, undefined, false, 0, { id: 0, nombre: '' }, 1, 0, 0, false, 0, undefined, undefined, false, undefined, undefined, 0, false, undefined);
    return result;
  }

  initParteTaller() {
    let result: ParteTaller;
    result = new ParteTaller(this.uuid(), undefined, undefined, undefined, { id: 0, nombre: 'Sin asignar estado' }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, false, 0, false, 0, false, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [], [], [], [], []);
    return result;
  }

  initPresupuesto() {
    let result: Presupuesto;
    result = new Presupuesto(this.uuid(), undefined, undefined, undefined,  undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, []);
    return result;
  }

  initTrabajoTaller() {
    let result: TrabajoTaller;
    result = new TrabajoTaller(this.uuid(), undefined, undefined, undefined, false, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [], undefined, undefined, undefined);
    return result;
  }

  initMaterialTaller() {
    let result: MaterialTrabajo;
    result = new MaterialTrabajo(this.uuid(), undefined, undefined, undefined, undefined);
    return result;
  }
  initIncidenciaTaller() {
    let result: IncidenciaTaller;
    result = new IncidenciaTaller(this.uuid(), undefined, undefined, { id: 1, nombre: 'Abierta' }, 2, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    return result;
  }

  initClaseContenedor() {
    let result: ClaseContenedor;
    result = new ClaseContenedor(this.uuid(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [], [], undefined);
    return result;
  }

  initTipoContenedor() {
    let result: TipoContenedor;
    result = new TipoContenedor(this.uuid(), undefined, undefined, undefined, undefined, undefined, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, false, false, false, false, false, undefined);
    return result;
  }

  initContenedor() {
    let result: Contenedor;
    result = new Contenedor(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    return result;
  }

  initLugar() {
    let result: Lugar;
    result = new Lugar(this.uuid(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, false, false, undefined, false, undefined, false, false, undefined, false, undefined, false, false, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, []);
    return result;
  }

  initMovSACE() {
    let result: MovSACE;
    result = new MovSACE(undefined, undefined, undefined, false, undefined, undefined, undefined, undefined, undefined, undefined);
    return result;
  }

  initEntidadContacto() {
    let result: ContactoEntidad;
    result = new ContactoEntidad(this.uuid(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [], undefined);
    return result;
  }

  initEntidad() {
    let result: Entidad;
    result = new Entidad(
      this.uuid(),
      undefined,
      undefined,
      undefined,
      { id: 0, nombre: 'Sin asignar estado' },
      undefined,
      false,
      { id: 2, Abreviatura: 'S.L.', nombre: 'Sociedad Limitada' },
      undefined,
      undefined,
      { id: 0, nombre: '' },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      false,
      false,
      false,
      undefined,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      [],
      [],
      undefined,
      [],
      [],
      [],
      [],
      false,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      false,
      undefined,
      undefined,
      false,
      false,
      undefined,
      undefined,
      false,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      false,
      false,
      undefined,
      undefined,
      false,
      undefined,
      false,
      undefined,
      undefined,
      false,
      false,
      []
    );
    return result;
  }

  initInfoFacturacionEntidad() {
    let result: InfoFacturacionEntidad;
    result = new InfoFacturacionEntidad(undefined, undefined, true, { id: 5001, nombre: 'Flete marítimo (Seafreight)' }, [], [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    return result;
  }


}
