import { StateGrid } from '@usuarios/model/columnsGrid';

export class Usuario {
  constructor(
    public id: string,
    public nombre: string,
    public email: string,
    public telefono: string,
    public sadmin: boolean,
    public autorizado: boolean,
    public multiEmpresa: boolean,
    public empresa: any,
    public orgVentas: any,
    public delegacion: any,
    public UPCTrafico: boolean,
    // public agAduanas: boolean,
    // public comercial: boolean,
    // public grupoSAP: boolean,
    public reclamaPagos: boolean,
    // public gestorTerrestre: boolean,
    // public operarioAlmacen: boolean,
    // public operarioTaller: boolean,
    // public operarioMaritimo: boolean,
    public imagen: string,
    public mailDriver: string,
    public mailHost: string,
    public mailPort: number,
    public mailUsername: string,
    public mailPassword: string,
    public mailEncryption: string,
    public mailSendmail: string,
    public mailPretend: boolean,
    public controlVersion: boolean,
    public pinBlock: boolean,
    public permisos: UsuarioPermiso,
    public variantesGrid: UsuarioVarianteGrid
  ) {}
}

export class UsuarioPermiso {
  constructor(
    public uuid: string,
    public sgAduanasExportacion: number,
    public sgAduanasImportacion: number,
    public sgAduanasTarifas: number,
    public sgAduanasFacturacion: number,
    public sgControlADT: number,
    public sgAlmacenOrdenes: number,
    public sgAlmacenPartidas: number,
    public sgAlmacenPlanificacion: number,
    public sgStockLogistica: number,
    public sgAlmacenTarifas: number,
    public sgAlmacenFacturacion: number,
    public sgComercialOfertas: number,
    public sgComercialGrupaje: number,
    public sgComercialGestiones: number,
    public sgComerciaTarifasVentas: number,
    public sgComerciaTarifasProveedor: number,
    public sgComerciaTarifasGenerales: number,
    public sgCaja: number,
    public sgDocumentos: number,
    public sgIncidencias: number,
    public sgNeodoc: number,
    public sgInventarios: number,
    public sgSeguros: number,
    public sgCalidad: number,
    public sgSII: number,
    public sgMaestrosBuques: number,
    public sgMaestrosContenedores: number,
    public sgMaestrosEntidades: number,
    public sgMaestrosLugares: number,
    public sgMaritimoCompletos: number,
    public sgMaritimoGrupajes: number,
    public sgMaritimoAverias: number,
    public sgMaritimoControlEquipo: number,
    public sgMaritimoFacturacionFCL: number,
    public sgMaritimoFacturacionLCL: number,
    public sgTerrestreOrdenes: number,
    public sgTerrestreRecogidas: number,
    public sgTerrestreEntregas: number,
    public sgTerrestreTraslados: number,
    public sgTerrestreTarifas: number,
    public sgTerrestreFacturacion: number,
    public sgTallerPartes: number,
    public sgTallerPresupuestos: number,
    public sgTallerPlanificacion: number,
    public sgTallerFacturacion: number,
    public sgOperacionesRTrip: number,
    public sgOperacionesEscalas: number,
    public sgOperacionesControlEscalas: number,
    public sgOperacionesInformes: number,
    public sgOperacionesPlanificacion: number,
    public sgOperacionesTarifas: number,
    public sgOperacionesFacturacion: number,
    public sgMaestrosUsuarios: number
  ) {}
}

export class UsuarioVarianteGrid {
  constructor(
    public uuid: string,
    public varDosierExport: string,
    public varDosierImport: string,
    public varDosierExportFacturacion: string,
    public varDosierImportFacturacion: string,
    public varAduanasTarifaVentas: string,
    public varAduanasTarifaProveedor: string,
    public varAduanasControlADT: string,
    public varOrdenesAlmacen: string,
    public varPartidasAlmacen: string,
    public varPlanificacionAlmacen: string,
    public varStockMercanciaLogistica: string,
    public varTarifaAlmacen: string,
    public varAlmacenFacturacion: string,
    public varOfertasComercial: string,
    public varOfertasGrupaje: string,
    public varGestionesComercial: string,
    public varComercialTarifaGeneral: string,
    public varComercialTarifaVentas: string,
    public varComercialTarifaProveedores: string,
    public varApuntesCaja: string,
    public varDocumentos: string,
    public varIncidencias: string,
    public varNeodoc: string,
    public varInventarios: string,
    public varSeguros,
    public varCalidad: string,
    public varSII: string,
    public varBuques: string,
    public varContenedores: string,
    public varClasesContenedor: string,
    public varTiposContenedor: string,
    public varEntidades: string,
    public varLugares: string,
    public varCompletos: string,
    public varGrupajes: string,
    public varAverias: string,
    public varControlEquipo: string,
    public varCompletosFacturacion: string,
    public varGrupajesFacturacion: string,
    public varOrdenesTerrestre: string,
    public varRecogidas: string,
    public varEntregas: string,
    public varTarifaDirecto: string,
    public varTarifaConvencional: string,
    public varTarifaGrupaje: string,
    public varTarifaPaqueteria: string,
    public varTarifaRecargos: string,
    public varPartesTaller: string,
    public varPresupuestosTaller: string,
    public varPlanificacionTaller: string,
    public varTallerFacturacion: string,
    public varRoundTrips: string,
    public varEscalas: string,
    public varControlEscalas: string,
    public varInformeOperaciones: string,
    public varPlanificacionOperaciones: string,
    public varTarifaTasasPuerto: string,
    public varTarifaTasasT3: string,
    public varTarifaTerminal: string,
    public varOperacionesFacturacion: string,
    public varAutorizaciones: string,
    public varUsuarios: string
  ) {}
}

export class NuevoUsuario {
  constructor(public nombre: string, public email: string, public password: string, public password_confirmation: string, public delegacion: any, public empresa: any, public imagen: string) {}
}

export class SearchUsuario {
  constructor(public nombre: string, public email: string, public autorizado: number, public m_delegacion_id: number, public m_empresa_id: number, public filter: any, public numFilterRows: number, public gridState: StateGrid) {}
}

export class Favorito {
  constructor(public nombre: string, public icon: string, public tipo: string, public codigo: string) {}
}

export class Autorizacion {
  constructor(public uuid: string, public parentUUID: string, public usuario: any, public responsable: any, public modulo: any, public documento: number, public tipoDocumento: string, public tipoSolicitud: any, public fechaSolicitud: any, public solicitud: any, public situacion: number, public fechaRespuesta: any, public motivoRechazo: string, public clave: any) {}
}

export class SearchAutorizacion {
  constructor(public usuario: any, public responsable: any, public modulo: number, public tipoSolicitud: any, public fechaSolicitud: any, public solicitud: any, public situacion: number, public filter: any, public numFilterRows: any, public gridState: StateGrid) {}
}

export class SearchControlHorario {
  constructor(public usuario: any, public fechaDesde: any, public fechaHasta: any) {}
}
