import { Component, OnInit, Optional, Inject, InjectionToken, OnDestroy } from '@angular/core';

import { trigger, state, style, transition, animate } from '@angular/animations';

import { NotificationBarService } from '@comun/UI/angular2-notification-bar/src/notification-bar.service';
import { MessagesConfig, Notification, NotificationType } from '@comun/UI/angular2-notification-bar/src/message-config';
import { Subscription } from 'rxjs-compat/Subscription';
export const MESSAGES_CONFIG = new InjectionToken('notification-bar.messages.config');

@Component({
  selector: 'notification-bar',
  styles: [
    `
      :host {
        position: relative;
        display: block;
      }

      .notifications-container {
        position: fixed;
        top: 0px;
        right: 0;
        left: 0;

        line-height: 40px;
        width: 100%;
        z-index: 999999;
        overflow: hidden;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      .notification {
        position: relative;
        text-align: center;
        font-size: 12px;
        color: #fff;
        -moz-border-radius: (3px 3px 3px 3px);
        -webkit-border-radius: (3px 3px 3px 3px);
        border-radius: (3px 3px 3px 3px);
        background-position: 15px center;
        background-repeat: no-repeat;
        -moz-box-shadow: (0 0 12px #999999);
        -webkit-box-shadow: (0 0 12px #999999);
        box-shadow: (0 0 12px #999999);
        opacity: 1;
      }

      .message {
        padding: 0 12px;
      }

      .error {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=') !important;
        background-color: #f44336;
        border-bottom: 1px solid #f31515;
      }

      .success {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==') !important;
        background-color: #4caf50;
        border-bottom: 1px solid #02aa8f;
      }

      .warning {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=') !important;
        background-color: #ff8b00;
        border-bottom: 1px solid #e7ba08;
      }

      .info {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=') !important;
        background-color: #2196f3;
        border-bottom: 1px solid #0c6997;
      }

      .close-click {
        position: relative;
        right: 20px;
        top: 0;
        float: right;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        -webkit-text-shadow: 0 1px 0 rgba(255, 255, 255, 1);
        text-shadow: 0 1px 0 rgba(255, 255, 255, 1);
        opacity: 1;
      }

      .close-click:hover {
        -moz-box-shadow: (0 0 12px #000000);
        -webkit-box-shadow: (0 0 12px #000000);
        box-shadow: (0 0 12px #000000);
        opacity: 1;
        cursor: pointer;
      }
    `,
  ],
  template: `
    <div class="notifications-container">
      <div *ngFor="let notification of notifications; let i = index" class="notification {{ notification.typeValue }}" [@flyDown]>
        <span *ngIf="notification.isHtml" class="message" [innerHTML]="notification.message"></span>
        <span *ngIf="!notification.isHtml" class="message">{{ notification.message }}</span>
        <span class="close-click" *ngIf="notification.allowClose" (click)="hideNotification(notification)">×</span>
      </div>
    </div>
  `,
  animations: [
    trigger('flyDown', [
      state(
        'in',
        style({
          opacity: 1,
          transform: 'translateY(0)',
        })
      ),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateY(-100%)',
        }),
        animate('.2s ease-in-out'),
      ]),
      transition('* => void', [
        animate(
          '.2s ease-in-out',
          style({
            opacity: 0,
            transform: 'translateY(-100%)',
          })
        ),
      ]),
    ]),
  ],
})
export class NotificationBarComponent implements OnInit, OnDestroy {
  notifications: Notification[] = [];

  defaults = {
    message: '',
    type: NotificationType.Info,
    autoHide: true,
    hideDelay: 3000,
    isHtml: false,
    allowClose: false,
    hideOnHover: true,
  };

  subscription: Subscription;

  constructor(private notificationBarService: NotificationBarService, @Inject(MESSAGES_CONFIG) @Optional() private config?: MessagesConfig) {
    this.subscription = this.notificationBarService.onCreate.subscribe(this.addNotification.bind(this));
  }

  ngOnInit() {}

  addNotification(notification: Notification) {
    let newNotification = Object.assign({}, this.defaults, notification);
    newNotification.typeValue = NotificationType[newNotification.type].toLowerCase();
    if (this.config && this.config.messages) {
      newNotification.message = this.config.messages[notification.message] || notification.message;
    }

    this.notifications.push(newNotification);

    if (newNotification.autoHide) {
      window.setTimeout(() => {
        this.hideNotification(newNotification);
      }, newNotification.hideDelay);
    }
  }

  hideNotification(notification: Notification) {
    let index = this.notifications.indexOf(notification);

    this.notifications.splice(index, 1);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  hideOnHover(notification: Notification) {
    if (notification.hideOnHover) {
      this.hideNotification(notification);
    }
  }
}
