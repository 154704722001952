import { StateGrid } from '@usuarios/model/columnsGrid';

export class MovSACE {
  constructor(public id: any, public contenedor: string, public evento: any, public lleno: boolean, public situacion: any, public localizacion: any, public observaciones: any, public tipoAveria: any, public estadoContenedor: any, public fecha: any) {}
}

export class SearchSACE {
  constructor(
    public situacion: any,
    public movimiento: any,
    public localizacion: any[],
    public zona: any[],
    public contenedor: any,
    public categoriaContenedor: any,
    public claseContenedor: any[],
    public tipoContenedor: any[],
    public lleno: any,
    public propio: any,
    public averia: any,
    public estado: any,
    public ultimoMov: boolean,
    public todosVisibles: boolean,
    public fechaDesde: any,
    public fechaHasta: any,
    public resPerPage: any,
    public page: number,
    public filter: any,
    public numFilterRows: number,
    public gridState: StateGrid
  ) {}
}

export class SearchOCS {
  constructor(public cliente: any, public receptor: any, public localizacion: any[], public zona: any[], public contenedor: any, public categoriaContenedor: any, public claseContenedor: any[], public tipoContenedor: any[], public lleno: any, public propio: any, public estado: any, public resPerPage: any, public page: number, public filter: any, public numFilterRows: number) {}
}

export class SearchReefer {
  constructor(public cliente: any, public receptor: any, public localizacion: any[], public contenedor: any, public fechaSalidaDesde: any, public fechaSalidaHasta: any, public fechaLlegadaDesde: any, public fechaLlegadaHasta: any, public resPerPage: any, public page: number, public filter: any, public numFilterRows: number) {}
}

export class Averia {
  constructor(
    public uuid: string,
    public empresa: any,
    public idAveria: number,
    public expSeguroUUID: string,
    public tipoAveria: any,
    public estadoAveria: any,
    public fechaAveria: any,
    public contenedor: any,
    public bloqueaContenedor: boolean,
    public responsable: any,
    public localizacion: any,
    public taller: any,
    public solicitaPptoTaller: boolean,
    public pptoTallerNum: string,
    public pptoTallerFecha: any,
    public pptoTallerImporte: any,
    public pptoTallerAprobado: boolean,
    public reparado: boolean,
    public parteID: string,
    public fechaReparacion: any,
    public importeReparacion: any,
    public tieneFacturaReparacion: boolean,
    public facturaReparacion: string,
    public fechaFacturaReparacion: any,
    public observaciones: string
  ) {}
}

export class SearchAveria {
  constructor(
    public idAveria: number,
    public empresa: any,
    public contenedor: any,
    public tipoContenedor: any,
    public claseContenedor: any,
    public localizacion: any,
    public fechaAveriaDesde: any,
    public fechaAveriaHasta: any,
    public fechaReparacionDesde: any,
    public fechaReparacionHasta: any,
    public reparado: any,
    public tipoAveria: any,
    public estadoAveria: any,
    public page: number,
    public filter: any,
    public numFilterRows: number,
    public gridState: StateGrid
  ) {}
}
