<notification-bar></notification-bar>
<section class="container">
    <section class="login-form">
        <div class="login-msg"></div>
        <form [formGroup]="formNuevoUsuario" role="login" novalidate>

            <img src="./assets/images/logoJSV.png" id="logo" class="img-responsive img-logo" alt="" />
            <p>
                <input pInputText formControlName="nombre" class="form-control input-lg" [(ngModel)]="usuario.nombre" name="nombre" placeholder="Nombre y Apellidos"
                    type="text">
                <span *ngIf="formNuevoUsuario.controls['nombre'].errors && (formNuevoUsuario.controls['nombre'].dirty || formNuevoUsuario.controls['nombre'].touched)"
                    class="DisplayBlock alert alert-danger">
                    <img class="img-error-min" src="./assets/images/icons/stop.png">El nombre y apellidos es obligatorio</span>
            </p>
            <p>
                <input pInputText formControlName="email" class="form-control input-lg" [(ngModel)]="usuario.email" name="email" placeholder="Correo electr&oacute;nico"
                    type="text">
                <span *ngIf="formNuevoUsuario.controls['email'].errors && (formNuevoUsuario.controls['email'].dirty || formNuevoUsuario.controls['email'].touched)"
                    class="DisplayBlock alert alert-danger">
                    <img class="img-error-min" src="./assets/images/icons/stop.png">El correo electr&oacute;nico es obligatorio con formato
                    <i>usuario@dominio.com</i>
                </span>
            </p>
            <p>
                <input pInputText formControlName="password" class="form-control input-lg" [(ngModel)]="usuario.password" name="password"
                    placeholder="Contrase&ntilde;a" type="password">
                <span *ngIf="formNuevoUsuario.controls['password'].errors && (formNuevoUsuario.controls['password'].dirty || formNuevoUsuario.controls['password'].touched)"
                    class="DisplayBlock alert alert-danger">
                    <img class="img-error-min" src="./assets/images/icons/stop.png">La contrase&ntilde;a es obligatorio (min 6 caracteres)
                </span>
            </p>
            <p>
                <input pInputText formControlName="passwordConfirm" class="form-control input-lg" [(ngModel)]="usuario.password_confirmation" name="passwordConfirm"
                    placeholder="Confirma Contrase&ntilde;a" type="password" validateEqual="password">
                <span *ngIf="formNuevoUsuario.controls['passwordConfirm'].errors && (formNuevoUsuario.controls['passwordConfirm'].dirty || formNuevoUsuario.controls['passwordConfirm'].touched)"
                    class="DisplayBlock alert alert-danger">
                    <img class="img-error-min" src="./assets/images/icons/stop.png">La contrase&ntilde;a no coincide
                </span>
            </p>
            <p>
                <p-dropdown [options]="delegaciones" [(ngModel)]="usuario.delegacion" styleClass="form-control input-lg"
                    formControlName="delegacion" [style]="{'width': '350px','display': 'flex','font-size': '18px','margin': '0','line-height': '1.25','padding-left': '0px','padding-top': '6px','height': '46px'}">
                </p-dropdown>
                <span *ngIf="formNuevoUsuario.controls['delegacion'].errors && (formNuevoUsuario.controls['delegacion'].dirty || formNuevoUsuario.controls['delegacion'].touched)"
                    class="DisplayBlock alert alert-danger">
                    <img class="img-error-min" src="./assets/images/icons/stop.png">La delegaci&oacute;n es obligatorio
                </span>
            </p>
            <p>
                <p-dropdown [options]="empresas" [(ngModel)]="usuario.empresa" styleClass="form-control input-lg"
                    formControlName="empresa" [style]="{'width': '350px','display': 'flex','font-size': '18px','margin': '0','line-height': '1.25','padding-left': '0px','padding-top': '6px','height': '46px'}">
                </p-dropdown>
                <span *ngIf="formNuevoUsuario.controls['empresa'].errors && (formNuevoUsuario.controls['empresa'].dirty || formNuevoUsuario.controls['empresa'].touched)"
                    class="DisplayBlock alert alert-danger">
                    <img class="img-error-min" src="./assets/images/icons/stop.png">La empresa es obligatorio
                </span>
            </p>

            <button class="btn btn-lg btn-primary btn-block" (click)="onSubmit()" [disabled]="formNuevoUsuario.invalid">
                <i class="fa fa-user fa fa-fw"></i>Registrar</button>
            <div>
                <div style="float: right; width: 120px; text-align: right;">
                    <a href="" [routerLink]="['/login']">Volver</a>
                </div>
            </div>
        </form>
    </section>
</section>
<p-confirmDialog header="Confirmacion registro" icon="fa fa-info-circle" width="425" #cd>
    <footer>
        <button type="button" pButton icon="fa fa-check" label="Aceptar" (click)="cd.accept()"></button>
    </footer>
</p-confirmDialog>
