import { StateGrid } from '@usuarios/model/columnsGrid';
import { ConceptoFacturacion, Etapa } from '@maestros/model/maestros.model';

export class TrabajoTaller {
  constructor(
    public uuid: any,
    public idTrabajoTaller: number,
    public parentUUID: any,
    public codigo: any,
    public manual: boolean,
    public descripcionTrabajo: any,
    public uds: any,
    public zona: any,
    public seccion: any,
    public averia: any,
    public reparacion: any,
    public dimension: any,
    public operario: any,
    public fechaInicio: any,
    public fechaFinal: any,
    public pausado: boolean,
    public tiempoPrevisto: any,
    public tiempoReal: any,
    public materialesTrabajo: MaterialTrabajo[],
    public tipoParte: any,
    public ayuda: any,
    public observaciones: string
  ) {}
}

export class MaterialTrabajo {
  constructor(public uuid: string, public parentUUID: string, public codigoMaterial: any, public familiaMaterial: any, public cantidadMaterial: any) {}
}

export class Presupuesto {
  constructor(
    public uuid: any,
    public idPresupuesto: number,
    public empresa: any,
    public tipoParte: any,
    public estadoPresupuesto: any,
    public cliente: any,
    public referencia: any,
    public refCliente: any,
    public refEnvio: any,
    public sender: any,
    public receive: any,
    public taller: any,
    public destacado: number,
    public observaciones: string,
    public usuario: any,
    public trabajosPresupuesto: TrabajoTaller[]
  ) {}
}
export class SearchPresupuesto {
  constructor(public idPresupuesto: number, public tipoParte: any, public estadoPresupuesto: any, public empresa: any, public cliente: any, public referencia: any, public taller: any, public usuario: any, public resPerPage: any, public page: number, public filter, public numFilterRows: number, public gridState: StateGrid) {}
}

export class ParteTaller {
  constructor(
    public uuid: any,
    public idParteTaller: number,
    public empresa: any,
    public tipoParte: any,
    public estadoParte: any,
    public cliente: any,
    public taller: any,
    public prioridad: any,
    public dificultad: any,
    public destacado: number,
    public referencia: any,
    public proyecto: any,
    public zona: any,
    public presupuesto: any,
    public contenedor: any,
    public finalizado: boolean,
    public exportadoSAPSD: number,
    public facturadoSAPSD: boolean,
    public exportadoSAPMM: number,
    public facturadoSAPMM: boolean,
    public observaciones: string,
    public fechaParte: any,
    public fechaEntrada: any,
    public fechaSalida: any,
    public fechaFinalizado: any,
    public usuario: any,
    public operario: any,
    public trabajosTaller: TrabajoTaller[],
    public incidencias: IncidenciaTaller[],
    public historico: HistoricoTaller[],
    public conceptosFacturacion: ConceptoFacturacion[],
    public etapasTaller: Etapa[]
  ) {}
}

export class SearchPartesTaller {
  constructor(
    public idParteTaller: number,
    public tipoParte: any,
    public estadoParte: any,
    public empresa: any,
    public cliente: any,
    public taller: any,
    public presupuesto: string,
    public contenedor: any,
    public proyecto: any,
    public referencia: any,
    public usuario: any,
    public finalizado: boolean,
    public fechaParteIni: any,
    public fechaParteFin: any,
    public fechaFinalizadoIni: any,
    public fechaFinalizadoFin: any,
    public resPerPage: any,
    public page: number,
    public filter,
    public numFilterRows: number,
    public gridState: StateGrid
  ) {}
}

export class IncidenciaTaller {
  constructor(
    public uuid: string,
    public parentUUID: string,
    public parentID: number,
    public estado: any,
    public nivel: any,
    public tipo: any,
    public clase: any,
    public liqComp: any,
    public sancion: any,
    public usuario: any,
    public usuarioInformado: any,
    public proveedor: any,
    public fechaAlta: any,
    public fechaCierre: any,
    public fechaMaxCierre: any,
    public descripcion: string,
    public tratamiento: string
  ) {}
}

export class Trabajo {
  constructor(public codigo: any, public descripcion: any, public tipoParte: any) {}
}

export class HistoricoTaller {
  constructor(public uuid: string, public parentUUID: string, public usuario: any, public evento: any, public observaciones: string, public visible: boolean, public web: boolean, public fecha: any) {}
}
