import { StateGrid } from '@usuarios/model/columnsGrid';

export class Entidad {
  constructor(
    public uuid: string,
    public businessPartner: number,
    public businessPartnerName: string,
    public comercialName: string,
    public estado: any,
    public taxNumber: string,
    public extranjero: boolean,
    public legalForm: any,
    public industry: any,
    public streetName: string,
    public postalCode: any,
    public region: any,
    public cityName: any,
    public country: any,
    public phoneNumberCountry: any,
    public phoneNumber: string,
    public mobilePhoneCountry: any,
    public mobilePhoneNumber: string,
    public faxCountry: any,
    public faxNumber: string,
    public emailAddress: string,
    public websiteURL: string,
    public isNaturalPerson: boolean,
    public isGroup: boolean,
    public preCliente: boolean,
    public cliente: boolean,
    public tipoCliente: any,
    public proveedor: boolean,
    public acreedor: boolean,
    public transportista: boolean,
    public operadorMaritimo: boolean,
    public agAduanas: boolean,
    public consignatario: boolean,
    public cargador: boolean,
    public receptor: boolean,
    public terminal: boolean,
    public taller: boolean,
    public almacen: boolean,
    public consignaMercancia: boolean,
    public gestores: GestoresEntidad[],
    public infoFacturacion: InfoFacturacionEntidad[],
    public infoFinanciera: InfoFinancieraEntidad,
    public tarifasComercial: TarifaComercial[],
    public ocupacionSuperficie: OcSuperficieEntidad[],
    public entidadesAsoc: EntidadAsoc[],
    public docsCalidad: DocsCalidad[],
    public IVA: boolean,
    public IGIC: boolean,
    public flujoReclamacion: any,
    public limiteRiesgo: any,
    public coberturaSeguro: any,
    public fechaCoberturaSeguro: any,
    public notasAdm: string,
    public notasComercial: string,
    public EORI: string,
    public numIVA: boolean,
    public CIFAsociado: boolean,
    public OEA: string,
    public fechaOEA: any,
    public particularEORI: boolean,
    public autDespGlobal: boolean,
    public codAutorizacion: string,
    public cadAutGlobal: any,
    public autDespCanarias: boolean,
    public cadAutCanarias: any,
    public autDespPeninsula: boolean,
    public cadAutPeninsula: any,
    public TRImport: any,
    public MPImport: any,
    public TRExport: any,
    public IVADiferido: boolean,
    public garantiaPeninsula: string,
    public recargoEquiv: boolean,
    public garantiaCan1: string,
    public garantiaCan2: string,
    public garantiaCan3: string,
    public pagoAduanaCliente: boolean,
    public pagoGCCliente: boolean,
    public recargoMinorista: boolean,
    public medioPagoImpuestos: any,
    public recargoFinanciacionImpuestos: boolean,
    public valorRecargoFinanciacion: any,
    public recargoContrareembolso: boolean,
    public obsTaric: string,
    public notasAduanas: string,
    public validoSAP: boolean,
    public exportadoSAP: boolean,
    public contactos: ContactoEntidad[]
  ) {}
}

export class ContactoEntidad {
  constructor(public uuid: string, public contactType: boolean, public formOfAddress: string, public firstName: string, public lastName: string, public contactPersonFunction: any, public phoneNumberCountry: any, public phoneNumber: string, public mobilePhoneCountry: any, public mobilePhoneNumber: string, public faxCountry: string, public faxNumber: string, public emailAddress: string, public observaciones: string, public notificaciones: any[], public flag: string) {}
}

export class GestoresEntidad {
  constructor(public uuid: string, public empresa: any, public comercial: any, public UPCTrafico: any, public grupoSAP: any, public reclamaPagos: any, public UPCAduanas: any, public UPCTerrestre: any) {}
}

export class TarifaComercial {
  constructor(public uuid: string, public empresa: any, public tarifa: any, public fechaValidez: any, public dto: any, public observaciones: any) {}
}

export class OcSuperficieEntidad {
  constructor(public uuid: string, public categoriaTC: any, public puerto: any, public diasLibres: any, public observaciones: any) {}
}

export class InfoFacturacionEntidad {
  constructor(public uuid: string, public empresa: any, public agrupaFacturacion: boolean, public conceptoAgrupa: any, public conceptosExcluidos: any[], public documentosAdjuntos: any[], public docCobro: any, public formaCobro: any, public tipoFacturacion: any, public diasCobro1: any, public diasCobro2: any, public docPago: any, public formaPago: any, public IBAN: string, public diasPago1: any, public diasPago2: any) {}
}

export class InfoFinancieraEntidad {
  constructor(public plazoCobro: number, public ICPinterno: number, public coefICPinterno: number, public ICPproveedor: number, public coefICPproveedor: number, public ICPcompetencia: number, public coefICPcompetencia: number, public RIIM: number, public pdRIIM: number, public limiteCredito: number, public saldoVivo: number, public saldoVencido: number, public cifraNegocio: number, public resultadoNegocio: number, public tamano: string, public ultBalance: number, public ventasAnual: number) {}
}

export class SearchEntidades {
  constructor(public businessPartner: number, public empresa: any, public entidad: any, public comercial: any, public upcTrafico: any, public esCliente: boolean, public esProveedor: boolean, public existeSAP: boolean, public page: number, public filter: any, public numFilterRows: number, public gridState: StateGrid) {}
}

export class EntidadAsoc {
  constructor(public uuid: string, public referencia: any, public entidad: any) {}
}

export class DocsCalidad {
  constructor(public uuid: string, public empresa: any, public tipoDocumento: any, public fechaDocumento: any, public fechaCaducidad: any, public caduca: boolean, public caducado: boolean, public obligatorio: boolean, public entregado: boolean) {}
}

export class FacturacionEntidad {
  constructor(public value: number, public label: string) {}
}
