<div class="container animated fadeIn">
  <div style="margin: 0 auto; margin-top: 20px;">
    <div class="ui-g-12">
      <div class="container-fluid">
        <div id="ui-view">

          <div class="animated fadeIn">
            <div class="row">
              <div class="col-sm-6 col-lg-3">
                <div class="card text-white bg-primary">
                  <div class="card-body pb-0">
                    <div class="btn-group float-right">
                      <button class="btn btn-transparent p-0" type="button" aria-haspopup="true" aria-expanded="false"
                        routerLinkActive="active" [routerLink]="['/pedidos/buscar/pdte']">
                        <i class="icon-info"></i>
                      </button>
                    </div>
                    <div class="text-value font-4xl">167</div>
                    <div>{{ 'dashboard.pdteEmbarque' | translate }}</div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-3">
                <div class="card text-white bg-info">
                  <div class="card-body pb-0">
                    <button class="btn btn-transparent p-0 float-right" type="button" aria-haspopup="true" aria-expanded="false"
                    routerLinkActive="active" [routerLink]="['/pedidos/buscar/pdte']">
                      <i class="icon-info"></i>
                    </button>
                    <div class="text-value font-4xl">298</div>
                    <div>{{ 'dashboard.pdteEntrega' | translate }}</div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-3">
                <div class="card text-white bg-warning">
                  <div class="card-body pb-0">
                    <div class="btn-group float-right">
                      <button class="btn btn-transparent p-0" type="button" aria-haspopup="true" aria-expanded="false"
                      routerLinkActive="active" [routerLink]="['/pedidos/buscar/pdte']">
                        <i class="icon-info"></i>
                      </button>
                    </div>
                    <div class="text-value font-4xl">23</div>
                    <div>{{ 'dashboard.pdteDisponibilidad' | translate }}</div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-3">
                <div class="card text-white bg-danger">
                  <div class="card-body pb-0">
                    <div class="btn-group float-right">
                      <button class="btn btn-transparent p-0" type="button" aria-haspopup="true" aria-expanded="false"
                      routerLinkActive="active" [routerLink]="['/pedidos/buscar/pdte']">
                        <i class="icon-info"></i>
                      </button>
                    </div>
                    <div class="text-value font-4xl">12</div>
                    <div>{{ 'dashboard.pedidosRetrasados' | translate }}</div>
                  </div>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-6 col-lg-3">
                <div class="card">
                  <div class="card-body p-0 d-flex align-items-center">
                    <i class="fa fa-ship bg-primary p-4 font-2xl mr-3"></i>
                    <div>
                      <div class="text-value-sm text-primary">6 días</div>
                      <div class="text-muted text-uppercase font-weight-bold small">Embarque</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-lg-3">
                <div class="card">
                  <div class="card-body p-0 d-flex align-items-center">
                    <i class="fa fa-truck bg-info p-4 font-2xl mr-3"></i>
                    <div>
                      <div class="text-value-sm text-info">3 días</div>
                      <div class="text-muted text-uppercase font-weight-bold small">Entrega</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-lg-3">
                <div class="card">
                  <div class="card-body p-0 d-flex align-items-center">
                    <i class="fa fa-moon-o bg-warning p-4 font-2xl mr-3"></i>
                    <div>
                      <div class="text-value-sm text-warning">12 días</div>
                      <div class="text-muted text-uppercase font-weight-bold small">Disponible</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-lg-3">
                <div class="card">
                  <div class="card-body p-0 d-flex align-items-center">
                    <i class="fa fa-bell bg-danger p-4 font-2xl mr-3"></i>
                    <div>
                      <div class="text-value-sm text-danger">19 días</div>
                      <div class="text-muted text-uppercase font-weight-bold small">Retrasado</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-5">
                    <h4 class="card-title mb-0">Mis estadísticas</h4>
                    <div class="small text-muted">Marzo 2019</div>
                  </div>

                  <div class="col-sm-7 d-none d-md-block">
                    <button class="btn btn-primary float-right" type="button">
                      <i class="icon-cloud-download"></i>
                    </button>
                    <div class="btn-group btn-group-toggle float-right mr-3" data-toggle="buttons">
                      <label class="btn btn-outline-secondary">
                        <input id="option1" type="radio" name="options" autocomplete="off"> Semanal
                      </label>
                      <label class="btn btn-outline-secondary active">
                        <input id="option2" type="radio" name="options" autocomplete="off" checked=""> Mensual
                      </label>
                      <label class="btn btn-outline-secondary">
                        <input id="option3" type="radio" name="options" autocomplete="off"> Trimestral
                      </label>
                    </div>
                  </div>

                </div>

                <div class="chart-wrapper" style="margin-top:40px;">
                  <div class="chartjs-size-monitor"
                    style="position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;">
                    <div class="chartjs-size-monitor-expand"
                      style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                      <div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div>
                    </div>
                    <div class="chartjs-size-monitor-shrink"
                      style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                      <div style="position:absolute;width:200%;height:200%;left:0; top:0"></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-12">
                          <p-chart type="bar" [data]="dataChart"></p-chart>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <p-chart type="line" [data]="dataChart"></p-chart>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-12">
                          <p-chart type="radar" [data]="dataChart2"></p-chart>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <p-chart type="pie" [data]="dataChart3"></p-chart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">Seguimiento & Trazabilidad</div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="callout callout-info">
                              <small class="text-muted">Pedidos on-line</small>
                              <br>
                              <strong class="h4">9,123</strong>
                              <div class="chart-wrapper">
                                <canvas id="sparkline-chart-1" width="100" height="30"></canvas>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="callout callout-danger">
                              <small class="text-muted">Eventos</small>
                              <br>
                              <strong class="h4">22,643</strong>
                              <div class="chart-wrapper">
                                <canvas id="sparkline-chart-2" width="100" height="30"></canvas>
                              </div>
                            </div>
                          </div>

                        </div>

                        <hr class="mt-0">
                      </div>

                      <div class="col-sm-6">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="callout callout-warning">
                              <small class="text-muted">Incidencias</small>
                              <br>
                              <strong class="h4">78</strong>
                              <div class="chart-wrapper">
                                <canvas id="sparkline-chart-3" width="100" height="30"></canvas>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="callout callout-success">
                              <small class="text-muted">Agentes</small>
                              <br>
                              <strong class="h4">225</strong>
                              <div class="chart-wrapper">
                                <canvas id="sparkline-chart-4" width="100" height="30"></canvas>
                              </div>
                            </div>
                          </div>

                        </div>

                        <hr class="mt-0">
                      </div>

                    </div>

                    <br>
                    <table class="table table-responsive-sm table-hover table-outline mb-0">
                      <thead class="thead-light">
                        <tr>
                          <th class="text-center">
                            <i class="icon-user"></i>
                          </th>
                          <th>Agente</th>
                          <th class="text-center">Evento</th>
                          <th class="text-center">Pedido</th>
                          <th>Estado</th>
                          <th>Actividad</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center">
                            <div class="avatar">
                              <img class="img-avatar" src="./assets/images/avatars/user_dummy.png">
                            </div>
                          </td>
                          <td>
                            <div>Galletas Gullón</div>
                            <div class="small text-muted">
                              <span>Cargador</span> | TrackId: 2283HG-4487WS</div>
                          </td>
                          <td class="text-center">
                            <div>Disponible recogida</div>
                          </td>
                          <td class="text-center">
                            <div>4501258910</div>
                          </td>
                          <td>
                            <div class="clearfix">
                              <div class="float-left">
                                <strong>5%</strong>
                              </div>
                              <div class="float-right">
                                <small class="text-muted">Mar 05, 2019 - Mar 22, 2019</small>
                              </div>
                            </div>
                            <div class="progress progress-xs">
                              <div class="progress-bar bg-faded" role="progressbar" style="width: 5%"
                                aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </td>
                          <td>
                            <div class="small text-muted">Ult. actualización</div>
                            <strong>Hace 10 segundos</strong>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">
                            <div class="avatar">
                              <img class="img-avatar" src="./assets/images/avatars/2.jpg">
                            </div>
                          </td>
                          <td>
                            <div>Naviera Armas</div>
                            <div class="small text-muted">
                              <span>Naviera</span> | TrackId: 78DS12-99A2S1</div>
                          </td>
                          <td class="text-center">
                            Embarcado
                          </td>
                          <td class="text-center">
                            4501895264
                          </td>
                          <td>
                            <div class="clearfix">
                              <div class="float-left">
                                <strong>80%</strong>
                              </div>
                              <div class="float-right">
                                <small class="text-muted">Mar 11, 2019 - Mar 19, 2019</small>
                              </div>
                            </div>
                            <div class="progress progress-xs">
                              <div class="progress-bar bg-warning" role="progressbar" style="width: 80%"
                                aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </td>
                          <td>
                            <div class="small text-muted">Ult. actualización</div>
                            <strong>Hace 5 minutos</strong>
                          </td>
                        </tr>
                        <tr>
                            <td class="text-center">
                              <div class="avatar">
                                <img class="img-avatar" src="./assets/images/avatars/3.jpg">
                              </div>
                            </td>
                            <td>
                              <div>Transportes Archenera</div>
                              <div class="small text-muted">
                                <span>Transportista</span> | TrackId: 2983HG-1487DS</div>
                            </td>
                            <td class="text-center">
                              <div>Recogida realizada</div>
                            </td>
                            <td class="text-center">
                              <div>4501223918</div>
                            </td>
                            <td>
                              <div class="clearfix">
                                <div class="float-left">
                                  <strong>25%</strong>
                                </div>
                                <div class="float-right">
                                  <small class="text-muted">Mar 08, 2019 - Mar 21, 2019</small>
                                </div>
                              </div>
                              <div class="progress progress-xs">
                                <div class="progress-bar bg-primary" role="progressbar" style="width: 25%"
                                  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            </td>
                            <td>
                              <div class="small text-muted">Ult. actualización</div>
                              <strong>Hace 8 minutos</strong>
                            </td>
                          </tr>
                          <tr>
                              <td class="text-center">
                                <div class="avatar">
                                  <img class="img-avatar" src="./assets/images/avatars/4.jpg">
                                </div>
                              </td>
                              <td>
                                <div>Almacenes Tamarán</div>
                                <div class="small text-muted">
                                  <span>Almacén</span> | TrackId: 8683QG-4467D9</div>
                              </td>
                              <td class="text-center">
                                <div>Recepción almacén</div>
                              </td>
                              <td class="text-center">
                                <div>4501888676</div>
                              </td>
                              <td>
                                <div class="clearfix">
                                  <div class="float-left">
                                    <strong>65%</strong>
                                  </div>
                                  <div class="float-right">
                                    <small class="text-muted">Mar 02, 2019 - Mar 21, 2019</small>
                                  </div>
                                </div>
                                <div class="progress progress-xs">
                                  <div class="progress-bar bg-secondary" role="progressbar" style="width: 65%"
                                    aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </td>
                              <td>
                                <div class="small text-muted">Ult. actualización</div>
                                <strong>Hace 22 minutos</strong>
                              </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                  <div class="avatar">
                                    <img class="img-avatar" src="./assets/images/avatars/ef.png">
                                  </div>
                                </td>
                                <td>
                                  <div>European Forwarding</div>
                                  <div class="small text-muted">
                                    <span>Agente Aduanas</span> | TrackId: 4583TG-4567OS</div>
                                </td>
                                <td class="text-center">
                                  <div>Despacho importación</div>
                                </td>
                                <td class="text-center">
                                  <div>4501255733</div>
                                </td>
                                <td>
                                  <div class="clearfix">
                                    <div class="float-left">
                                      <strong>90%</strong>
                                    </div>
                                    <div class="float-right">
                                      <small class="text-muted">Mar 05, 2019 - Mar 22, 2019</small>
                                    </div>
                                  </div>
                                  <div class="progress progress-xs">
                                    <div class="progress-bar bg-danger" role="progressbar" style="width: 90%"
                                      aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </td>
                                <td>
                                  <div class="small text-muted">Ult. actualización</div>
                                  <strong>Hace 29 minutos</strong>
                                </td>
                              </tr>
                              <tr>
                                  <td class="text-center">
                                    <div class="avatar">
                                      <img class="img-avatar" src="./assets/images/avatars/jsv.png">
                                    </div>
                                  </td>
                                  <td>
                                    <div>JSV Logistic</div>
                                    <div class="small text-muted">
                                      <span>Operador Logístico</span> | TrackId: 7883WG-4412PS</div>
                                  </td>
                                  <td class="text-center">
                                    <div>Terminal destino</div>
                                  </td>
                                  <td class="text-center">
                                    <div>4501257554</div>
                                  </td>
                                  <td>
                                    <div class="clearfix">
                                      <div class="float-left">
                                        <strong>60%</strong>
                                      </div>
                                      <div class="float-right">
                                        <small class="text-muted">Mar 08, 2019 - Mar 22, 2019</small>
                                      </div>
                                    </div>
                                    <div class="progress progress-xs">
                                      <div class="progress-bar bg-info" role="progressbar" style="width: 60%"
                                        aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="small text-muted">Ult. actualización</div>
                                    <strong>Hace 36 minutos</strong>
                                  </td>
                                </tr>
                                <tr>
                                    <td class="text-center">
                                      <div class="avatar">
                                        <img class="img-avatar" src="./assets/images/avatars/mario.jpg">
                                      </div>
                                    </td>
                                    <td>
                                      <div>Transportes Canarios</div>
                                      <div class="small text-muted">
                                        <span>Transportista</span> | TrackId: 3183UH-4487JS</div>
                                    </td>
                                    <td class="text-center">
                                      <div>Entregado</div>
                                    </td>
                                    <td class="text-center">
                                      <div>4501244611</div>
                                    </td>
                                    <td>
                                      <div class="clearfix">
                                        <div class="float-left">
                                          <strong>100%</strong>
                                        </div>
                                        <div class="float-right">
                                          <small class="text-muted">Mar 05, 2019 - Mar 22, 2019</small>
                                        </div>
                                      </div>
                                      <div class="progress progress-xs">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 100%"
                                          aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="small text-muted">Ult. actualización</div>
                                      <strong>Hace 47 minutos</strong>
                                    </td>
                                  </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</div>

