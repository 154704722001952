import { ComponentFactoryResolver, Injectable, Inject, ReflectiveInjector } from '@angular/core';

import { HomeDashboardComponent } from '@coreUI/components/homeDashboard/homeDashboard.component';

@Injectable()
export class DynamicLoaderService {
  public factoryResolver: any;
  public rootViewContainer: any;

  constructor(@Inject(ComponentFactoryResolver) factoryResolver) {
    this.factoryResolver = factoryResolver;
  }

  setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  addHomeDashboardComponent() {
    const factory = this.factoryResolver.resolveComponentFactory(HomeDashboardComponent);
    const component = factory.create(this.rootViewContainer.parentInjector);
    this.rootViewContainer.insert(component.hostView);
  }
}
