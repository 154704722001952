<div class="container animated fadeIn">
    <div class="container animated fadeIn">
<div class="ui-g" style="max-width: 900px; margin: 0 auto; margin-top: 20px;">
            <div class="error_acceso">                
                <p class="mensaje_error_acceso">ERROR: Acceso restringido</p>                
                <p class="error_acceso_txt">
                    
                    Parece que estas intentando acceder a una seccion de la Web para la que tu usuario no tiene suficientes privilegios de acceso.

                </p>
                <p class="error_acceso_inner-box">Si necesitas ayuda, ponte en contacto con el administrador <a href="mailto:a2soporte@jsvlogistic.com">a2soporte@jsvlogistic.com</a> e indica el siguiente codigo: <br><br><i>Error 403: Forbidden access</i>.</p>
            </div>            
</div>
    </div>  
</div>