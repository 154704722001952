import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NuevoUsuarioService } from '@auth/services/nuevoUsuario.service';

import { NotificationBarService, NotificationType } from '@comun/UI/angular2-notification-bar';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'resetClave',
  templateUrl: './resetClave.html',
  providers: [NuevoUsuarioService],
})
export class ResetClaveComponent implements OnInit {
  public envioemail: string;
  public backEnd: boolean;

  constructor(private _usuariosService: NuevoUsuarioService, private confirmationService: ConfirmationService, private _notificationBarService: NotificationBarService, private _router: Router) {}
  ngOnInit() {
    this.envioemail = '';
    this.backEnd = !!sessionStorage.getItem('BackEnd');
  }

  // Funciones auxiliares

  showNotification(tipoMsg: string, bodyMsg: string, autoHide: boolean, allowClose: boolean) {
    this._notificationBarService.create({
      message: bodyMsg,
      type: NotificationType[tipoMsg],
      autoHide: autoHide,
      allowClose: allowClose,
    });
  }

  confirm() {
    this.confirmationService.confirm({
      message: 'Si tus datos son correctos, en breve recibirás un correo electrónico con el enlace para restaurar tu contraseña',
      accept: () => {
        this.onSubmit();
      },
    });
  }

  onSubmit() {
    if (this.backEnd) {
      this._usuariosService.resetClave(this.envioemail).subscribe((result) => {
        if (result['status'] === 'ok') {
          this.showNotification('Success', 'Comprueba tu email!', true, true);
          setTimeout(() => {
            this._router.navigate(['/login']);
          }, 1250);
        }
        if (result['status'] === 'error') {
          this.showNotification('Error', 'No existe ese email en el servidor', true, true);
        }
      });
    } else {
      this.showNotification('Error', 'No tienes conexión a Internet', false, true);
    }
  }
}
