import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { catchError, finalize, map } from 'rxjs/operators';
import 'rxjs-compat/add/operator/do';
import 'rxjs-compat/add/operator/catch';
import 'rxjs-compat/add/observable/throw';
import 'rxjs-compat/add/observable/empty';
import { throwError } from 'rxjs';

import { AuthService } from '@auth/services/auth.service';
import { DebugLogService } from '@comun/services/debugLog.service';

@Injectable()
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private inj: Injector, private status: HTTPStatus) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.status.setHttpStatus(true);
    return next
      .handle(this.setAuthorizationHeader(req))
      .pipe(
        map((event) => {
          return event;
        }),
        finalize(() => {
          this.status.setHttpStatus(false);
        })
      )
      .catch((event) => {
        console.log(event);
        const debugLogService = this.inj.get(DebugLogService);
        debugLogService.logError('ErrorTokenInterceptor', event.name + ': ' + event.message, event.url, event);
        if (event instanceof HttpErrorResponse) {
          return this.catch401(event);
        }
      });
  }

  private setAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
    const auth = this.inj.get(AuthService);
    const authToken = auth.getToken();
    if (authToken) {
      req = req.clone({ setHeaders: { Authorization: 'Bearer ' + authToken } });
    }
    if (!req.headers.has('Content-Type')) {
      if (!req.headers.has('Content-Location')) {
        req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
      }
    }
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    return req;
  }

  // Response Interceptor
  private catch401(error: HttpErrorResponse): Observable<any> {
    // Check if we had 401 response
    if (error.status === 401) {
      const auth = this.inj.get(AuthService);
      auth.logout();
      return Observable.empty();
    }
    return throwError(error);
  }
}
