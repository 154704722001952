import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';

// Componentes PrimeNG
import { NotificationBarService, NotificationType } from '@comun/UI/angular2-notification-bar';
import { MenuItem } from 'primeng/api';
import { ModalDirective } from 'ngx-bootstrap/modal';

// Servicios importados de otros módulos
import { UsuariosService } from '@usuarios/services/usuarios.service';

// Servicios de utilidades comunes
import { AuthService } from '@auth/services/auth.service';

import { UIComponentsService } from '@comun/services/uiComponents.service';

// Modelos de configuraciones comunes
import { Usuario } from '@usuarios/model/usuario.model';

// Servicios del modulo Maritimo

import { ControlVersionService } from '@comun/services/controlVersion.service';

// Modelos del componente
import { InfoVersion } from '@comun/model/soporte.model';

@Component({
  selector: 'novedadesVersion-modal',
  templateUrl: './novedadesVersion.modal.html',
  providers: [UIComponentsService, UsuariosService, ControlVersionService, AuthService],
})
export class NovedadesVersionModalComponent implements OnInit {
  @ViewChild('novedadesVersionModal', { static: false }) public novedadesVersionModal: ModalDirective;

  @Output() onClose = new EventEmitter();

  public isShow: Boolean;
  public isCollapsed: Boolean[] = [];
  public idChangelog: any[] = [];
  public showDialog: boolean;
  public displayError: boolean;
  public showModalGrid: boolean;
  public showDialogVersion: boolean;
  public miUsuario: Usuario;
  public autorizacion: string;
  public version: string;
  public mostrar: boolean;
  public changelog: any[];
  public infoVersion: InfoVersion;
  public versiones: any[] = [];
  public modulosVersion: any[] = [];
  public fechasVersion: any[] = [];
  // Variables del componente
  public modalNovedadesVersion: any;
  // Variables de los campos de busqueda
  public itemsOpciones: MenuItem[];

  constructor(private _uiComponentService: UIComponentsService, public _usuariosService: UsuariosService, public _controlVersionService: ControlVersionService, private _notificationBarService: NotificationBarService, private _authService: AuthService) {}

  ngOnInit() {
    this.miUsuario = this._authService.getMiUsuario();
    this.autorizacion = this._usuariosService.getPermisos(this.miUsuario, 'sgAduanas');
    // Inicialización de objetos principales de componente
    this._controlVersionService.getDropDownTiposVersion();
    this.infoVersion = new InfoVersion(this._uiComponentService.uuid(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, 0, 0);
  }

  // Funciones propias del componente Modal

  public handler(type: string, $event: ModalDirective) {
    if (type === 'onHidden') {
    }
    if (type === 'onShow') {
    }
  }

  public showChildModal(data: any): void {
    this.changelog = data;
    this.extractVersion();
    this.extractModulosVersion();
    this.extractFechaVersion();
    this.showModalGrid = true;
    this.novedadesVersionModal.config.backdrop = false; // workaround
    this.novedadesVersionModal.config.ignoreBackdropClick = true;
    this.novedadesVersionModal.show();
  }

  public cancelChildModal(): void {
    this.showModalGrid = false;
    this.onClose.emit({ novedadesVersion: this.mostrar, action: 'close' });
    this.modalNovedadesVersion = undefined;
    this.novedadesVersionModal.hide();
  }

  // Funciones auxiliares

  showNotification(tipoMsg: string, bodyMsg: string, autoHide: boolean, allowClose: boolean) {
    this._notificationBarService.create({
      message: bodyMsg,
      type: NotificationType[tipoMsg],
      autoHide: autoHide,
      allowClose: allowClose,
    });
  }

  procesoInfoVersion(visible: boolean, action: string) {
    this.showDialogVersion = visible;
    if (action === 'init') {
      this.infoVersion = new InfoVersion(this._uiComponentService.uuid(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, 0);
    } else if (action === 'save') {
      if (this.miUsuario.sadmin) {
        this.infoVersion.version = this.infoVersion.version['nombre'];
        this.infoVersion.fecha_version = this.infoVersion.fecha_version['nombre'];
        this.infoVersion.modulo = this.infoVersion.modulo['nombre'];
        this.infoVersion.css_tipo = this.infoVersion.tipo['css'];
        this.infoVersion.tipo = this.infoVersion.tipo['nombre'];
        this._controlVersionService.addInfoVersion(this.infoVersion).subscribe(() => {
          this.showNotification('Success', 'Información incluida correctamente', true, true);
        });
      } else {
        this.showNotification('Warning', 'No tienes permisos para realizar esta operación', true, true);
      }
    } else if (action === 'cancel') {
      this.infoVersion = new InfoVersion(this._uiComponentService.uuid(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, 0, 0);
    }
  }

  extractModulosVersion() {
    let data: any;
    let arrayData: any[] = [];
    this.changelog.forEach((item) => {
      item.changelog.forEach((element) => {
        data = element.modulo;
        arrayData.push(data);
      });
    });
    arrayData = arrayData.filter(this._uiComponentService.arrayUnique);
    arrayData.forEach((item) => {
      let info = { label: item, value: { id: item, nombre: item } };
      this.modulosVersion.push(info);
    });
  }

  extractVersion() {
    let data: any;
    let arrayData: any[] = [];
    this.changelog.forEach((item) => {
      data = item.version;
      arrayData.push(data);
    });
    arrayData = arrayData.filter(this._uiComponentService.arrayUnique);
    arrayData.forEach((item) => {
      let info = { label: item, value: { id: item, nombre: item } };
      this.versiones.push(info);
    });
  }

  extractFechaVersion() {
    let data: any;
    let arrayData: any[] = [];
    this.changelog.forEach((item) => {
      data = item.fechaVersion;
      arrayData.push(data);
    });
    arrayData = arrayData.filter(this._uiComponentService.arrayUnique);
    arrayData.forEach((item) => {
      let info = { label: item, value: { id: item, nombre: item } };
      this.fechasVersion.push(info);
    });
  }
}
