import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(date: Date, args: any[]) {
    moment.locale('es');
    let localLocale = moment(date).locale('es').fromNow();
    return localLocale;
  }
}
