<div class="container">
    <div class="login-form">
        <div class="login-msg"></div>
        <form #formLogin="ngForm" (ngSubmit)="onSubmit()" role="login" novalidate>

            <img src="./assets/images/logoLogin.png" id="logo" class="img-responsive img-logo" alt="" />
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-user"></i></span>
                <input type="text" name="username" #email="ngModel" [(ngModel)]="accessToken.username" placeholder="Correo electr&oacute;nico"  id="username" required class="form-control input-lg" />
            </div>

            <div>&nbsp;</div>

            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                <input type="password" name="password" #password="ngModel" [(ngModel)]="accessToken.password" placeholder="Contrase&ntilde;a" id="password" required class="form-control input-lg"/>
            </div>

            <button class="btn btn-lg btn-primary btn-block"><i class="fa fa-sign-in fa fa-fw"></i>Conectar</button>
            <div>
                <div style="float: left; width: 180px;"><a href="" [routerLink]="['/resetClave']">&iquest;Olvidaste tu contrase&ntilde;a?</a></div>
                <div style="float: right; width: 120px; text-align: right;"><a href="" [routerLink]="['/nuevoUsuario']">Nuevo usuario</a></div>
            </div>
        </form>
    </div>
</div>

<p-confirmDialog header="Confirmación" icon="fa fa-question-circle" acceptLabel="Si" rejectLabel="No" [style]="{width: '450px'}"></p-confirmDialog>
