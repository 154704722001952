import { NgModule, ModuleWithProviders } from '@angular/core';

//import { CoreUIRoutingModule } from  './coreui.routing';

import { DashboardComponent, HomeDashboardComponent, A2LogisticComponent, AyudaComponent, CalendarioComponent } from '@coreUI/components';

//import {CalendarComponent} from 'ap-angular2-fullcalendar/src/calendar/calendar';

import { NavDropdownDirective, NavDropdownToggleDirective, SidebarToggleDirective, SidebarOffCanvasCloseDirective, MobileSidebarToggleDirective, AsideToggleDirective, MenubarToggleDirective, ToggleFullscreenDirective } from '@coreUI/directives';

import { ComunModule } from '@comun/comun.module';

@NgModule({
  declarations: [
    DashboardComponent,
    A2LogisticComponent,
    HomeDashboardComponent,
    AyudaComponent,
    CalendarioComponent,
    NavDropdownDirective,
    NavDropdownToggleDirective,
    SidebarToggleDirective,
    SidebarOffCanvasCloseDirective,
    MobileSidebarToggleDirective,
    AsideToggleDirective,
    MenubarToggleDirective,
    ToggleFullscreenDirective,
  ],
  imports: [
    //rwa modules
    ComunModule,
    //CoreUIRoutingModule
  ],
  providers: [],
  entryComponents: [HomeDashboardComponent],
})
export class CoreUIModule {}
