import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationBarService } from '@comun/UI/angular2-notification-bar/src/notification-bar.service';
import { NotificationBarComponent, MESSAGES_CONFIG } from '@comun/UI/angular2-notification-bar/src/notification-bar.component';
import { MessagesConfig } from '@comun/UI/angular2-notification-bar/src/message-config';

@NgModule({
  imports: [CommonModule],
  declarations: [NotificationBarComponent],
  providers: [NotificationBarService],
  exports: [NotificationBarComponent],
})
export class NotificationBarModule {
  static forRoot(config: MessagesConfig): ModuleWithProviders<NotificationBarModule> {
    return {
      ngModule: NotificationBarModule,
      providers: [{ provide: MESSAGES_CONFIG, useValue: config }],
    };
  }
}
