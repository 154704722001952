import { Component, ViewContainerRef, OnInit } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { NotificationBarService, NotificationType } from '@comun/UI/angular2-notification-bar';
import { HTTPStatus } from '@auth/services/token.interceptor';

import { TranslateService } from '@ngx-translate/core';

import { APIRoutesService } from '@app/services/apiRoutes.service';

@Component({
  selector: 'body',
  styleUrls: ['app.component.css'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private viewContainerRef: ViewContainerRef;
  public HTTPActivity: boolean;
  public loading: boolean;

  public constructor(viewContainerRef: ViewContainerRef, private router: Router, private _notificationBarService: NotificationBarService, private _APIRoutesService: APIRoutesService, private httpStatus: HTTPStatus, private translate: TranslateService) {
    this.translate.setDefaultLang('es');
    this.translate.use('es');
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.HTTPActivity = status;
    });
    this.viewContainerRef = viewContainerRef;
    this.loading = true;
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  ngOnInit() {
    this._APIRoutesService.setBackEnd();
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  // Funciones auxiliares

  showNotification(tipoMsg: string, bodyMsg: string, autoHide: boolean, allowClose: boolean) {
    this._notificationBarService.create({
      message: bodyMsg,
      type: NotificationType[tipoMsg],
      autoHide: autoHide,
      allowClose: allowClose,
    });
  }
}
