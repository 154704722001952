import { Injectable, Injector, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NotificationBarService, NotificationType } from '@comun/UI/angular2-notification-bar';

import { AuthService } from '@auth/services/auth.service';
import { APIRoutesService } from '@app/services/apiRoutes.service';

// just an interface for type safety.
interface ICTerror {
  id: number;
  type: string;
  status: any;
  message: string;
  url: any;
  route: any;
  browser: any;
  plataform: any;
  backEnd: any;
  userIP: any;
  miUsuario: any;
  ahora: any;
  checkSession: any;
  event: any;
}

@Injectable()
export class DebugLogService {
  private apiURL: string;
  public options: any;

  constructor(private _http: HttpClient, private _APIRoutesService: APIRoutesService, private _notificationBarService: NotificationBarService, private inj: Injector) {
    this.apiURL = _APIRoutesService.getApiURIdebugLog();
  }

  showNotification(tipoMsg: string, bodyMsg: string, autoHide: boolean, allowClose: boolean) {
    this._notificationBarService.create({
      message: bodyMsg,
      type: NotificationType[tipoMsg],
      autoHide: autoHide,
      allowClose: allowClose,
    });
  }

  logError(type: string, message: string, url: any, event: any) {
    let miUsuario;

    let checkSession = !!sessionStorage.getItem('session');
    if (checkSession) {
      const auth = this.inj.get(AuthService);
      let auxMiUsuario = auth.getMiUsuario();
      miUsuario = { id: auxMiUsuario.id, nombre: auxMiUsuario.nombre };
    } else {
      miUsuario = { id: 0, nombre: 'No user' };
    }

    const catchError: ICTerror = {
      id: Date.now(),
      type: type,
      status: event.status,
      message: message,
      url: url,
      route: window.location.href,
      browser: window.navigator.userAgent,
      plataform: window.navigator.platform,
      backEnd: sessionStorage.getItem('BackEnd'),
      userIP: sessionStorage.getItem('IP'),
      miUsuario: miUsuario,
      ahora: this.toMySQLdatetime(new Date()),
      checkSession: checkSession,
      event: event,
    };

    if (message.includes('ExpressionChangedAfterItHasBeenCheckedError:') || message.includes('Cannot read property') || message.includes('Attempt to use a destroyed view')) {
    } else {
      let code;
      if (catchError.event.hasOwnProperty('error')) {
        code = catchError.event.error.errors.code;
      }
      this.checkErrorType(catchError);
      switch (code) {
        case 'InvalidCredentials':
          break;
        case 'AuthenticationFailed':
          break;
        default:
          //this.saveErrorLog(catchError);
          break;
      }
      if (isDevMode()) {
        console.log('**********Inicio la secuencia de error**********');
        console.log(catchError);
        console.log('**********Fin la secuencia de error**********');
        console.error(event);
      }
    }
  }

  saveErrorLog(error: ICTerror) {
    let body = JSON.stringify(error);

    if (error.checkSession) {
      this._http.post(this.apiURL + '/write', body, this.options).subscribe();
    }
  }

  checkErrorType(error: ICTerror) {
    let code;
    if (error.event.hasOwnProperty('error')) {
      code = error.event.error.errors.code;
    }
    if (error.message.includes('Ha ocurrido un error en el servidor de SAP')) {
      this.showNotification('Warning', 'SAP Info: ' + error.message + ' --- ID: ' + error.id, true, true);
    } else {
      if (code) {
        switch (code) {
          case 0:
            this.showNotification('Error', 'Servidor en mantenimiento. Disculpa las molestias', false, true);
            break;
          case 'InvalidCredentials':
            this.showNotification('Warning', 'Usuario / Contraseña incorrecta. No puedes iniciar sesión', false, true);
            break;
          case 'AuthenticationFailed':
            this.showNotification('Warning', 'Tu sesión ha caducado debes volver a iniciar sesión', false, true);
            break;
          default:
            this.showNotification('Error', 'Error: ' + error.message + ' --- ID: ' + error.id, false, true);
            break;
        }
      } else {
        this.showNotification('Error', 'Error: ' + error.message + ' --- ID: ' + error.id, false, true);
      }
    }
  }

  // Input: Date -> Output: MySQL Date (YYYY-mm-dd hh:ii:ss)
  toMySQLdatetime(fecha: Date) {
    let add: string;
    let resDay: string;
    let resMonth: string;
    let day = fecha.getDate().toString();
    let hours = fecha.getHours().toString();
    let minutes = fecha.getMinutes().toString();
    add = '0';
    if (day.length === 1) {
      resDay = add.concat(day);
    } else {
      resDay = day;
    }
    let numMonth = fecha.getMonth() + 1;
    let month = numMonth.toString();
    if (month.length === 1) {
      resMonth = add.concat(month);
    } else {
      resMonth = month;
    }
    let year = fecha.getFullYear();

    return year + '-' + resMonth + '-' + resDay + ' ' + hours + ':' + minutes + ':00';
  }
}
