import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs-compat/Subscription';
import { TranslateService } from '@ngx-translate/core';

// Componentes PrimeNG
import { NotificationBarService, NotificationType } from '@comun/UI/angular2-notification-bar';
import { ConfirmationService } from 'primeng/api';

import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';

import { AuthService } from '@auth/services/auth.service';
import { UIComponentsService } from '@comun/services/uiComponents.service';
import { PushNotificationsService } from '@auth/services/pushNotifications.service';
import { APIRoutesService } from '@app/services/apiRoutes.service';
import { UsuariosService } from '@usuarios/services/usuarios.service';
import { ControlVersionService } from '@comun/services/controlVersion.service';

import { APIConfig } from '@app/models/global';
import { EncryptionService } from '@comun/services/encryption.service';

import { Usuario, Favorito } from '@usuarios/model/usuario.model';
import { Channel } from '@comun/model/socket.model';

import { NovedadesVersionModalComponent } from '@comun/modal/novedadesVersion/novedadesVersion.modal.component';

import * as io from 'socket.io-client';
import { CommonService } from '@comun/services/common.service';
import { appsMenu, extrasMenu, sistemaMenu, usuariosMenu } from '@coreUI/model/apps.model';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-dashboard',
  templateUrl: './A2Logistic.html',
  styleUrls: ['./A2Logistic.css'],
  providers: [AuthService, PushNotificationsService, EncryptionService, UsuariosService, ControlVersionService, UIComponentsService],
})
export class A2LogisticComponent implements OnInit, OnDestroy {
 @ViewChild(NovedadesVersionModalComponent, { static: false }) private modalComponentNovedades: NovedadesVersionModalComponent;

  private channelKey: string;
  // Variable de Usuario
  public miUsuario: Usuario;
  public misSuscripciones: Channel[];
  public misFavoritos: Favorito[];
  public apiImagenAvatar: string;
  public es: any;
  // Variables Registro Horario
  public showDialogHorario: boolean;
  public horarioUltRegistro: any;
  public horarioUtlFecha: any;
  public horarioUtlMov: any;
  public horarioObs: string;
  public horarioManual: boolean;
  public horarioFecha: any;
  // Variables de comunicacion WebSockets
  public socket: any;
  private socketUrl: string;
  public message = '';
  public numMessages: number;
  public numAutorizaciones: number;
  public numSuscripciones: number;
  public showNumMessages: boolean;
  public conversation: any = [];
  public revConversation: any = [];
  public version: string;
  public leftDays: string;
  public bubbleClass: string;
  public server: string;
  public serverMessage: boolean;
  public cumpleanyos: boolean;
  // Variables para la gestión de Tabs
  public listenerTabs: Subscription;
  public selectedTab: any[] = [];
  public selectedTab$: any;
  public _apps: any[] = [];
  public _extras: any[] = [];
  public _sistema: any[] = [];
  public tmpMenus: any[] = [];
  public tabSelectedIndex = 0;
  public stackTabs: any[] = [];
  public menuIsCollapsed: boolean;
  menuWidth = 40;

  companyName = 'Mario García Fernández';
  avatar = 'assets/image/avatar.jpeg';

  constructor(
    private _router: Router,
    private _authService: AuthService,
    public _usuariosService: UsuariosService,
    private _uiComponentService: UIComponentsService,
    private confirmationService: ConfirmationService,
    private _notificationBarService: NotificationBarService,
    private _pushNotificationService: PushNotificationsService,
    private _encryptionService: EncryptionService,
    public _controlVersionService: ControlVersionService,
    private _APIRoutesService: APIRoutesService,
    public _translateService: TranslateService
  ) {
    this._pushNotificationService.requestPermission();
    this.apiImagenAvatar = _APIRoutesService.getApiURIusuarios();
    this.socketUrl = sessionStorage.getItem('NodeServer');
    this.socket = io.connect(this.socketUrl);
    this.channelKey = APIConfig.CHANNEL_KEY;
    // Tipos de mensaje que escuchará A2Logistic
    this.socket.on('new message', (item: any) => this.addChatMessage(item));
    this.socket.on('user joined', (item: any) => this.addChatMessage(item));
    this.socket.on('add user', (item: any) => this.addChatMessage(item));
    this.socket.on('dis user', (item: any) => this.addChatMessage(item));
    this.numMessages = -1;
    this.numAutorizaciones = 0;
    this.numSuscripciones = 0;
    // Subscribe para la gestión de Tabs
    this.stackTabs = [];
    this.listenerTabs = CommonService.subscribe('selectedTab', (event: any) => {
      let abrirNewTab: boolean;
      if (event) {
        this.selectedTab$ = event.param;
        if (this.selectedTab$.TABEVENT === 'Close') {
          const tabClose = this.stackTabs.filter((tab) => tab.content.TABID === this.selectedTab$.TABID);
          this.closeTabNav(tabClose[0], true);
        } else {
          const tabClose = this.stackTabs.filter((tab) => tab.content.TABID === this.selectedTab$.TABCLOSEID);
          if (tabClose.length > 0) {
            this.updateTabNav(tabClose[0], this.selectedTab$);
          }
          const tabOne = this.stackTabs.filter((tab) => tab.content.TABID === this.selectedTab$.TABID);

          if (tabOne.length > 0) {
            if (tabOne[0].content.TABICON === this.selectedTab$.TABICON) {
              this.tabSelectedIndex = tabOne[0].index;

              if (this.selectedTab$['TIMESTAMP']) {
                tabOne[0].content['TIMESTAMP'] = this.selectedTab$['TIMESTAMP'];
              }
              this.selectedTabMenu(tabOne[0]);
            } else {
              abrirNewTab = true;
            }
          } else {
            abrirNewTab = true;
          }

          if (abrirNewTab) {
            const tab = {
              id: this.selectedTab$.ID,
              index: this.stackTabs.length,
              name: this.selectedTab$.TABNAME,
              close: true,
              icon: this.selectedTab$.TABICON,
              content: this.selectedTab$,
              refresh: 'Y',
            };
            if (this.stackTabs.length === 0) {
              tab.close = false;
            }
            this.stackTabs.push(tab);
            this.tabSelectedIndex = tab.index;
            this.selectedTabMenu(tab);
          }
        }
      }
    });
  }

  ngOnInit() {
    this.miUsuario = this._authService.getMiUsuario();
    this.misSuscripciones = this._authService.getMisSuscripciones();
    this.countSuscripciones();
    this.misFavoritos = this._authService.getMisFavoritos();
    this.es = this._uiComponentService.setCalendar();
    this.version = APIConfig.VERSION;
    this.countAutorizaciones(this.miUsuario.id);
    this.connect(this.miUsuario, this.misSuscripciones);
    this.days2deadLine();
    this.getServer();
    this.showNovedadesVersion();
    this.happyBirthday();
    this.initTabs();
    this.goToDashboard();
    setTimeout(() => {
      this.menuIsCollapsed = true;
    }, 750);
  }

  ngOnDestroy() {
    this.listenerTabs.unsubscribe();
  }

  // Translate

  setLanguage(language: string) {
    this._translateService.use(language);
    this.initTabs();
  }

  //NZ Layout

  toggleLayout() {
    this.menuWidth = this.menuWidth === 40 ? 200 : 40;
    this.menuIsCollapsed = this.menuIsCollapsed === true ? false : true;
  }

  // Tabs

  initTabs() {
    let tmpMenus = this.getAppsMenus().APPS_MENU;
    this._translateService.get('apps').subscribe((translations) => {
      tmpMenus.forEach((level0) => {
        if (level0.HASCHILD === 'Y') {
          level0['TABNAME'] = translations[level0['TABID']];
          level0.P_CHILDMENUS.forEach((level1) => {
            level1['TABNAME'] = translations[level1['TABID']];
            if (level1.HASCHILD === 'Y') {
              level1.P_CHILDMENUS.forEach((level2) => {
                if (level2.HASCHILD === 'Y') {
                  level2['TABNAME'] = translations[level2['TABID']];
                  level2.P_CHILDMENUS.forEach((level3) => {
                    level3['TABNAME'] = translations[level3['TABID']];
                  });
                } else {
                  level2['TABNAME'] = translations[level2['TABID']];
                }
              });
            } else {
              level1['TABNAME'] = translations[level1['TABID']];
            }
          });
        } else {
          level0['TABNAME'] = translations[level0['TABID']];
        }
      });
    });

    this._apps = tmpMenus[0].P_CHILDMENUS;

    this.tmpMenus = this.getExtrasMenus().EXTRAS_MENU;
    this._extras = this.tmpMenus[0].P_CHILDMENUS;

    this.tmpMenus = this.getSistemaMenus().SISTEMA_MENU;
    this._sistema = this.tmpMenus[0].P_CHILDMENUS;

    if (this.stackTabs) {
      this.stackTabs = [];
      this.tabSelectedIndex = 0;
      if (this.stackTabs.length === 0) {
        this.stackTabs.push({
          id: '0',
          index: 0,
          name: 'Dashboard',
          close: false,
          icon: 'icon-speedometer',
          refresh: 'N',
          content: {
            ID: 'APP',
            TABID: 'HOME',
            TABNAME: 'Dashboard',
            TABICON: 'icon-speedometer',
            ROUTER: '/dashboard',
          },
        });
      }
    }
  }

  selectedMenu(menu: any) {
    menu.select = true;
    CommonService.event('selectedTab', menu);
  }

  goFavorito(tipo, id) {
    let newTab;
    if (tipo === 'APPS') {
      newTab = this._uiComponentService.filterByProperty(appsMenu.APPS_MENU[0].P_CHILDMENUS, 'TABID', id);
    } else if (tipo === 'EXTRAS') {
      newTab = this._uiComponentService.filterByProperty(extrasMenu.EXTRAS_MENU[0].P_CHILDMENUS, 'TABID', id);
    } else if (tipo === 'USERS') {
      newTab = this._uiComponentService.filterByProperty(usuariosMenu.USER_MENU[0].P_CHILDMENUS, 'TABID', id);
    }
    setTimeout(() => {
      this.selectedMenu(newTab[0]);
    }, 50);
  }

  selectedTabMenu(tabMenu: any) {
    let refresh = 'Y';
    refresh = this.selectedTab[tabMenu.content.TABID] && this.selectedTab[tabMenu.content.TABID] !== '' ? 'N' : 'Y';
    if (!this.selectedTab[tabMenu.content.TABID]) {
      this.selectedTab[tabMenu.content.TABID] = tabMenu.content.TABID;
    } else {
      refresh = 'N';
    }
    const params = {
      queryParams: {
        REFRESH: refresh,
        ID: tabMenu.content.ID,
        TABID: tabMenu.content.TABID,
        TABNAME: tabMenu.content.TABNAME,
        TABICON: tabMenu.content.TABICON,
        TIMESTAMP: tabMenu.content.TIMESTAMP,
        ROUTER: tabMenu.content.ROUTER,
      },
    };
    this._router
      .navigate([tabMenu.content.ROUTER], params)
      .then(() => {})
      .catch((error) => {
        console.log(error);
        this._router.navigate(['/error']);
      });
  }

  updateTabNav(tabClose: any, tab: any): void {
    this.stackTabs[tabClose.index].id = tab.ID;
    this.stackTabs[tabClose.index].name = tab.TABNAME;
    this.stackTabs[tabClose.index].content.ID = tab.ID;
    this.stackTabs[tabClose.index].content.TABID = tab.TABID;
    this.stackTabs[tabClose.index].content.TABNAME = tab.TABNAME;
    this.stackTabs[tabClose.index].content.TABICON = tab.TABICON;
    this.stackTabs[tabClose.index].content.ROUTER = tab.ROUTER;
  }

  closeTabNav(tab: any, navigate: boolean): void {
    this.selectedTab[tab.content.TABID] = '';
    if (tab.close) {
      this.stackTabs.splice(this.stackTabs.indexOf(tab), 1);
      sessionStorage.removeItem(tab.content.ID);
      sessionStorage.removeItem(tab.content.TABID);
      if (Number.isInteger(tab.content.ID)) {
        sessionStorage.removeItem(tab.content.TIMESTAMP);
      }
      let i = 0;
      this.stackTabs.forEach((item) => {
        item.index = i++;
      });
      if (this.stackTabs.length > 0 && navigate) {
        if (this.tabSelectedIndex && tab.index > this.tabSelectedIndex) {
          CommonService.event('selectedTab', this.stackTabs[this.tabSelectedIndex].content);
        } else {
          CommonService.event('selectedTab', this.stackTabs[this.tabSelectedIndex - 1].content);
        }
      }
    }
  }

  goToDashboard() {
    CommonService.event('selectedTab', {
      ID: 'APP',
      TABID: 'HOME',
      TABNAME: 'Dashboard',
      TABICON: 'icon-speedometer',
      ROUTER: '/dashboard',
    });
  }

  getAppsMenus() {
    return appsMenu;
  }

  getExtrasMenus() {
    return extrasMenu;
  }

  getSistemaMenus() {
    return sistemaMenu;
  }

  // Funciones auxliares

  showNotification(tipoMsg: string, bodyMsg: string, autoHide: boolean, allowClose: boolean) {
    this._notificationBarService.create({
      message: bodyMsg,
      type: NotificationType[tipoMsg],
      autoHide: autoHide,
      allowClose: allowClose,
    });
  }

  // Funciones de comunicación por WebSockets

  connect(usuario: Usuario, channel: Channel[]) {
    this.send2channel('add user', this.miUsuario, '', 'Estoy dentro!!!', false);
    channel.forEach((element: any) => {
      this.send2channel('join channel', this.miUsuario, element.channel, 'Suscrito al canal!!!', false);
    });
  }

  send2channel(type: string, usuario: Usuario, channel: string, message: string, echo: boolean) {
    let ahora = new Date();
    let data = {
      username: usuario.nombre,
      channel: channel,
      message: message,
      date: ahora,
      userid: usuario.id,
      userImagen: usuario.imagen,
    };
    this.socket.emit(type, data);
    if (echo) {
      this.addChatMessage(data);
    }
  }

  sendMessage(usuario: Usuario, channel: any, message: any, broadcast: boolean, echo: boolean) {
    if (broadcast) {
      channel.forEach((element: any) => {
        this.send2channel('new message', usuario, element.channel, message, echo);
      });
    } else {
      this.send2channel('new message', usuario, channel, message, echo);
    }
    this.message = '';
  }

  // Función para la notificacion de mensajes Push

  pushNotify(title: string, body: string) {
    let options = {
      body: body,
      icon: 'assets/images/icons/angular.png',
      sound: 'assets/sounds/alert.wav',
      noscreen: false,
      sticky: true,
      dir: 'auto',
      lang: 'ES',
      vibrate: [1000, 1000, 1000, 1000, 1000],
    };
    this._pushNotificationService.generateNotification(title, options);
  }

  // Cambia a pantalla completa

  toggleFullscreen() {
    let sf = <Screenfull>screenfull;
    if (sf.isEnabled) {
      sf.toggle();
    }
  }

  // Funciones de manejo de los mensajes en las vistas

  addChatMessage(data: any) {
    let sistema, aux, doc, numDoc: string;
    if (data.message.includes('##$:')) {
      sistema = data.message.substring(4, 7);
      aux = data.message.substring(8);
      aux = aux.split('#');
      doc = aux[0];
      numDoc = aux[1];
      this.executeSystem(sistema, data, doc, numDoc);
    } else {
      this.pushMessage(data);
    }
  }

  pushMessage(data: any) {
    this.conversation.push(data);
    this.numMessages = this.conversation.length - 1;
    this.bubbleClass = 'tag tag-pill tag-danger animating';
    setTimeout(() => {
      this.bubbleClass = 'tag tag-pill tag-danger';
    }, 1000);
    for (let i = 0; i <= this.numMessages; i++) {
      this.revConversation[this.numMessages - i] = this.conversation[i];
    }
  }

  executeSystem(sistema: string, data: any, doc: string, numDoc: any) {
    switch (sistema) {
      case 'S01':
        setTimeout(() => {
          data.message = doc + ': ' + numDoc + ' - Solicitud de precio especial';
          this.pushMessage(data);
          this.pushNotify('Solicitud precio especial', doc + ': ' + numDoc);
        }, 750);
        break;
      case 'S10':
        setTimeout(() => {
          data.message = doc + ': ' + numDoc + ' - Autorización de precio especial';
          this.pushMessage(data);
          this.pushNotify('Autorización precio especial', doc + ': ' + numDoc);
        }, 750);
        break;
      case 'S11':
        data.message = doc + ': ' + numDoc + ' - Precio especial rechazado';
        this.pushMessage(data);
        this.pushNotify('Precio especial rechazado', doc + ': ' + numDoc);
        break;
      case 'NS1':
        data.message = 'Status: ' + doc + ' --- SAPid: ' + numDoc;
        this.pushMessage(data);
        this.pushNotify('Nuevo registro NeoDOC', 'Status: ' + doc + ' --- SAPid: ' + numDoc);
        if (doc === 'ERROR') {
          this.showNotification('Error', 'Nuevo registro NeoDOC: ' + 'Status: ' + doc + ' --- SAPid: ' + numDoc, false, true);
        } else {
          this.showNotification('Success', 'Nuevo registro NeoDOC: ' + 'Status: ' + doc + ' --- SAPid: ' + numDoc, true, true);
        }
        break;
      case 'WB1':
        data.message = 'Actualización ' + doc + ' --- WebService: ' + numDoc;
        this.pushMessage(data);
        this.pushNotify('Nuevo registro NeoDOC', 'Status: ' + doc + ' --- SAPid: ' + numDoc);
        this.showNotification('Success', 'Actualización Web: ' + 'WebService: ' + numDoc, true, true);
        break;
      case 'S99':
        break;
    }
    this.countAutorizaciones(this.miUsuario.id);
  }

  countAutorizaciones(uuid: string) {
    this._usuariosService.countAutorizaciones(uuid).subscribe((result) => {
      this.numAutorizaciones = result['data'].count;
    });
  }

  countSuscripciones() {
    this.numSuscripciones = Object.keys(this.misSuscripciones).length;
  }

  refreshMisFavoritos() {
    this.misFavoritos = this._authService.getMisFavoritos();
  }

  refreshMisSuscripciones() {
    this.misSuscripciones = this._authService.getMisSuscripciones();
    this.connect(this.miUsuario, this.misSuscripciones);
    this.countSuscripciones();
  }

  refreshMisSuscripcionesBackEnd() {
    this._authService.getRefreshSuscripciones(this.miUsuario.id).subscribe((resultSuscripciones) => {
      let channelsInfo = resultSuscripciones['data'];
      let _cipherObject = this._encryptionService.encrypt(channelsInfo, this.channelKey);
      sessionStorage.setItem('channels', _cipherObject);
      this.connect(this.miUsuario, channelsInfo);
      this.countSuscripciones();
    });
  }

  deleteMessage(msg: any) {
    let index = this.revConversation.findIndex((elt: any) => elt === msg);
    let revIdx = this.numMessages - index;
    this.revConversation.splice(index, 1);
    this.conversation.splice(revIdx, 1);
    this.numMessages = this.conversation.length - 1;
  }

  deleteAllMessage() {
    this.revConversation = [];
    this.conversation = [];
    this.numMessages = -1;
  }

  keypressHandler(event: any) {
    if (event.keyCode === 13) {
      this.sendMessage(this.miUsuario, 'broadcast', this.message, false, true);
      return false;
    }
  }

  click2send() {
    this.sendMessage(this.miUsuario, 'broadcast', this.message, false, true);
  }

  public toggled(open: boolean): void {
    console.log('Dropdown is now: ', open);
  }

  onLogout() {
    this.send2channel('dis user', this.miUsuario, '', 'Estoy fuera!!!', false);
    this._authService.logout();
  }

  days2deadLine() {
    let today = new Date();
    let deadLine = new Date(today.getFullYear(), 9, 1);
    if (today.getMonth() === 9 && today.getDate() > 1) {
      deadLine.setFullYear(deadLine.getFullYear() + 1);
    }
    let one_day = 1000 * 60 * 60 * 24;
    this.leftDays = Math.ceil((deadLine.getTime() - today.getTime()) / one_day) + ' dias para impacto!';
  }

  happyBirthday() {
    let today = new Date();
    if (today.getMonth() === 9 && today.getDate() === 1) {
      this.cumpleanyos = true;
    }
  }

  getServer() {
    switch (window.location.origin) {
      case APIConfig.FRONTEND_LOC:
        this.server = 'SERVIDOR LOCAL';
        this.serverMessage = true;
        break;
      default:
        this.server = '';
        this.serverMessage = false;
        break;
    }
  }

  // Novedades Version

  showNovedadesVersion() {
    if (this.miUsuario.controlVersion) {
      setTimeout(() => {
        this.openNovedadesVersion();

      }, 550);
    }
  }

  // Codigo necesario para activar el Modal Window --- NovedadesVersionModalComponent

  closeNovedadesVersion() {
    this.modalComponentNovedades.isShow = false;
    this.modalComponentNovedades.cancelChildModal();
  }

  openNovedadesVersion(): void {
    this._controlVersionService.getVersiones().subscribe((result) => {
      this.modalComponentNovedades.showChildModal(result);

    });
  }

  onNovedadesVersionClose(event: any) {
    if (event.novedadesVersion) {
      if (event.novedadesVersion[0] === 'true') {
        this._usuariosService.ocultaControlVersion(this.miUsuario.id, { controlVersion: false }).subscribe();
      }
    }
  }

  // Input: Date -> Output: MySQL Date (YYYY-mm-dd hh:ii:ss)
  toMySQLdatetime(fecha: Date) {
    let add: string;
    let resDay: string;
    let resMonth: string;
    let day = fecha.getDate().toString();
    let hours = fecha.getHours().toString();
    let minutes = fecha.getMinutes().toString();
    add = '0';
    if (day.length === 1) {
      resDay = add.concat(day);
    } else {
      resDay = day;
    }
    let numMonth = fecha.getMonth() + 1;
    let month = numMonth.toString();
    if (month.length === 1) {
      resMonth = add.concat(month);
    } else {
      resMonth = month;
    }
    let year = fecha.getFullYear();

    return year + '-' + resMonth + '-' + resDay + ' ' + hours + ':' + minutes + ':00';
  }
}
