import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, PRIMARY_OUTLET, Route } from '@angular/router';

export class FcRouteReuseStrategy implements RouteReuseStrategy {
  private static waitDelete: string;
  public static handlers: { [key: string]: DetachedRouteHandle } = {};

  public static deleteRouteSnapshot(name: string): void {
    if (FcRouteReuseStrategy.handlers[name]) {
      delete FcRouteReuseStrategy.handlers[name];
    } else {
      FcRouteReuseStrategy.waitDelete = name;
    }
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (route.routeConfig.path === 'home' || route.routeConfig.path === 'error') {
      return false;
    }
    if (route.routeConfig.data && !route.routeConfig.data.keepAlive) {
      return false;
    }
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return false;
    }
    return true;
  }

  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (route.routeConfig.path === 'signin') {
      FcRouteReuseStrategy.handlers = {};
    }
    const url = this.getRouteUrl(route);
    FcRouteReuseStrategy.handlers[url] = handle;
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route.queryParams.refresh === 'Y') {
      return false;
    }
    const url = this.getRouteUrl(route);
    return !!FcRouteReuseStrategy.handlers[url];
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig || route.queryParams.refresh === 'Y' || route.routeConfig.loadChildren) {
      return null;
    }
    const url = this.getRouteUrl(route);
    const rtn: DetachedRouteHandle = FcRouteReuseStrategy.handlers[url];
    if (rtn !== undefined) {
      return rtn;
    } else {
      return null;
    }
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig && JSON.stringify(future.params) === JSON.stringify(curr.params);
  }

  private getRouteUrl(route: ActivatedRouteSnapshot) {
    const path = route.routeConfig.path;
    return path;
  }

  getRouteUrl0(route: ActivatedRouteSnapshot): string {
    let namedOutletCount = 0;
    return (
      route.pathFromRoot.reduce((path, routeIs) => {
        const config: Route = routeIs.routeConfig;
        if (config) {
          if (config.outlet && config.outlet !== PRIMARY_OUTLET) {
            path += `(${config.outlet}:`;
            namedOutletCount++;
          } else {
            path += '/';
          }
          return (path += config.path);
        }
        return path;
      }, '') + (namedOutletCount ? new Array(namedOutletCount + 1).join(')') : '')
    );
  }
}
