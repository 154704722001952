import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usuario, SearchUsuario, Autorizacion, SearchAutorizacion } from '@usuarios/model/usuario.model';
import { APIRoutesService } from '@app/services/apiRoutes.service';
import { SearchControlHorario } from '../model/usuario.model';

@Injectable()
export class UsuariosService {
  private apiURL: string;
  public options: any;

  public comerciales: any[];
  // public grupoSAP: any[];
  public upcsTrafico: any[];
  public usuariosAduanas: any[];
  public usuariosAlmacen: any[];
  public usuariosTerrestre: any[];
  public operariosTaller: any[];
  public reclamacion: any[];
  public usuarios: any[];
  public optDelegacion: any[];
  public optEmpresa: any[];
  public optOrgVentas: any[];
  public optOrgCompras: any[];
  public optTipoAutorizacion: any[];

  constructor(private _http: HttpClient, private _APIRoutesService: APIRoutesService) {
    this.apiURL = _APIRoutesService.getApiURIusuarios();
  }

  // Servicios principales

  miUsuario() {
    return this._http.get(this.apiURL + '/miPerfil');
  }

  misSuscripciones() {
    return this._http.get(this.apiURL + '/misSuscripciones');
  }

  getUsuario(id: string) {
    return this._http.get(this.apiURL + '/' + id);
  }

  getUsuarios() {
    return this._http.get(this.apiURL + '/allUsuarios');
  }

  searchUsuarios(query: SearchUsuario) {
    let body = JSON.stringify(query);
    return this._http.post(this.apiURL + '/search', body);
  }

  addUsuario(usuario: Usuario) {
    let body = JSON.stringify(usuario);
    return this._http.post(this.apiURL + '/nuevo', body);
  }

  updateUsuario(usuario: Usuario, id: string) {
    let body = JSON.stringify(usuario);
    return this._http.post(this.apiURL + '/actualiza/' + id, body);
  }

  setNuevoPassword(uuid: string, data: any) {
    let body = JSON.stringify(data);
    return this._http.patch(this.apiURL + '/setNuevoPassword/' + uuid, body);
  }

  ocultaControlVersion(uuid: string, data: any) {
    let body = JSON.stringify(data);
    return this._http.patch(this.apiURL + '/ocultaControlVersion/' + uuid, body);
  }

  ocultaActualizaVersion(uuid: string, data: any) {
    let body = JSON.stringify(data);
    return this._http.patch(this.apiURL + '/ocultaActualizaVersion/' + uuid, body);
  }

  setGridVariantePrincipal(uuid: string, data: any) {
    let body = JSON.stringify(data);
    return this._http.patch(this.apiURL + '/setGridVariantePrincipal/' + uuid, body);
  }

  setNuevoPasswordSuper(uuid: string, data: any) {
    let body = JSON.stringify(data);
    return this._http.patch(this.apiURL + '/setNuevoPasswordSuper/' + uuid, body);
  }

  deleteUsuario(id: string) {
    return this._http.delete(this.apiURL + '/borrar/' + id);
  }

  // Favoritos

  addFavorito(data: any) {
    let body = JSON.stringify(data);
    return this._http.post(this.apiURL + '/favoritos/nuevo', body);
  }

  deleteFavorito(uuid: string) {
    return this._http.delete(this.apiURL + '/favoritos/borrar/' + uuid);
  }

  // Autorizaciones

  getAutorizaciones(reqAutorizacion: SearchAutorizacion) {
    let body = JSON.stringify(reqAutorizacion);
    return this._http.post(this.apiURL + '/autorizaciones/search', body);
  }

  getAutorizacion(uuid: string) {
    return this._http.get(this.apiURL + '/autorizaciones/searchAutorizacion?uuid=' + uuid);
  }

  addAutorizacion(data: any) {
    let body = JSON.stringify(data);
    return this._http.post(this.apiURL + '/autorizaciones/nueva', body);
  }

  updateAutorizacion(data: any) {
    let body = JSON.stringify(data);
    return this._http.post(this.apiURL + '/autorizaciones/actualiza', body);
  }

  deleteAutorizacion(uuid: string) {
    return this._http.delete(this.apiURL + '/autorizaciones/borrar/' + uuid);
  }

  updateEstadoAutorizacion(uuid: any, situacion: number) {
    let body = JSON.stringify({ situacion: situacion });
    return this._http.put(this.apiURL + '/autorizaciones/situacion/actualiza/' + uuid, body);
  }

  countAutorizaciones(uuid: string) {
    return this._http.get(this.apiURL + '/autorizaciones/countAutorizaciones/' + uuid);
  }

  // Suscripciones

  altaSuscripcion(uuid: string, usuario: any, canal: any) {
    let body = JSON.stringify({ uuid: uuid, usuario: usuario, canal: canal });
    return this._http.post(this.apiURL + '/suscripciones/nueva', body);
  }

  // Busquedas comunes

  searchUsuario(event: any) {
    let query = event.query;
    this._http.get(this.apiURL + '/searchUsuario?q=' + query).subscribe((result) => {
      this.usuarios = result['data'];
    });
  }

  usuariosDropdown(event: any) {
    this._http.get(this.apiURL + '/allUsuarios').subscribe((result) => {
      this.usuarios = result['data'];
    });
  }

  searchOperarioTaller(event: any) {
    let query = event.query;
    this._http.get(this.apiURL + '/searchOperarioTaller?q=' + query).subscribe((result) => {
      this.operariosTaller = result['data'];
    });
  }

  operariosTallerDropdown(event: any) {
    this._http.get(this.apiURL + '/allOperariosTaller').subscribe((result) => {
      this.operariosTaller = result['data'];
    });
  }

  searchUsuarioTerrestre(event: any) {
    let query = event.query;
    this._http.get(this.apiURL + '/searchUsuarioTerrestre?q=' + query).subscribe((result) => {
      this.usuariosTerrestre = result['data'];
    });
  }

  usuariosTerrestreDropdown(event: any) {
    this._http.get(this.apiURL + '/allUsuariosTerrestre').subscribe((result) => {
      this.usuariosTerrestre = result['data'];
    });
  }

  searchUsuarioAlmacen(event: any) {
    let query = event.query;
    this._http.get(this.apiURL + '/searchUsuarioAlmacen?q=' + query).subscribe((result) => {
      this.usuariosAlmacen = result['data'];
    });
  }

  usuariosAlmacenDropdown(event: any) {
    this._http.get(this.apiURL + '/allUsuariosAlmacen').subscribe((result) => {
      this.usuariosAlmacen = result['data'];
    });
  }

  searchUsuarioAduanas(event: any) {
    let query = event.query;
    this._http.get(this.apiURL + '/searchUsuarioAduanas?q=' + query).subscribe((result) => {
      this.usuariosAduanas = result['data'];
    });
  }

  usuariosAduanasDropdown(event: any) {
    this._http.get(this.apiURL + '/allUsuariosAduanas').subscribe((result) => {
      this.usuariosAduanas = result['data'];
    });
  }

  searchComercial(event: any) {
    let query = event.query;
    this._http.get(this.apiURL + '/searchComercial?q=' + query).subscribe((result) => {
      this.comerciales = result['data'];
    });
  }

  comercialesDropdown(event: any) {
    this._http.get(this.apiURL + '/allComerciales').subscribe((result) => {
      this.comerciales = result['data'];
    });
  }

  // searchgrupoSAP(event: any) {
  //   let query = event.query;
  //   this._http.get(this.apiURL + '/searchgrupoSAP?q=' + query).subscribe((result) => {
  //     this.grupoSAP = result['data'];
  //   });
  // }

  // grupoSAPDropdown(event: any) {
  //   this._http.get(this.apiURL + '/allgrupoSAP').subscribe((result) => {
  //     this.grupoSAP = result['data'];
  //   });
  // }

  searchUPCTrafico(event: any) {
    let query = event.query;
    this._http.get(this.apiURL + '/searchUPCTrafico?q=' + query).subscribe((result) => {
      this.upcsTrafico = result['data'];
    });
  }

  upcsTraficoDropdown(event: any) {
    this._http.get(this.apiURL + '/allUPCsTrafico').subscribe((result) => {
      this.upcsTrafico = result['data'];
    });
  }

  searchReclamacion(event: any) {
    let query = event.query;
    this._http.get(this.apiURL + '/searchReclamacion?q=' + query).subscribe((result) => {
      this.reclamacion = result['data'];
    });
  }

  reclamacionDropdown(event: any) {
    this._http.get(this.apiURL + '/allReclamacion').subscribe((result) => {
      this.reclamacion = result['data'];
    });
  }

  // Servicios para formularios

  getDropDownDelegacion() {
    this._http.get('assets/mocks/Usuarios/dropdownDelegacion.json').subscribe((result) => {
      this.optDelegacion = result['data'];
    });
  }

  getDropDownEmpresa() {
    this._http.get('assets/mocks/Usuarios/dropdownEmpresa.json').subscribe((result) => {
      this.optEmpresa = result['data'];
    });
  }

  // getDropDownOrgVentas() {
  //   this._http.get('assets/mocks/Maestros/orgVentasSAP.json').subscribe((result) => {
  //     this.optOrgVentas = result['data'];
  //   });
  // }

  // getDropDownOrgCompras() {
  //   this._http.get('assets/mocks/Maestros/orgComprasSAP.json').subscribe((result) => {
  //     this.optOrgCompras = result['data'];
  //   });
  // }

  getDropDownTipoAutorizacion() {
    this._http.get('assets/mocks/Usuarios/dropdownTipoAutorizacion.json').subscribe((result) => {
      this.optTipoAutorizacion = result['data'];
    });
  }

  // Funciones de Usuarios

  getPermisos(usuario: Usuario, modulo: string) {
    let valor = usuario.permisos[modulo];
    let resultado: string;

    switch (valor) {
      case 0:
        resultado = '';
        break;
      case 1:
        resultado = 'r';
        break;
      case 2:
        resultado = 'rw';
        break;
      case 3:
        resultado = 'rwx';
        break;
      case 4:
        resultado = 'rwxs';
        break;
      case 5:
        resultado = 'rwxsd';
        break;
      default:
        resultado = '';
    }
    return resultado;
  }

  getDropDownOrgVentas() {
    this._http.get('assets/mocks/Maestros/orgVentasSAP.json').subscribe((result) => {
      this.optOrgVentas = result['data'];
    });
  }

  getDropDownOrgCompras() {
    this._http.get('assets/mocks/Maestros/orgComprasSAP.json').subscribe((result) => {
      this.optOrgCompras = result['data'];
    });
  }

  // Gestión de Favoritos

  /*

  checkFavoritosURL() {
    // Comporbamos si existe la URL en los Favoritos
    let lenght = this.breadcrumbs.length;

    this.actualURL = this.breadcrumbs[lenght - 1]['url'];
    this.actualURL = this.actualURL.substring(1);
    this.relleno = 'fa fa-star-o';
    this.existeURL = false;
    this.toolTipFav = 'Añadir a favoritos';
    let index = 0;
    this.misFavoritos.forEach(element => {
      if (element.url === this.actualURL) {
        this.relleno = 'fa fa-star';
        this.existeURL = true;
        this.toolTipFav = 'Eliminar de favoritos';
        this.indexFav = index;
      }
      index++;
    });
  }

  onClickFavoritos() {
    let uuid: string;
    let miUsuario = this._authService.getMiUsuario();
    let lenght = this.breadcrumbs.length;
    let nombre = this.breadcrumbs[lenght - 2]['label'].title + ' / ' + this.breadcrumbs[lenght - 1]['label'].title;
    if (this.existeURL) {
      this.confirmationService.confirm({
        message: 'Seguro que quieres eliminar de tus favoritos?',
        accept: () => {
          this._usuariosService.deleteFavorito(this.misFavoritos[this.indexFav].uuid).subscribe(() => {
            this.misFavoritos.splice(this.indexFav, 1);
            this._authService.setMisFavoritos(this.misFavoritos);
            this._webSocketService.refreshMisFavoritos();
            this.showNotification('Success', 'Registro eliminado de tus favoritos', true, true);
            this.checkURL();
          });
        }
      });
    } else {
      uuid = this._uiComponentService.uuid();
      this.confirmationService.confirm({
        message: 'Seguro que quieres añadir a tus favoritos?',
        accept: () => {
          let data = { uuid: uuid, usuario: miUsuario.id, nombre: nombre, url: this.actualURL };
          this._usuariosService.addFavorito(data).subscribe(() => {
            let newFavorito = { uuid: uuid, nombre: nombre, url: this.actualURL };
            this.misFavoritos.push(newFavorito);
            this._authService.setMisFavoritos(this.misFavoritos);
            this._webSocketService.refreshMisFavoritos();
            this.checkURL();
            this.showNotification('Success', 'Registro añadido a tus favoritos', true, true);
          });
        }
      });
    }
  }
  */

  checkFavoritosURL(favoritos, codigo) {
    let estrella: string;
    let toolTipFav: string;
    let esFavorito: boolean;
    let fav = this.findArrayByField(favoritos, 'codigo', codigo);
    if (fav[0]) {
      estrella = 'fa fa-star';
      esFavorito = true;
      toolTipFav = 'Eliminar de favoritos';
    } else {
      estrella = 'fa fa-star-o';
      esFavorito = false;
      toolTipFav = 'Añadir a favoritos';
    }
    return { estrella: estrella, esFavorito: esFavorito, toolTip: toolTipFav, obj: fav[0] };
  }

  findArrayByField(array: any, field: any, value: any) {
    // IN->  array: Matriz inicial, field: campo por el cual vamos a filtrar, value: valor del campo filtro => OUT: Matriz resultado
    return array.filter((x: any) => x[field] === value);
  }
}
