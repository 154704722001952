/*  Identificacion de la aplicacion cliente de la API  */

export interface IAPIConfig {
  VERSION: string;
  FRONTEND_LOC: string;
  FRONTEND_WEB: string;
  BACKEND_LOC: string;
  BACKEND_WEB: string;
  NODE_WEB: string;
  API_CLIENT: string;
  APP_KEY: string;
  SESSION_KEY: string;
  COOKIE_KEY: string;
  CHANNEL_KEY: string;
  FAVORITOS_KEY: string;
}

export const APIConfig: IAPIConfig = {
  VERSION: '8.2.9/P21111',
  FRONTEND_LOC: 'http://localhost:4200/',
  FRONTEND_WEB: 'https://www.a2logistic.es/',
  BACKEND_LOC: 'https://www.a2logistic.es/wsdl.svc/public/index.php',
  BACKEND_WEB: 'https://www.a2logistic.es/wsdl.svc/public/index.php',
  NODE_WEB: 'https://node-server-appionic.herokuapp.com/',
  API_CLIENT: '480bdc58-3bba-3d18-b863-9967e76a1679',
  APP_KEY: '2i9FTtWnPJ9J3kBIOhCP2nWzYUQIvnAu6LF2Vp2A',
  SESSION_KEY: 'tosUf3mg3O3ID0WufMRc',
  COOKIE_KEY: 'MV3qx1l3SlBdhVOI3yC7',
  CHANNEL_KEY: 'vYKuwyWVkEEe7s2IYMFB',
  FAVORITOS_KEY: '1l3SlBdyW3kBIOhCPsUf3mg',
};
