export const appsMenu = {
  APPS_MENU: [
    {
      ID: 'APP',
      TABID: 'HOME',
      TABNAME: 'Dashboard',
      HASCHILD: 'Y',
      TABICON: 'icon-speedometer',
      ROUTER: '/dashboard',
      P_CHILDMENUS: [
        //CRM
        {
          ID: 'M0',
          TABID: 'M0HO',
          TABNAME: 'CRM',
          HASCHILD: 'Y',
          TABICON: 'icon-people',
          ROUTER: '/crm',
          P_CHILDMENUS: [
            {
              ID: 'M0-1',
              TABID: 'OFCL',
              TABNAME: 'agenda',
              HASCHILD: 'N',
              TABICON: 'icon-notebook',
              ROUTER: '/crm/agenda/main',
            },
            {
              ID: 'M0-2',
              TABID: 'OLCL',
              TABNAME: 'cotizaciones',
              HASCHILD: 'N',
              TABICON: 'fa fa-address-book-o',
              ROUTER: '/crm/cotizaciones/buscar',
            },
            {
              ID: 'M0-3',
              TABID: 'GEST',
              TABNAME: 'oportunidades',
              HASCHILD: 'N',
              TABICON: 'icon-docs',
              ROUTER: '/crm',
            },
            {
              ID: 'M0-4',
              TABID: 'TARF',
              TABNAME: 'Tarifas',
              HASCHILD: 'Y',
              TABICON: 'icon-star',
              ROUTER: '/comercial/home2',
              P_CHILDMENUS: [
                {
                  ID: 'M0-4-1',
                  TABID: 'TARV',
                  TABNAME: 'Ventas',
                  HASCHILD: 'N',
                  TABICON: 'icon-star',
                  ROUTER: '/comercial/tarifaVentas/buscar',
                },
                {
                  ID: 'M0-4-2',
                  TABID: 'TARP',
                  TABNAME: 'Proveedor',
                  HASCHILD: 'N',
                  TABICON: 'fa fa-wrench',
                  ROUTER: '/comercial/tarifaProveedor/buscar',
                },
                {
                  ID: 'M0-4-3',
                  TABID: 'TARG',
                  TABNAME: 'Generales',
                  HASCHILD: 'N',
                  TABICON: 'fa fa-life-ring',
                  ROUTER: '/comercial/tarifaGeneral/buscar',
                },
              ],
            },
          ],
        },
        //Tráfico
        {
          ID: 'M3',
          TABID: 'M3HO',
          TABNAME: 'Tráfico',
          HASCHILD: 'Y',
          TABICON: 'fa fa-bus',
          ROUTER: '/trafico',
          P_CHILDMENUS: [
            {
              ID: 'M3-1',
              TABID: 'TORD',
              TABNAME: 'Export',
              HASCHILD: 'N',
              TABICON: 'fa fa-wpforms',
              ROUTER: '/trafico/export/buscar',
            },
            {
              ID: 'M3-2',
              TABID: 'TREC',
              TABNAME: 'Import',
              HASCHILD: 'N',
              TABICON: 'icon-clock',
              ROUTER: '/trafico/import/buscar',
            },
            {
              ID: 'M3-3',
              TABID: 'TENT',
              TABNAME: 'X-Trail',
              HASCHILD: 'N',
              TABICON: 'fa fa-truck',
              ROUTER: '/trafico/xtrail/buscar',
            },
            {
              ID: 'M3-4',
              TABID: 'TTRA',
              TABNAME: 'Local',
              HASCHILD: 'N',
              TABICON: 'fa fa-fast-forward',
              ROUTER: '/trafico/local/buscar',
            },
          ],
        },
        // Taller
        {
          ID: 'M6',
          TABID: 'M6HO',
          TABNAME: 'Taller',
          HASCHILD: 'Y',
          TABICON: 'icon-wrench',
          ROUTER: '/taller',
          P_CHILDMENUS: [
            {
              ID: 'M6-1',
              TABID: 'TPAR',
              TABNAME: 'Partes taller',
              HASCHILD: 'N',
              TABICON: 'icon-book-open',
              ROUTER: '/taller/partesTaller/buscar',
            },
            {
              ID: 'M6-2',
              TABID: 'TPRE',
              TABNAME: 'Presupuestos',
              HASCHILD: 'N',
              TABICON: 'icon-doc',
              ROUTER: '/taller/presupuestos/buscar',
            },
            {
              ID: 'M6-3',
              TABID: 'TPLA',
              TABNAME: 'Planificación',
              HASCHILD: 'N',
              TABICON: 'icon-clock',
              ROUTER: '/taller',
            },
            {
              ID: 'M6-5',
              TABID: 'TGM',
              TABNAME: 'Materiales',
              HASCHILD: 'Y',
              TABICON: 'fa fa-dropbox',
              ROUTER: '/taller',
              P_CHILDMENUS: [
                {
                  ID: 'M6-5-1',
                  TABID: 'TPM',
                  TABNAME: 'Pedidos Compra',
                  HASCHILD: 'N',
                  TABICON: 'fa fa-th-list',
                  ROUTER: '/taller/pedidosMateriales/buscar',
                },
                {
                  ID: 'M6-5-2',
                  TABID: 'TGA',
                  TABNAME: 'Gestión Albaranes',
                  HASCHILD: 'N',
                  TABICON: 'fa fa-columns',
                  ROUTER: '/taller/gestionAlbaranes/buscar',
                },
                {
                  ID: 'M6-5-3',
                  TABID: 'TCS',
                  TABNAME: 'Control Stock',
                  HASCHILD: 'N',
                  TABICON: 'fa fa-exchange',
                  ROUTER: '/taller/controlStock/buscar',
                },
                {
                  ID: 'M6-5-4',
                  TABID: 'TCMS',
                  TABNAME: 'Maestro Materiales',
                  HASCHILD: 'N',
                  TABICON: 'fa fa-sort-amount-desc',
                  ROUTER: '/taller/maestroMateriales/buscar',
                },
              ],
            },
          ],
        },
        // Facturacion
        {
          ID: 'M7',
          TABID: 'M7HO',
          TABNAME: 'Facturación',
          HASCHILD: 'Y',
          TABICON: 'icon-calculator',
          ROUTER: '/facturacion',
          P_CHILDMENUS: [
            {
              ID: 'M7-5',
              TABID: 'FTAP',
              TABNAME: 'Taller',
              HASCHILD: 'Y',
              TABICON: 'icon-wrench',
              ROUTER: '/facturacion',
              P_CHILDMENUS: [
                {
                  ID: 'M7-5-1',
                  TABID: 'FTPT',
                  TABNAME: 'Partes Taller',
                  HASCHILD: 'N',
                  TABICON: 'icon-calculator',
                  ROUTER: '/facturacion/taller/partes',
                },
                {
                  ID: 'M7-5-2',
                  TABID: 'FTPI',
                  TABNAME: 'Ingresos Taller',
                  HASCHILD: 'N',
                  TABICON: 'fa fa-check-square-o',
                  ROUTER: '/facturacion/taller/ingresos',
                },
              ],
            },
            {
              ID: 'M7-6',
              TABID: 'FACT',
              TABNAME: 'Facturas',
              HASCHILD: 'N',
              TABICON: 'icon-docs',
              ROUTER: '/facturacion/facturas/buscar',
            },
          ],
        },
        // Visados
        {
          ID: 'M8',
          TABID: 'M8HO',
          TABNAME: 'Visados',
          HASCHILD: 'Y',
          TABICON: 'fa fa-shopping-cart',
          ROUTER: '/visados',
          P_CHILDMENUS: [
            {
              ID: 'M8-4',
              TABID: 'VMEX',
              TABNAME: 'Taller',
              HASCHILD: 'Y',
              TABICON: 'icon-wrench',
              ROUTER: '/multimodal',
              P_CHILDMENUS: [
                {
                  ID: 'M8-2-1',
                  TABID: 'VMEP',
                  TABNAME: 'Previsiones',
                  HASCHILD: 'N',
                  TABICON: 'fa fa-file-text-o',
                  ROUTER: '/visados/multimodal/previsiones',
                },
              ],
            },
            {
              ID: 'M8-5',
              TABID: 'VISA',
              TABNAME: 'Facturas',
              HASCHILD: 'N',
              TABICON: 'icon-docs',
              ROUTER: '/visados/facturas/buscar',
            },
            {
              ID: 'M8-6',
              TABID: 'VPRE',
              TABNAME: 'Preregistro',
              HASCHILD: 'N',
              TABICON: 'fa fa-file-o',
              ROUTER: '/visados/preregistro/buscar',
            },
          ],
        },
      ],
    },
  ],
};

export const extrasMenu = {
  EXTRAS_MENU: [
    {
      ID: 'APP',
      TABID: 'HOME',
      TABNAME: 'Dashboard',
      HASCHILD: 'Y',
      TABICON: 'icon-speedometer',
      ROUTER: '/dashboard',
      P_CHILDMENUS: [
        //Calidad
        {
          ID: 'E1',
          TABID: 'E1HO',
          TABNAME: 'Calidad',
          HASCHILD: 'Y',
          TABICON: 'icon-emotsmile',
          ROUTER: '/extras/calidad',
          P_CHILDMENUS: [
            {
              ID: 'E1-1',
              TABID: 'CISO',
              TABNAME: 'ISO 9001',
              HASCHILD: 'N',
              TABICON: 'icon-user',
              ROUTER: '/extras/calidad',
            },
            {
              ID: 'E1-2',
              TABID: 'CPRO',
              TABNAME: 'Procedimientos',
              HASCHILD: 'N',
              TABICON: 'icon-map',
              ROUTER: '/extras/calidad',
            },
            {
              ID: 'E1-3',
              TABID: 'CMAN',
              TABNAME: 'Manuales técnicos',
              HASCHILD: 'N',
              TABICON: 'icon-notebook',
              ROUTER: '/extras/calidad',
            },
          ],
        },
        //Maestros
        {
          ID: 'E3',
          TABID: 'E3HO',
          TABNAME: 'Maestros',
          HASCHILD: 'Y',
          TABICON: 'fa fa-cogs',
          ROUTER: '/maestros',
          P_CHILDMENUS: [
            {
              ID: 'E3-1',
              TABID: 'MENT',
              TABNAME: 'Entidades',
              HASCHILD: 'N',
              TABICON: 'fa fa-users',
              ROUTER: '/maestros/entidades/buscar',
            },
            {
              ID: 'E3-2',
              TABID: 'MLUG',
              TABNAME: 'Lugares',
              HASCHILD: 'N',
              TABICON: 'fa fa-map-marker',
              ROUTER: '/maestros/lugares/buscar',
            },
            {
              ID: 'E3-3',
              TABID: 'CONT',
              TABNAME: 'Contenedores',
              HASCHILD: 'Y',
              TABICON: 'fa fa-th',
              ROUTER: '/maestros/',
              P_CHILDMENUS: [
                {
                  ID: 'E3-3-1',
                  TABID: 'MCNT',
                  TABNAME: 'Contenedores',
                  HASCHILD: 'N',
                  TABICON: 'fa fa-th',
                  ROUTER: '/maestros/contenedores/buscar',
                },
                {
                  ID: 'E3-3-2',
                  TABID: 'MCCT',
                  TABNAME: 'Clases Contenedores',
                  HASCHILD: 'N',
                  TABICON: 'fa fa-file-o',
                  ROUTER: '/maestros/clasesContenedores/buscar',
                },
                {
                  ID: 'E3-3-3',
                  TABID: 'MTCT',
                  TABNAME: 'Tipos contenedores',
                  HASCHILD: 'N',
                  TABICON: 'fa fa-file-o',
                  ROUTER: '/maestros/tiposContenedores/buscar',
                },
              ],
            },
            {
              ID: 'E3-4',
              TABID: 'MBUQ',
              TABNAME: 'Buques',
              HASCHILD: 'N',
              TABICON: 'fa fa-ship',
              ROUTER: '/maestros/buques/buscar',
            },
          ],
        },
        //OTROS: Facturas SII, Neodoc, RRHH, Incidencias, Documentos)
        {
          ID: 'E4',
          TABID: 'E4HO',
          TABNAME: 'Otros',
          HASCHILD: 'Y',
          TABICON: 'fa fa-wpforms',
          ROUTER: '/otros',
          P_CHILDMENUS: [
            {
              ID: 'E4-3',
              TABID: 'INCI',
              TABNAME: 'Incidencias',
              HASCHILD: 'N',
              TABICON: 'fa fa-exclamation-triangle',
              ROUTER: '/extras/incidencias/buscar',
            },
            {
              ID: 'E4-4',
              TABID: 'DOCS',
              TABNAME: 'Documentos',
              HASCHILD: 'N',
              TABICON: 'fa fa-file-text',
              ROUTER: '/extras/documentos/buscar',
            },
            {
              ID: 'E4-5',
              TABID: 'INVEN',
              TABNAME: 'Inventarios',
              HASCHILD: 'N',
              TABICON: 'fa fa-file-text',
              ROUTER: '/extras',
            },
          ],
        },
      ],
    },
  ],
};
export const sistemaMenu = {
  SISTEMA_MENU: [
    {
      ID: 'APP',
      TABID: 'HOME',
      TABNAME: 'Dashboard',
      HASCHILD: 'Y',
      TABICON: 'icon-speedometer',
      ROUTER: '/dashboard',
      P_CHILDMENUS: [
        {
          ID: 'S0',
          TABID: 'S0HO',
          TABNAME: 'Mi usuario',
          HASCHILD: 'N',
          TABICON: 'fa fa-user',
          ROUTER: '/usuarios/miUsuario',
        },
      ],
    },
  ],
};

export const usuariosMenu = {
  USER_MENU: [
    {
      ID: 'APP',
      TABID: 'HOME',
      TABNAME: 'Dashboard',
      HASCHILD: 'Y',
      TABICON: 'icon-speedometer',
      ROUTER: '/dashboard',
      P_CHILDMENUS: [
        //Usuarios
        {
          ID: 'US',
          TABID: 'USHO',
          TABNAME: 'Usuarios',
          HASCHILD: 'Y',
          TABICON: 'icon-people',
          ROUTER: '/usuarios',
          P_CHILDMENUS: [
            {
              ID: 'U0',
              TABID: 'U0HO',
              TABNAME: 'Usuarios',
              HASCHILD: 'N',
              TABICON: 'fa fa-user',
              ROUTER: '/usuarios/buscar',
            },
            {
              ID: 'U1',
              TABID: 'U1HO',
              TABNAME: 'Autorizaciones',
              HASCHILD: 'N',
              TABICON: 'fa fa-user',
              ROUTER: '/usuarios/autorizaciones/buscar',
            },
          ],
        },
      ],
    },
  ],
};
