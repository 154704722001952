import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { AuthService } from '@auth/services/auth.service';

import { EncryptionService } from '@comun/services/encryption.service';
import { APIConfig } from '@app/models/global';

@Injectable()
export class LoggedInGuard implements CanActivate {
  private cookieKey: string;
  private isCookie: boolean;
  private isSession: boolean;

  constructor(private _router: Router, private _authService: AuthService, private _encryptionService: EncryptionService) {
    this.cookieKey = APIConfig.COOKIE_KEY;
  }

  canActivate() {
    this.isCookie = !!sessionStorage.getItem('cookie');
    this.isSession = !!sessionStorage.getItem('session');
    if (this.isSession && this.isCookie) {
      let cookieInfo = sessionStorage.getItem('cookie');
      let decryptObject = this._encryptionService.decrypt(cookieInfo, this.cookieKey);
      let autorizado = decryptObject.permisos.autorizado;
      if (autorizado !== 0) {
        return true;
      } else {
        this._authService.logout();
        return false;
      }
    } else {
      this._authService.logout();
      return false;
    }
  }
}
