import { Directive, HostListener } from '@angular/core';

/**
 * Allows the aside to be toggled via click.
 */
@Directive({
  selector: '.menubar-toggle',
})
export class MenubarToggleDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-nav');
  }
}
