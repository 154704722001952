import { Routes, RouterModule } from '@angular/router';

// Componentes modulo CoreUI
import {
  DashboardComponent,
  A2LogisticComponent,
  //CalendarioComponent,
  AyudaComponent,
} from '@coreUI/components';

// Componentes modulo Auth
import { LoginComponent, NuevoUsuarioComponent, ResetClaveComponent, ErrorAccesoComponent } from '@auth/components';
import { LoggedInGuard } from '@auth/guards';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'nuevoUsuario', component: NuevoUsuarioComponent, data: { title: 'Nuevo Usuario' } },
  { path: 'resetClave', component: ResetClaveComponent, data: { title: 'Olvidaste contrasena' } },
  // Rutas contraladas por LogIn
  {
    path: '',
    component: A2LogisticComponent,
    canActivate: [LoggedInGuard],
    data: {
      title: 'Home',
    },
    children: [
      { path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard' } },
      { path: 'errorAcceso', component: ErrorAccesoComponent, data: { title: 'Acceso restringido' } },
      //{ path: 'calendario', component: CalendarioComponent, data: { title: 'Calendario' } },
      { path: 'ayuda', component: AyudaComponent, data: { title: 'Ayuda' } },
      // Rutas de los módulos de la App (Lazy Loading)
      // { path: 'usuarios', data: { title: 'Usuarios' }, loadChildren: '../modulos/Usuarios/usuarios.module#UsuariosModule' },
      { path: 'taller', data: { title: 'Taller' }, loadChildren: () => import('../modulos/Taller/taller.module').then((m) => m.TallerModule) },
      { path: 'maestros', data: { title: 'Maestros' }, loadChildren: () => import('../modulos/Maestros/maestros.module').then((m) => m.MaestrosModule) },
      { path: 'usuarios', data: { title: 'Usuarios' }, loadChildren: () => import('../modulos/Usuarios/usuarios.module').then((m) => m.UsuariosModule) }
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

export const routing = RouterModule.forRoot(appRoutes);
