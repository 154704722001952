// Modulos
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor, HTTPStatus } from '@auth/services/token.interceptor';

//Translation
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { RouteReuseStrategy } from '@angular/router';
import { FcRouteReuseStrategy } from '@app/services/routereusestrategy.service';

const RxJS_Services = [TokenInterceptor, HTTPStatus];

// Config router
import { routing } from '@app/app.routing';

// Componentes principales App
import { AppComponent } from '@app/components/app/app.component';
import { APIRoutesService } from '@app/services/apiRoutes.service';

// Modulos de la App
import { CoreUIModule } from '@coreUI/coreui.module';
import { AuthModule } from '@auth/auth.module';

// Modulo comun UI
import { ComunModule } from '@comun/comun.module';

// Servicios comunes de la App
import { AuthService } from '@auth/services/auth.service';
import { PushNotificationsService } from '@auth/services/pushNotifications.service';
import { CustomErrorHandler } from '@auth/services/customErrorHandler.service';
import { DebugLogService } from '@comun/services/debugLog.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ComunModule,
    routing,
    AuthModule,
    CoreUIModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [AppComponent],
  providers: [APIRoutesService, AuthService, PushNotificationsService, DebugLogService, RxJS_Services, { provide: RouteReuseStrategy, useClass: FcRouteReuseStrategy }, { provide: ErrorHandler, useClass: CustomErrorHandler }, { provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
