import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APIRoutesService } from '@app/services/apiRoutes.service';

@Injectable()
export class ControlVersionService {
  private apiURL: string;
  public tiposVersion: any[];

  constructor(private _http: HttpClient, private _APIRoutesService: APIRoutesService) {
    this.apiURL = _APIRoutesService.getApiURIControlVersion();
  }

  // Servicios para Control de Versiones

  getVersiones() {
    return this._http.get(this.apiURL + '/getVersiones');
  }

  addInfoVersion(data: any) {
    let body = JSON.stringify(data);
    return this._http.post(this.apiURL + '/addInfoVersion', body);
  }

  getDropDownTiposVersion() {
    this._http.get('assets/mocks/Comun/dropdownTiposVersion.json').subscribe((result) => {
      this.tiposVersion = result['data'];
    });
  }
}
