import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';

// Componentes NG-ZORRO

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';

// Componentes de Ag Grid
import { AgGridModule } from 'ag-grid-angular';

// Componentes de Notification Bar

import { NotificationBarModule } from '@comun/UI/angular2-notification-bar';

// Ngx-Bootstrap

import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';

// Componentes del PrimeNG
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { SidebarModule } from 'primeng/sidebar';
import { ToolbarModule } from 'primeng/toolbar';
import { MenuModule } from 'primeng/menu';
import { RatingModule } from 'primeng/rating';
import { FieldsetModule } from 'primeng/fieldset';
import { SpinnerModule } from 'primeng/spinner';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
import { StepsModule } from 'primeng/steps';
import { BlockUIModule } from 'primeng/blockui';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PickListModule } from 'primeng/picklist';
import { PasswordModule } from 'primeng/password';
import { LightboxModule } from 'primeng/lightbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ChartModule } from 'primeng/chart';
import { ConfirmationService } from 'primeng/api';
import { TreeTableModule } from 'primeng/treetable';

//import { SignaturePadModule } from 'angular2-signature-pad';

import { LoadingComponent } from '@comun/components/loading/loading.component';
import { LoginSpinnerComponent } from '@comun/components/loginSpinner/login.spinner.component';
import { GoogleMapsComponent } from '@comun/components/googleMaps/google-maps.component';
import { GoogleMapsModalComponent } from '@comun/modal/GoogleMaps/google-maps.modal.component';
import { LugaresGmapsModalComponent } from '@comun/modal/GoogleMaps/lugaresGmaps.modal.component';
import { ScrollTopComponent } from '@comun/components/scrollTop/scrollTop.component';

// // Importaciones de Modals compratidos entre módulos
// import { DetalleMercanciaModalComponent } from '@comun/modal/Maestros/detalleMercancia/detalleMercancia.modal.component';
// import { DetalleMercanciaLogisticaModalComponent } from '@comun/modal/Maestros/detalleMercanciaLogistica/detalleMercanciaLogistica.modal.component';
import { FichaContenedorModalComponent } from '@comun/modal/Maestros/fichaContenedor/fichaContenedor.modal.component';
import { DetalleLugarModalComponent } from '@comun/modal/Maestros/Lugares/detalleLugar.modal.component';
// import { ContactoEntidadModalComponent } from '@comun/modal/Maestros/Entidades/contactoEntidad.modal.component';
 import { NovedadesVersionModalComponent } from '@comun/modal/novedadesVersion/novedadesVersion.modal.component';
 import { ConceptoFacturacionModalComponent } from '@comun/modal/Facturacion/conceptoFacturacion/conceptoFacturacion.modal.component';

// import { SoporteModalComponent } from '@comun/modal/Soporte/soporte.modal.component';

// Directiva formato de números
import { DecimalFormatPipe } from '@comun/pipes/decimalFormat.pipe';
import { IntegerFormatPipe } from '@comun/pipes/integerFormat.pipe';
import { EuroFormatPipe } from '@comun/pipes/euroFormat.pipe';
import { DecimalFormatDirective } from '@comun/directives/decimalFormat.directive';
import { IntegerFormatDirective } from '@comun/directives/integerFormat.directive';
import { EqualValidator } from '@comun/directives/equal-validator.directive';
import { DirectionsMapDirective } from '@comun/directives/google-map.directive';
import { CapitalizePipe } from '@comun/pipes/capitalize.pipe';
import { TimeAgoPipe } from '@comun/pipes/timeAgo.pipe';
import { ReversePipe } from '@comun/pipes/reverseFor.pipe';
import { DisableControlDirective } from '@comun/directives/disableControl.directive';
import { OpenLinkInNewWindowDirective } from '@comun/directives/olinw.directive';

// Servicios comunes
import { EncryptionService } from '@comun/services/encryption.service';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    // LoadingSAPComponent,
    LoadingComponent,
    LoginSpinnerComponent,
    GoogleMapsComponent,
    ScrollTopComponent,
    GoogleMapsModalComponent,
    LugaresGmapsModalComponent,
    //Modals compartidos
    // DetalleMercanciaModalComponent,
    // DetalleMercanciaLogisticaModalComponent,
    FichaContenedorModalComponent,
    DetalleLugarModalComponent,
    // ContactoEntidadModalComponent,
    NovedadesVersionModalComponent,
    ConceptoFacturacionModalComponent,
    // SoporteModalComponent,
    // Pipes Varias
    DecimalFormatPipe,
    IntegerFormatPipe,
    EuroFormatPipe,
    DecimalFormatDirective,
    IntegerFormatDirective,
    DirectionsMapDirective,
    EqualValidator,
    CapitalizePipe,
    TimeAgoPipe,
    ReversePipe,
    DisableControlDirective,
    OpenLinkInNewWindowDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzProgressModule,
    NzMenuModule,
    NzLayoutModule,
    NzDividerModule,
    NzTabsModule,
    NzIconModule,
    AccordionModule,
    TabViewModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    CheckboxModule,
    SidebarModule,
    ToolbarModule,
    SpinnerModule,
    MenuModule,
    RatingModule,
    DropdownModule,
    FieldsetModule,
    DialogModule,
    SelectButtonModule,
    CalendarModule,
    TooltipModule,
    ToggleButtonModule,
    TreeModule,
    FileUploadModule,
    MultiSelectModule,
    ListboxModule,
    PasswordModule,
    StepsModule,
    LightboxModule,
    ScrollPanelModule,
    ChartModule,
    PickListModule,
    BlockUIModule,
    TreeTableModule,
    //SignaturePadModule,
    NotificationBarModule,
    AgGridModule.withComponents([]),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAkD6lF7mAwV2WIbhJ_I7QkJB4k9-SsmRI',
      libraries: ['places'],
    }),
    TranslateModule,
  ],
  providers: [ConfirmationService, EncryptionService, DecimalFormatPipe, IntegerFormatPipe],
  exports: [
    // LoadingSAPComponent,
    LoadingComponent,
    LoginSpinnerComponent,
    GoogleMapsComponent,
    ScrollTopComponent,
    GoogleMapsModalComponent,
    LugaresGmapsModalComponent,
    // //Modals compartidos
    // DetalleMercanciaModalComponent,
    // DetalleMercanciaLogisticaModalComponent,
    FichaContenedorModalComponent,
    DetalleLugarModalComponent,
    // ContactoEntidadModalComponent,
    NovedadesVersionModalComponent,
    ConceptoFacturacionModalComponent,
    //IncidenciasMaritimoModalComponent,
    //IncidenciasAlmacenModalComponent,
    // SoporteModalComponent,
    //PrimeNG
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzProgressModule,
    NzMenuModule,
    NzLayoutModule,
    NzDividerModule,
    NzTabsModule,
    NzIconModule,
    AccordionModule,
    TabViewModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    CheckboxModule,
    SidebarModule,
    ToolbarModule,
    SpinnerModule,
    MenuModule,
    RatingModule,
    DropdownModule,
    FieldsetModule,
    DialogModule,
    SelectButtonModule,
    CalendarModule,
    TooltipModule,
    ToggleButtonModule,
    TreeModule,
    FileUploadModule,
    MultiSelectModule,
    ListboxModule,
    PasswordModule,
    StepsModule,
    LightboxModule,
    ScrollPanelModule,
    ChartModule,
    PickListModule,
    BlockUIModule,
    TreeTableModule,
    //SignaturePadModule,
    NotificationBarModule,
    AgGridModule,
    ModalModule,
    TabsModule,
    CollapseModule,
    BsDropdownModule,
    // Pipes Varias
    DecimalFormatPipe,
    IntegerFormatPipe,
    EuroFormatPipe,
    DecimalFormatDirective,
    IntegerFormatDirective,
    DirectionsMapDirective,
    EqualValidator,
    CapitalizePipe,
    TimeAgoPipe,
    ReversePipe,
    DisableControlDirective,
    OpenLinkInNewWindowDirective,
    TranslateModule,
  ],
})
export class ComunModule {}
