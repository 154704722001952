import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { NuevoUsuario } from '@usuarios/model/usuario.model';
import { APIRoutesService } from '@app/services/apiRoutes.service';

@Injectable()
export class NuevoUsuarioService {
  private apiURL: string;
  private apiURLResetClave: string;

  constructor(private _http: HttpClient, private _APIRoutesService: APIRoutesService) {
    this.apiURL = _APIRoutesService.getApiURInuevoUsuario();
    this.apiURLResetClave = _APIRoutesService.getApiURIresetClave();
  }

  getDelegaciones() {
    return this._http.get('assets/mocks/Auth/delegaciones.json');
  }

  getEmpresas() {
    return this._http.get('assets/mocks/Auth/empresas.json');
  }

  addNuevoUsuario(usuario: NuevoUsuario) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    //let options = new HttpParams().set('headers': headers);
    let body = JSON.stringify(usuario);

    return this._http.post(this.apiURL, body, { headers: headers });
  }

  resetClave(email: string) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    //let options = new RequestOptions({ headers: headers });
    let result = {
      email: email,
    };
    let body = JSON.stringify(result);
    return this._http.post(this.apiURLResetClave, body, { headers: headers });
  }
}
