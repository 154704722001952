<notification-bar></notification-bar>
<section class="container">
    <section class="login-form">
        <form #formNuevoUsuario="ngForm" (ngSubmit)="confirm()" role="login" novalidate>

            <img src="./assets/images/logoJSV.png" id="logo" class="img-responsive img-logo" alt="" />
            <p>
                <input type="text" name="email" #email="ngModel" [(ngModel)]="envioemail" placeholder="Correo electr&oacute;nico"  id="email" required pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$" class="form-control input-lg" />
                <span *ngIf="!email.valid && email.touched" class="DisplayBlock alert alert-danger"><img class="img-error-min" src="./assets/images/icons/stop.png">El correo electr&oacute;nico es obligatorio con formato <i>usuario@dominio.com</i></span>
            </p>

            <button class="btn btn-lg btn-primary btn-block" [disabled]="!formNuevoUsuario.form.valid"><i class="fa fa-sign-in fa fa-fw"></i>Enviar</button>
            <div>
                <div style="float: right; width: 120px; text-align: right;"><a href="" [routerLink]="['/login']">Volver</a></div>
            </div>
        </form>
    </section>
</section>
<p-confirmDialog header="Confirmacion registro" icon="fa fa-info-circle" width="425" #cd>
    <footer>
        <button type="button" pButton icon="fa fa-check" label="Aceptar" (click)="cd.accept()"></button>
    </footer>
</p-confirmDialog>
