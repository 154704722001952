<div class="container animated fadeIn">
<div class="ui-g" style="max-width: 900px; margin: 0 auto; margin-top: 20px;">
    <div class="ui-g-12">
        <div class="row text-center">
            <div class="col-md-6 col-sm-6">
                <div class="thumbnail">
                            <span class="fa fa-stack fa fa-5x">
                                <i class="fa fa-square fa fa-stack-2x text-primary"></i>
                                <i class="fa fa-html5 fa fa-stack-1x fa fa-inverse"></i>
                            </span>
                    <div class="caption">
                        <h3>ASP.NET Core</h3>
                        <p>ASP.NET Core is a significant redesign of ASP.NET.</p>
                        <p>
                            <a class="btn btn-primary" routerLinkActive="active" >Ir..</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="thumbnail">
                            <span class="fa fa-stack fa fa-5x">
                                <i class="fa fa-square fa fa-stack-2x text-primary"></i>
                                <i class="fa fa-database fa fa-stack-1x fa fa-inverse"></i>
                            </span>
                    <div class="caption">
                        <h3>EF Core</h3>
                        <p>A cross-platform version of Entity Framework.</p>
                        <p>
                            <a href="https://docs.efproject.net/en/latest/" target="_blank" class="btn btn-primary">More..</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-md-6 col-sm-6">
                <div class="thumbnail">
                            <span class="fa fa-stack fa fa-5x">
                                <i class="fa fa-square fa fa-stack-2x text-primary"></i>
                                <i class="fa fa-code fa fa-stack-1x fa fa-inverse"></i>
                            </span>
                    <div class="caption">
                        <h3>Angular</h3>
                        <p>Angular is a platform for building mobile web apps.</p>
                        <p>
                            <a href="https://angular.io/" target="_blank" class="btn btn-primary">More..</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="thumbnail">
                            <span class="fa fa-stack fa fa-5x">
                                <i class="fa fa-square fa fa-stack-2x text-primary"></i>
                                <i class="fa fa-terminal fa fa-stack-1x fa fa-inverse"></i>
                            </span>
                    <div class="caption">
                        <h3>TypeScript</h3>
                        <p>A free and open source programming language.</p>
                        <p>
                            <a href="https://www.typescriptlang.org/" target="_blank" class="btn btn-primary">More..</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
