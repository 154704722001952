import { StateGrid } from '@usuarios/model/columnsGrid';

export class Contenedor {
  constructor(public contenedor: any, public claseContenedor: ClaseContenedor, public tipoContenedor: TipoContenedor, public propietario: any, public operador: any, public propio: boolean, public estado: any, public bloqueado: boolean, public alquilado: boolean, public sitFinanciera: any, public numFinanciera: string, public fechaIniFinanciera: any, public fechaFinFinanciera: any, public fechaFabricacion: any, public valorCompra: any, public idAmortizacion: string, public valorGReparacion: any, public observaciones: string) {}
}

export class ClaseContenedor {
  constructor(public uuid: string, public id: any, public nombre: string, public alias: string, public teus: number, public taraT3: number, public categoria: any, public familia: any, public importe: any, public recargo: any, public usoPrincipal: any, public usoAlternativo: any, public tipos: TipoContenedor[], public imagenes: any[], public urlVideo: string) {}
}

export class TipoContenedor {
  constructor(public uuid: string, public id: any, public claseContenedor: any, public nombre: string, public alias: string, public iso: string, public tara: number, public pesoMax: number, public largoInt: number, public anchoInt: number, public altoInt: number, public largoExt: number, public anchoExt: number, public altoExt: number, public m3: number, public pallets: number, public refrigerado: boolean, public ventilado: boolean, public chatarra: boolean, public estanco: boolean, public reforzado: boolean, public observaciones: string) {}
}

export class SearchContenedor {
  constructor(public contenedor: string, public claseContenedor: ClaseContenedor, public tipoContenedor: TipoContenedor, public propietario: any, public operador: any, public propio: boolean, public estado: any, public resPerPage: any, public page: number, public filter: any, public numFilterRows: number, public gridState: StateGrid) {}
}

export class SearchClaseContenedor {
  constructor(public tipoContenedor: TipoContenedor, public categoria: any, public familia: any, public usoPrincipal: any, public usoAlternativo: any, public filter: any, public numFilterRows: number, public gridState: StateGrid) {}
}

export class SearchTipoContenedor {
  constructor(public filter: any, public numFilterRows: number, public gridState: StateGrid) {}
}
