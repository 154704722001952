import { Pipe, PipeTransform } from '@angular/core';

const PADDING = '000000';

@Pipe({ name: 'integerFormat' })
export class IntegerFormatPipe implements PipeTransform {
  private DECIMAL_SEPARATOR: string;
  private DECIMAL_DECORATOR: string;
  private THOUSANDS_DECORATOR: string;

  constructor() {
    // TODO comes from configuration settings
    this.DECIMAL_SEPARATOR = '.';

    this.DECIMAL_DECORATOR = ',';
    this.THOUSANDS_DECORATOR = '.';
  }

  transform(value: number | string, fractionSize = 2): string {
    if (value !== 0) {
      let [integer, fraction = ''] = (value || '').toString().split(this.DECIMAL_SEPARATOR);

      fraction = fractionSize > 0 ? this.DECIMAL_DECORATOR + (fraction + PADDING).substring(0, fractionSize) : '';

      integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_DECORATOR);
      return integer;
    } else {
      return String(value);
    }
  }

  parse(value: string, fractionSize = 2): string {
    let [integer, fraction = ''] = (value || '').split(this.DECIMAL_DECORATOR);

    integer = integer.replace(this.THOUSANDS_DECORATOR, '');

    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0 ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize) : '';

    return integer;
  }
}
