<div bsModal #novedadesVersionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myLargeModalLabel" aria-hidden="true" (onShow)="handler('onShow',$event)"
  (onHidden)="handler('onHidden',$event)">
  <div class="modal-dialog custom-modal">
    <div class="modal-content">
      <div class="modal-header" style="padding-bottom: 0px;">
        <div class="col-lg-10">
          <div class="col-lg-8">
            <div class="form-group">
              <h4 class="modal-title">
                <i class="fa icon-social-dropbox"></i> &nbsp; Control de versiones </h4>
            </div>
          </div>
        </div>
        <div class="ui-toolbar-group-right" style="margin-bottom: 10px;" *ngIf="miUsuario.sadmin">
          <button pButton type="text" label="Añadir" (click)="procesoInfoVersion(true, 'init')"
            icon="fa fa-plus-circle"></button>
        </div>
      </div>
      <div class="modal-body">
        <div class="ui-g">
          <div class="ui-g-12 modalForm">

            <p-scrollPanel [style]="{width: '100%', height: '640px'}">
              <section class="wrapper-changelog-panels">
                <div *ngFor="let versionInfo of changelog" class="changelog-panel">
                  <div class="panel-head">
                    <h3 class="panel-date">{{ versionInfo.fechaVersion }}</h3>
                    <span class="panel-version">Versión {{ versionInfo.version }}</span>
                  </div>
                  <div class="panel-content">
                    <ul class="changelog-list" style="list-style: none;">
                      <li *ngFor="let itemChangelog of versionInfo.changelog" class="changelog-list-item">
                        <div class="panel-head" style="margin-bottom: 20px; margin-top: 20px;">
                          <h6 class="panel-date" style="color: dimgrey;">{{ itemChangelog.modulo }}</h6>
                        </div>
                        <ul class="changelog-list">
                          <li *ngFor="let item of itemChangelog.info" class="changelog-list-item">
                            <h4 class="changelog-title">
                              <a href="#" (click)="item.isCollapsed = !item.isCollapsed"
                                [attr.aria-expanded]="!item.isCollapsed" aria-controls="item.uuid"
                                class="collapsed nav-link">
                                <span [ngClass]="item.css_tipo">{{ item.tipo }}</span>

                                <span class="changelog-name">{{ item.titulo }}</span>
                                <i class="title-icon"></i>
                              </a>
                            </h4>
                            <div id="item.uuid" class="panel-collapse collapse" [collapse]="item.isCollapsed">
                              <div class="changelog-content">
                                <ul class="file-directories-list">
                                  <li>
                                    <i class="directories-icon">∟</i>
                                    {{ item.descripcion }} </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </p-scrollPanel>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row clearfix">
          <div class="ui-toolbar-group-left margen-izq-15">
            <div class="form-group" style="display: inline-flex">
              <p-checkbox name="opcionesSD" value="true" [(ngModel)]="mostrar" inputId="mostrar"
                [style]="{'padding':'5'}"></p-checkbox>
              <label class="control-label" style="display: inline-flex">No volver a mostrar</label>
            </div>
          </div>
          <div class="ui-toolbar-group-right margen-dcho-15">
            <button type="text" (click)="cancelChildModal()" pButton label="Cerrar" icon="fa fa-close"></button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<p-dialog header="Añadir novedades a la versión" [(visible)]="showDialogVersion" modal="modal" [responsive]="true"
  [contentStyle]="{'overflow':'visible', 'max-height':'640px','width':'750'}">
  <div class="row clearfix">
    <div class="col-lg-12">
      <div class="form-group">
        <label class="control-label">Titulo</label>
        <input pInputText [(ngModel)]="infoVersion.titulo" class="form-control input-sm"
          name="titulo" placeholder="Titulo...." type="text">
      </div>
    </div>
  </div>
  <div class="row clearfix">
    <div class="col-lg-3">
      <div class="form-group">
        <label class="control-label">Versión</label>
        <p-dropdown [options]="versiones" [(ngModel)]="infoVersion.version" styleClass="DisplayBlock"
          [style]="{'width':'152px','display':'flex'}" placeholder="Selecciona opción" [showClear]="true">
        </p-dropdown>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="form-group">
        <label class="control-label">Fecha versión</label>
        <p-dropdown [options]="fechasVersion" [(ngModel)]="infoVersion.fecha_version" styleClass="DisplayBlock"
           [style]="{'width':'152px','display':'flex'}" placeholder="Selecciona opción"
          [showClear]="true">
        </p-dropdown>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="form-group">
        <label class="control-label">Módulo</label>
        <p-dropdown [options]="modulosVersion" [(ngModel)]="infoVersion.modulo" styleClass="DisplayBlock"
           [style]="{'width':'152px','display':'flex'}" placeholder="Selecciona opción"
          [showClear]="true">
        </p-dropdown>
      </div>
    </div>
    <div class="col-lg-3">
        <div class="form-group">
          <label class="control-label">Tipo</label>
          <p-dropdown [options]="_controlVersionService.tiposVersion" [(ngModel)]="infoVersion.tipo"
            styleClass="DisplayBlock"  [style]="{'width':'152px','display':'flex'}"
            placeholder="Selecciona opción" [showClear]="true">
          </p-dropdown>
        </div>
      </div>
  </div>
  <div class="row clearfix">
    <div class="col-lg-12">
      <div class="form-group">
        <label class="control-label">Descripción</label>
        <textarea class="textarea-center" pInputTextarea rows="4" cols="120" autoResize="autoResize"
        [(ngModel)]="infoVersion.descripcion" ></textarea>
      </div>
    </div>
  </div>
  <br>
  <p-footer>
    <button type="button" pButton icon="fa fa-download" (click)="procesoInfoVersion(false, 'save')"
      label="Guardar"></button>
    <button type="button" pButton icon="fa fa-close" (click)="procesoInfoVersion(false, 'cancel')"
      label="Cancelar"></button>
  </p-footer>
</p-dialog>
