import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@auth/services/auth.service';
import { AccessToken } from '@auth/model/accessToken';

import { NotificationBarService, NotificationType } from '@comun/UI/angular2-notification-bar';
import { ConfirmationService } from 'primeng/api';

import { UsuariosService } from '@usuarios/services/usuarios.service';

import { APIConfig } from '@app/models/global';
import { EncryptionService } from '@comun/services/encryption.service';
import { Usuario } from '@usuarios/model/usuario.model';

@Component({
  selector: 'login',
  templateUrl: './login.html',
  providers: [AuthService, UsuariosService],
})
export class LoginComponent implements OnInit {
  public accessToken: AccessToken;
  public status: number;
  public isCookie: boolean;
  private sessionKey: string;
  private cookieKey: string;
  private channelKey: string;
  private favoritosKey: string;
  public backEnd: boolean;

  constructor(private _authService: AuthService, private _router: Router, private _usuariosService: UsuariosService, private _encryptionService: EncryptionService, private confirmationService: ConfirmationService, private _notificationBarService: NotificationBarService) {}

  ngOnInit() {
    this.sessionKey = APIConfig.SESSION_KEY;
    this.cookieKey = APIConfig.COOKIE_KEY;
    this.channelKey = APIConfig.CHANNEL_KEY;
    this.favoritosKey = APIConfig.FAVORITOS_KEY;
    this.accessToken = new AccessToken('password', APIConfig.API_CLIENT, APIConfig.APP_KEY, '', '');
    this.backEnd = !!sessionStorage.getItem('BackEnd');
  }

  // Funciones auxiliares

  showNotification(tipoMsg: string, bodyMsg: string, autoHide: boolean, allowClose: boolean) {
    this._notificationBarService.create({
      message: bodyMsg,
      type: NotificationType[tipoMsg],
      autoHide: autoHide,
      allowClose: allowClose,
    });
  }

  onSubmit() {
    let user: any;
    this.backEnd = !!sessionStorage.getItem('BackEnd');
    if (this.backEnd) {
      let now: number = new Date().valueOf() / 1000 + 21600; // Token válido para 6h
      let token: any;
      this._authService.login(this.accessToken).subscribe(
        (result) => {
          token = result;
          let cipherObject;
          cipherObject = this._encryptionService.encrypt(token, this.sessionKey);
          sessionStorage.setItem('session', cipherObject);
          sessionStorage.setItem('expire', now.toString());
        },
        (error) => {},
        () => {
          this._authService.getMiPerfil(token.access_token).subscribe(
            (resultMiPerfil) => {
              user = resultMiPerfil['data'];
              user.permisos = resultMiPerfil['data'].permisos['data'][0];
              user.variantesGrid = resultMiPerfil['data'].variantesGrid['data'][0];
              let _cipherObject = this._encryptionService.encrypt(user, this.cookieKey);
              sessionStorage.setItem('cookie', _cipherObject);
            },
            (error) => {
              this.showNotification('Error', error, false, true);
              this._router.navigate(['login']);
            },
            () => {
              this._authService.getSuscripciones(token.access_token).subscribe(
                (resultSuscripciones) => {
                  let channelsInfo = resultSuscripciones['data'];
                  let _cipherObject = this._encryptionService.encrypt(channelsInfo, this.channelKey);
                  sessionStorage.setItem('channels', _cipherObject);
                },
                (error) => {
                  this.showNotification('Error', error, false, true);
                  this._router.navigate(['login']);
                },
                () => {
                  this._authService.getFavoritos(token.access_token).subscribe(
                    (resultFavoritos) => {
                      let favoritosInfo = resultFavoritos['data'];
                      let _cipherObject = this._encryptionService.encrypt(favoritosInfo, this.favoritosKey);
                      sessionStorage.setItem('bookmarks', _cipherObject);
                    },
                    (error) => {
                      this.showNotification('Error', error, false, true);
                      this._router.navigate(['login']);
                    },
                    () => {
                      if (sessionStorage.getItem('BackEnd') === 'http://192.168.3.7' || sessionStorage.getItem('BackEnd') === 'http://192.168.3.9') {
                        this.confirmationService.confirm({
                          message: 'ATENCIÓN: Estas accediendo al servidor de desarrollo. La información que contiene este servidor es solo para la realización de pruebas y puede ser eliminada en cualquier momento. <br><br>¿Seguro que quieres continuar?',
                          accept: () => {
                            this._router.navigate(['dashboard']);
                          },
                          reject: () => {
                            this._authService.logout();
                          },
                        });
                      } else {
                        if (user.actualizaVersion) {
                          this.confirmationService.confirm({
                            message: 'ATENCIÓN: Debes actualizar la versión para poder obtener las últimas mejoras. Asegúrate que tu versión es: <b>' + APIConfig.VERSION + '</b>',
                            accept: () => {
                              this._usuariosService.ocultaActualizaVersion(user.id, { controlVersion: false }).subscribe();
                              setTimeout(() => {
                                window.location.reload();
                              }, 500);
                            },
                            reject: () => {
                              this._authService.logout();
                            },
                          });
                        } else {
                          this._router.navigate(['dashboard']);
                        }
                      }
                    }
                  );
                }
              );
            }
          );
        }
      );
    } else {
      this.showNotification('Error', 'No tienes conexión a Internet', false, true);
    }
  }
}
