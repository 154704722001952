import { NgModule, ModuleWithProviders } from '@angular/core';

import { LoginComponent, NuevoUsuarioComponent, ResetClaveComponent, ErrorAccesoComponent } from '@auth/components';

import { LoggedInGuard } from '@auth/guards';

import { ComunModule } from '@comun/comun.module';

@NgModule({
  declarations: [LoginComponent, NuevoUsuarioComponent, ResetClaveComponent, ErrorAccesoComponent],
  imports: [
    //rwa modules
    ComunModule,
  ],
  providers: [LoggedInGuard],
})
export class AuthModule {}
