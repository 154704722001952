import { ErrorHandler, Injectable, Injector, isDevMode } from '@angular/core';

import { DebugLogService } from '@comun/services/debugLog.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error) {
    const debugLogService = this.injector.get(DebugLogService);
    const message = error.message ? error.message : error.toString();

    debugLogService.logError('ErrorCustomHandler', message, error.stack, error);
  }
}
