export class DropdownItem {
  constructor(public value: number, public label: string) {}
}

export class SelectedItem {
  constructor(public id: number, public nombre: string) {}
}

export class InfoState {
  constructor(public idTab: string, public tabIndex: any, public idTabOrigen: string, public tabOrigenIndex: any, public idTabDestino: string, public tabDestinoIndex: any, public pinBlock: boolean) {}
}

export class Documento {
  constructor(public uuid: string, public idDoc: string, public parentID: any, public tipo: any, public nombre: any, public tamano: any, public ext: any, public fecha: any) {}
}

export class SearchDocumentos {
  constructor(public id: any, public modulo: any, public tipo: any, public fechaIni: any, public fechaFin: any, public contenedor: any, public nombre: any, public asociados: boolean, public resPerPage: any, public page: number) {}
}
