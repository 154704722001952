import { Component, OnInit, ViewContainerRef, Inject } from '@angular/core';

// Configuraciones de Componentes UI
import { UIComponentsService } from '@comun/services/uiComponents.service';
import { appsMenu, extrasMenu } from '@coreUI/model/apps.model';
import { CommonService } from '@comun/services/common.service';
import { DynamicLoaderService } from '@coreUI/services/dynamicLoader.service';

@Component({
  template: ``,
  providers: [UIComponentsService, DynamicLoaderService],
})
export class DashboardComponent implements OnInit {
  constructor(private _uiComponentService: UIComponentsService, @Inject(DynamicLoaderService) private _dynamicLoader: DynamicLoaderService, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef) {
    _dynamicLoader.setRootViewContainerRef(viewContainerRef);
  }

  ngOnInit() {
    this.setDashboard();
  }

  setDashboard() {
    this._dynamicLoader.addHomeDashboardComponent();
    //this._dynamicLoader.addMaritimoDashboardComponent();
    //this._dynamicLoader.addComercialDashboardComponent();
  }

  openAppTab(tab) {
    const newTab = this._uiComponentService.filterByProperty(appsMenu.APPS_MENU[0].P_CHILDMENUS, 'TABID', tab);
    CommonService.event('selectedTab', newTab[0]);
  }

  openExtrasTab(tab) {
    const newTab = this._uiComponentService.filterByProperty(extrasMenu.EXTRAS_MENU[0].P_CHILDMENUS, 'TABID', tab);
    CommonService.event('selectedTab', newTab[0]);
  }
}
