/// <reference types="crypto-js" />

import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class EncryptionService {
  constructor() {}

  encrypt(data: any, key: string) {
    let cipherObject;

    cipherObject = CryptoJS.AES.encrypt(JSON.stringify(data), key);

    return cipherObject;
  }

  decrypt(cipherObject: any, key: string) {
    let bytes;
    let decryptedObject;

    bytes = CryptoJS.AES.decrypt(cipherObject.toString(), key);
    decryptedObject = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decryptedObject;
  }
}
